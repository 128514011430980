<template>
  <form
    @submit.prevent="submitOfferForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Contract
    </div>
    <text-input
      v-if="currentMarketOffer"
      :wideItem="true"
      inputTitle="Name"
      inputHtmlType="text"
      v-model="currentMarketOffer.contract_name"
      :display-only="readOnly"
      :display-only-value="currentMarketOffer.contract_name"
    />
    <text-area
      v-if="currentMarketOffer"
      :wideItem="true"
      inputTitle="Description"
      inputHtmlType="text"
      v-model="currentMarketOffer.contract_description"
      class-prop="h-20"
      :display-only="readOnly"
      :display-only-value="currentMarketOffer.contract_description"
    />
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Crop
    </div>
    <combo-box
      v-if="currentMarketOffer"
      :wideItem="true"
      inputTitle="Crop"
      inputHtmlType="text"
      v-model="currentMarketOffer.crop"
      :display-only="readOnly"
      :display-only-value="currentMarketOffer.crop"
      :expand-to-content="false"
      :selectOptions="cropOptions"
      class="ml-1 resize-none whitespace-pre-wrap sm:col-span-3"
      classProp="resize-none"
      @on-blur="currentMarketOffer.crop = $event"
    />
    <div></div>
    <number-input
      v-if="currentMarketOffer"
      :wideItem="true"
      inputTitle="Quantity"
      inputHtmlType="text"
      v-model="currentMarketOffer.quantity"
      :display-only="readOnly"
      :display-only-value="currentMarketOffer.quantity"
    />
    <text-input
      v-if="currentMarketOffer"
      :wideItem="true"
      inputTitle="Quantity Units (Bushels, Bags, Tons, etc)"
      inputHtmlType="text"
      v-model="currentMarketOffer.quantity_units"
      :display-only="readOnly"
      :display-only-value="currentMarketOffer.quantity_units"
    />
    <text-input
      v-if="currentMarketOffer"
      :wideItem="true"
      inputTitle="Deliver To:"
      inputHtmlType="text"
      v-model="currentMarketOffer.elevator"
      :display-only="readOnly"
      :display-only-value="currentMarketOffer.elevator"
    />
    <text-area
      v-if="currentMarketOffer"
      :wideItem="true"
      inputTitle="Delivery Location"
      inputHtmlType="text"
      v-model="currentMarketOffer.delivery_location"
      :display-only="readOnly"
      :display-only-value="currentMarketOffer.delivery_location"
    />

    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Status
    </div>
    <div class="-ml-6 sm:col-span-6">
      <offer-details :offerId="offerId" :hideContractSummary="true" />
    </div>

    <!-- <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Premiums
    </div>
    <div class="sm:col-span-6">
      <crop-premium-display :premiums="currentMarketOffer.premiums" />
    </div> -->
  </form>
  <div class="col-span-6">
    <TabGroup as="div">
      <div class="border-b border-gray-200">
        <TabList class="-mb-px flex space-x-8">
          <Tab as="template" v-slot="{ selected }">
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Premiums
            </button>
          </Tab>
          <Tab
            as="template"
            v-slot="{ selected }"
            v-if="currentMarketOffer && inquiries?.length > 0"
          >
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Inquiries
            </button>
          </Tab>
          <Tab
            as="template"
            v-slot="{ selected }"
            v-if="currentMarketOffer && orders?.length > 0"
          >
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Orders
            </button>
          </Tab>
          <Tab
            as="template"
            v-slot="{ selected }"
            v-if="currentMarketOffer && orders?.length > 0"
          >
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Map
            </button>
          </Tab>
        </TabList>
      </div>
      <TabPanels as="template">
        <TabPanel as="dl" class="pt-10">
          <crop-premium-display
            v-if="currentMarketOffer.premiums"
            :premiums="currentMarketOffer.premiums"
          />
        </TabPanel>
        <TabPanel
          as="dl"
          class="pt-10"
          v-if="currentMarketOffer && inquiries?.length > 0"
        >
          <inquiry-table :offerId="currentMarketOffer.id" />
        </TabPanel>
        <TabPanel
          as="dl"
          class="pt-10"
          v-if="currentMarketOffer && orders?.length > 0"
        >
          <orders-table :offerId="currentMarketOffer.id" />
        </TabPanel>
        <TabPanel
          as="dl"
          class="pt-10"
          v-if="currentMarketOffer && orders?.length > 0"
        >
          <growers-map :offer-id="offerId" />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import NumberInput from "@/components/form/NumberInput.vue";
// import CurrencyInput from "@/components/form/CurrencyInput.vue";
// import RadioInput from "@/components/form/RadioInput.vue";
import ComboBox from "@/components/form/ComboBox.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
// import OfferPremiumForm from "@/layouts/buyer-portal/offers/OfferPremiumForm.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
import CropPremiumDisplay from "@/layouts/buyer-grower/crop-orders/CropPremiumDisplay.vue";
import OfferDetails from "@/layouts/buyer-portal/bp-affiliate/offers/OfferDetails.vue";
import InquiryTable from "@/pages/buyer-portal/bp-affiliate/orders/InquiriesTable.vue";
import OrdersTable from "@/pages/buyer-portal/bp-affiliate/orders/OrdersTable.vue";
import GrowersMap from "@/layouts/buyer-portal/bp-affiliate/dashboard/GrowersMap.vue";

let cropList = require("@/assets/croplist.js");
export default {
  props: {
    offerId: {
      type: [Number, String],
      default: null,
    },
    formId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    OfferDetails,
    TextInput,
    // CurrencyInput,
    // RadioInput,
    TextArea,
    NumberInput,
    ComboBox,
    // CheckboxInput,
    // OfferPremiumForm,
    // SelectDropdown,
    CropPremiumDisplay,
    InquiryTable,
    OrdersTable,
    GrowersMap,
  },
  data() {
    return {
      currentMarketOffer: {},
      // temp until build multiple premium support
      numberOfPremiums: 1,
      initialPremiums: [],
      submissionPremiums: [],
      listOfCropOptionObjects: cropList,
    };
  },
  computed: {
    cropOptions() {
      let options = [];
      for (let crop of this.listOfCropOptionObjects) {
        options.push(crop.name);
      }
      return options;
    },
    inquiries() {
      let derived = this.$store.getters.getDerivedBuyerCropOrders({
        filterFunction: (order) => {
          return order.status === "Inquiry";
        },
      });
      derived = derived.filter(
        (inquiry) => inquiry.offer === this.currentMarketOffer.id,
      );
      console.log("inquiries", derived.length);
      return derived;
    },
    orders() {
      let orders = this.$store.getters.getEnrichedBuyerOrders({
        filterFunction: (order) => {
          return order.status === "In Progress";
        },
      });
      if (this.offerId) {
        orders = orders.filter(
          (order) => order.offer == this.currentMarketOffer.id,
        );
      }
      console.log("orders", orders.length, this.currentMarketOffer.id);
      return orders;
    },
    readyToReadMarketOffer() {
      if (this.$store.getters.getBuyerMarketOfferById(this.offerId)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    submitOfferForm() {
      if (this.offerId) {
        this.updateMarketOffer();
      } else {
        this.createMarketOffer();
      }
    },
    readMarketOffer() {
      let currentMarketOffer = this.$store.getters.getBuyerMarketOfferById(
        this.offerId,
      );
      if (!currentMarketOffer) {
        return;
      }
      this.currentMarketOffer = currentMarketOffer;
      this.initialPremiums = currentMarketOffer.premiums;
      this.numberOfPremiums = currentMarketOffer.premiums.length;
    },
    async createMarketOffer() {
      this.currentMarketOffer.premiums = this.submissionPremiums;
      await this.$store.dispatch(
        "createBuyerMarketOffer",
        this.currentMarketOffer,
      );
      this.$router.push({ name: "buyer-market-offers" });
    },
    async updateMarketOffer() {
      this.currentMarketOffer.premiums = this.submissionPremiums;
      await this.$store.dispatch(
        "updateBuyerMarketOffer",
        this.currentMarketOffer,
      );

      this.$router.push({ name: "buyer-market-offers" });
    },
    clearForm() {
      this.currentMarketOffer = {
        org: null, // int
        quantity: null, // number
        buyer_contract: null, // int
      };
    },
    setSubmissionPremium(value, index) {
      // console.log("this.submissionPremiums", this.submissionPremiums);
      this.submissionPremiums[index] = value;
    },
  },
  watch: {
    offerId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readMarketOffer();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    readyToReadMarketOffer: {
      handler(value) {
        if (value) {
          this.readMarketOffer();
        }
      },
      immediate: true,
    },
  },
};
</script>
