<template>
  <page-card headingTitle="New Field">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="newFieldFormId"
      />
    </template>
    <template #cardDetails>
      <field-form :formId="newFieldFormId" ref="fieldForm" />
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Map
              </button>
            </Tab>
            <!-- <Tab as="template" v-slot="{ selected }">
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  Records
                </button>
              </Tab> -->
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10">
            <div class="-mt-4 flex text-sm text-gray-600">
              You must add your field boundary. Click
              <img
                :src="require('@/assets/polygon.svg')"
                alt="add boundary"
                class="ml-1 h-4 w-4"
              />
              &nbsp;to add a boundary.
            </div>
            <div
              class="border-t border-gray-200 px-4 py-5 sm:col-span-6 sm:px-6"
            >
              <field-leaflet-map
                :fieldId="null"
                @edited="updateFieldShape"
                :key="null"
                :layers="layers"
                :show-layer-selector="true"
              >
                <!-- <modal :show="showLocationModal" title="save a location">
                  <select-dropdown
                    v-model="markedLocationType"
                    :selectOptions="['Entrance', 'Pick up']"
                    :placeholder="'Select a location'"
                    inputTitle="Location Type"
                  />
                  <base-button
                    @click="saveLocation"
                    buttonText="Save"
                  ></base-button>
                  <base-button
                    @click="showLocationModal = false"
                    buttonText="Cancel"
                    :redButton="true"
                  ></base-button>
                </modal> -->
              </field-leaflet-map>
            </div>
          </TabPanel>
          <!-- <TabPanel class="pt-10" :unmount="false">
              <records-table :currentField="fieldId" ref="recordsTable" />
            </TabPanel> -->
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import FieldForm from "@/layouts/grower-portal/assets/fields/FieldForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import FieldLeafletMap from "@/components/map/FieldEditMap.vue";
// import RecordsTable from "@/layouts/grower-portal/records/RecordsTable.vue";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
// import modal from "@/components/modals/PopupModal.vue";
export default {
  components: {
    PageCard,
    // BaseButton,
    SubmitButton,
    // SelectDropdown,
    FieldForm,
    FieldLeafletMap,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    // modal,
  },
  data() {
    return {
      newFieldFormId: "newField",
      showLocationModal: false,
      currentField: {
        type: "Feature",
        geometry: {}, // object
        properties: {
          farm: null, // string
          name: null, // string
          acres: null, // int
          owner: null, // int
          crop: null, // string
          details: {}, // object
        },
      },
    };
  },
  computed: {
    fieldHasGeometry() {
      if (this.currentField.geometry?.coordinates?.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    layers() {
      let layers = [];
      let fields = this.$store.state.fields.fields;

      let fieldJSON = {
        type: "FeatureCollection",
        features: fields.map((field) => ({
          type: "Feature",
          id: field.id,
          geometry: field.geometry,
          properties: {
            name: field.properties.name,
            link: "/app/fields/" + field.id,
          },
        })),
      };
      let fieldLayer = {
        name: "Other Fields",
        geojson: fieldJSON,
        style: { fillColor: "red", opacity: 1, weight: 1, color: "green" },
      };
      //console.log("fields layer", fieldLayer);
      layers.push(fieldLayer);
      return layers;
    },
  },
  methods: {
    updateFieldShape(feature, acres) {
      // console.log("update that shape!")
      // console.log("New vals", feature, acres)
      this.currentField.properties.acres = acres;
      this.currentField.geometry = feature.geometry;
      this.$refs.fieldForm.updateFieldShape(feature, acres);
      // console.log(this.currentField)
    },
    // markLocation(location) {
    //   this.markedLocationPoint = location;
    //   this.showLocationModal = true;
    // },
    // saveLocation() {
    //   this.showLocationModal = false;
    //   this.$store.dispatch("createGrowerLocation", {
    //     field: this.fieldId,
    //     type: this.markedLocationType,
    //     point: this.markedLocationPoint,
    //   });
    //   this.markedLocationType = null;
    //   this.markedLocationPoint = null;
    // },
    // deleteLocation(id) {
    //   this.$store.dispatch("deleteGrowerLocation", { id });
    // },
  },
};
</script>
