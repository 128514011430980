<template>
  <form
    id="login-form"
    action="#"
    method="POST"
    class="space-y-6"
    v-on:submit.prevent
  >
    <text-input v-model="staticToken" inputTitle="Token" id="staticToken" />
    <submit-button
      button-text="Sign in"
      formId="login-form"
      @submitButtonClick="signIn"
    />
  </form>
  <div v-if="loginError">
    <p class="text-red-500">There was an error logging in</p>
  </div>
</template>

<script>
// import { SDKError, RPCError, ExtensionError } from "magic-sdk";
//import * as yup from "yup";
import SubmitButton from "@/components/buttons/SubmitButton";
import TextInput from "@/components/form/TextInput";
export default {
  components: { TextInput, SubmitButton },
  data() {
    return {
      staticToken: "",
      loginError: false,
      loginRedirectName: "grower-portal-home",
    };
  },
  computed: {
    showLoading() {
      if (this.loggingIn) return true;
      return false;
    },
  },
  methods: {
    async signIn() {
      this.loginError = false;
      console.log("signing in");
      await this.$store.dispatch("loginViaStaticToken", this.staticToken);
      this.finalizeLogin();
    },
    async finalizeLogin() {
      console.log("finalizing login");
      await this.$store.dispatch("getCurrentOrgBlocking");
      console.log("orgs", this.$store.state.orgs.orgs);
      if (this.$store.state.orgs.orgs.length == 0) {
        this.loginError = true;
      } else {
        console.log(
          "profile",
          this.$store.state.profile.profile,
          this.$store.getters.getUserData,
        );
        this.$router.push({ name: "landing-page" });
      }
    },
  },
};
</script>
