<template>
  <div class="flex justify-center">
    <img :src="getImage('carbon-balance.jpg')" class="w-full" />
  </div>
  <div class="pt-4 text-lg" v-if="scenario.crop === 'other'">
    <b>Note:</b> The crop you selected is not one for which we can generate a CI
    Score. However, similar priciples may apply to your crop with respect to
    carbon intensity.
  </div>
  <div class="pt-4" v-if="scenario && scenario.crop != 'other'">
    A carbon intensity score of
    {{ scenario.scores?.ci_score?.toFixed(1) }} for your
    {{ scenario.crop }} crop refers to the grams of carbon dioxide (CO2)
    emissions or the equivalent of other emissions associated with the
    production of one
    {{ yieldUnits(scenario?.crop) }}
    of
    {{ scenario.crop }}. CI Scores are reported in grams per megajoule of energy
    available as a fuel.
  </div>
  <div class="pt-4">
    In general, a lower carbon intensity score is considered more
    environmentally friendly and sustainable. Here's an explanation of what this
    score means:

    <ul class="list-disc pl-4 pt-4">
      <li v-if="scenario && scenario.crop != 'other'">
        <span class="font-bold"
          >A carbon intensity score of
          {{ scenario.scores?.ci_score?.toFixed(1) }} is relatively
          {{ lowCI ? "low" : "high" }}</span
        >. It means that the carbon emissions associated with producing your
        {{ scenario.crop }} crop are {{ lowCI ? "lower" : "higher" }} than other
        growers following standard agricultural practices. Lower scores indicate
        a more environmentally sustainable production process.
      </li>
      <li class="pt-2">
        <span class="font-bold">Environmental Impact:</span> Agriculture can
        contribute to greenhouse gas emissions, mainly through the use of fossil
        fuels for machinery, transportation, and fertilizer production. Lowering
        carbon intensity scores is often a goal in sustainable agriculture
        practices, as it reduces the environmental impact and helps combat
        climate change.
      </li>
      <li class="pt-2">
        <span class="font-bold">Factors Affecting This Score:</span> Several
        factors can influence the carbon intensity score of a crop, including
        the type of farming practices, energy sources used, transportation
        methods, and the overall efficiency of the production process.
        Sustainable farming practices, such as planting cover crops, reduced
        tillage, efficient fertilizer application, and renewable energy use can
        help lower carbon intensity scores.
        <p>
          - N2O emissions reported come primarily from Nitrogen fertilizer not
          being used by the plant and prior crop residues breaking down.
          Possible losses of N from fields include “denitrification” (conversion
          of nitrate to N2 gas), and ammonia volatilization (from soils and
          plants). Most volatilization from urea typically occurs during a two-
          to three-week period after application. Volatilization of
          surface-applied urea increases linearly as soil water content
          increases. Generally, 0.5 inch or more of precipitation from a single
          weather event is needed to leach urea deep enough into the soil to
          protect it from volatilization. High soil pH and high temperatures
          cause higher rates of volatilization because 1) they increase soil
          concentrations of ammonia dissolved in soil water and 2) warm soil
          water cannot hold as much ammonia gas. Sandy or low organic matter in
          soils can also adversely affect the amount of ammonia volatilization.
        </p>
        <p>- CO2 emissions reported come primarily from urea and CaCO3 use.</p>
        <p>
          - It is important to note that yield is a significant factor in your
          emissions rates. The higher the yield for a given amount of fertilizer
          and energy will reduce the emissions per
          {{ yieldUnits(scenario?.crop) }}.
        </p>
      </li>

      <li class="pt-2" v-if="scenario && scenario.crop != 'other'">
        <span class="font-bold">Improvement Opportunities:</span> If your
        {{ scenario.crop }} crop achieved a score of
        {{ scenario.scores?.ci_score?.toFixed(1) }}, it's a good start, but
        there may still be room for improvement. Reducing
        {{ areaWithHighest }} will have the largest impact on your score.
      </li>
      <p class="pt-2">
        Here's a list of practices that can help reduce your carbon intensity:
      </p>
      <table>
        <tr>
          <th>
            <span class="font-bold">Practice Change</span>
          </th>
          <th>
            <span class="font-bold">Potential CI change</span>
          </th>
        </tr>
        <tr>
          <td>Green Ammonia</td>
          <td>-6.1</td>
        </tr>
        <!-- <tr>
            <td>Low CI Ammonia (Ammonia manufactured with CO2 capture)</td>
            <td>-2 to -5</td>
          </tr> -->
        <tr>
          <td>Switch to No Till from Reduced Till</td>
          <td>-3.4 to -6.5 depending on location</td>
        </tr>
        <!-- <tr>
            <td>Use enhanced efficiency nitrogen fertilizer</td>
            <td>-2.4</td>
          </tr> -->
        <tr>
          <td>4R precision application of fertilizer</td>
          <td>-5.2</td>
        </tr>
        <!-- <tr>
            <td>Bio based fertilizer</td>
            <td>-1 to -3</td>
          </tr> -->
        <tr>
          <td>Spreading manure as fertilizer</td>
          <td>-5.5 to -28</td>
        </tr>
        <!-- <tr>
            <td>Use of cover crops</td>
            <td>-20.4 to -39.1</td>
          </tr> -->
      </table>

      <li class="pt-2" v-if="scenario.crop === 'corn'">
        <span class="font-bold">Revenue Potential:</span> If your crop achieved
        a score below 20, you can potentially negotiate a premium for your
        crops, based on federal guidelines and tax credits if sold to a fuel
        producer. Standard practices typically result in a score of 29.1 for
        corn.
      </li>
    </ul>
    <div class="pt-4" v-if="scenario && scenario.crop != 'other'">
      In summary, a carbon intensity score of
      {{ scenario.scores?.ci_score?.toFixed(1) }} for your
      {{ scenario.crop }} crop indicates that the emissions associated with its
      production are relatively {{ lowCI ? "low" : "high" }}, which is generally
      a {{ lowCI ? "positive" : "negative" }} outcome from an environmental
      perspective. It's important to continue exploring ways to reduce carbon
      emissions further and promote sustainable agriculture practices to
      minimize the environmental impact of crop production.
    </div>
  </div>
  <!-- <div class="flex justify-center">
    <img :src="getImage('Sustainable.jpg')" class="w-96" />
  </div> -->
</template>
<script>
export default {
  name: "CIReportHelp",
  props: {
    scenarioId: {
      type: [String, Number],
      required: true,
    },
    sharedScenario: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    scenario() {
      if (this.sharedScenario) {
        return this.$store.getters.getSharedScenarioById(this.scenarioId);
      } else {
        return this.$store.getters.getScenarioById(this.scenarioId);
      }
    },
    lowCI() {
      switch (this.scenario?.crop) {
        case "corn":
          return this.scenario?.scores?.ci_score < 29.1;
        case "sugarcane":
          return this.scenario?.scores?.ci_score < 112.8;
        case "soybeans":
          return this.scenario?.scores?.ci_score < 65.3;
        case "sorghum":
          return this.scenario?.scores?.ci_score < 7.2;
        case "rice":
          return this.scenario?.scores?.ci_score < 65.2;
        default:
          return this.scenario?.scores?.ci_score < 29.1;
      }
    },
    areaWithHighest() {
      let top = 0;
      let areaWithHighest = "";
      if (this.scenario?.scores?.co2_emission_from_field_percent > top) {
        top = this.scenario?.scores?.co2_emission_from_field_percent;
        areaWithHighest = "CO2 emissions from the field";
      }
      if (this.scenario?.scores?.energy_percent > top) {
        top = this.scenario?.scores?.energy_percent;
        areaWithHighest = "Energy use";
      }
      if (this.scenario?.scores?.n2o_emission_from_field_percent > top) {
        top = this.scenario?.scores?.n2o_emission_from_field_percent;
        areaWithHighest = "N2O emissions from the field";
      }
      if (this.scenario?.scores?.nitrogen_fertilizer_percent > top) {
        top = this.scenario?.scores?.nitrogen_fertilizer_percent;
        areaWithHighest = "Nitrogen fertilizer use";
      }
      if (this.scenario?.scores?.other_chemicals_percent > top) {
        top = this.scenario?.scores?.other_chemicals_percent;
        areaWithHighest = "Other chemicals";
      }
      return areaWithHighest;
    },
  },
  methods: {
    yieldUnits(crop) {
      switch (crop) {
        case "sugarcane":
          return "ton";
        case "rice":
          return "cwt";
        default:
          return "bushel";
      }
    },
    getImage(img) {
      return require(`../../../assets/${img}`);
    },
  },
};
</script>
