import client from "@/api/parcel/api.js";
import { universalGetter } from "@/components/composables/universalGetter";

export default {
  state() {
    return {
      scenarios: [],
    };
  },
  mutations: {
    SET_SCENARIOS(state, scenarioData) {
      state.scenarios = scenarioData;
    },
    ADD_SCENARIO(state, scenarioObject) {
      state.scenarios.push(scenarioObject);
    },
    UPDATE_SCENARIO(state, scenarioObject) {
      state.scenarios = state.scenarios.filter(function (obj) {
        return obj.id !== scenarioObject.id;
      });
      state.scenarios.push(scenarioObject);
    },
    DELETE_SCENARIO(state, scenarioObject) {
      state.scenarios = state.scenarios.filter(function (obj) {
        return obj.id !== scenarioObject.id;
      });
    },
    RESET_SCENARIOS(state) {
      state.scenarios = [];
    },
  },
  actions: {
    async getScenarios({ commit }) {
      const { data, errors } = await client.scenarios.read();
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_SCENARIOS", data.scenarios);
      }
    },
    async createScenario({ commit, dispatch }, scenario) {
      const { data, errors } = await client.scenarios.create(null, scenario);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        console.log(data);
        commit("ADD_SCENARIO", data);
        this.dispatch("getScenarios");
        return data;
      }
    },
    async updateScenario({ commit, dispatch }, scenario) {
      const { data, errors } = await client.scenarios.update(
        scenario.id,
        scenario,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_SCENARIO", data);
        this.dispatch("getScenarios");
        return data;
      }
    },
    async deleteScenario({ commit, dispatch }, scenario) {
      const { data, errors } = await client.scenarios.delete(scenario.id);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors, data);
      } else {
        // console.log(data);
        commit("DELETE_SCENARIO", scenario);
        this.dispatch("getScenarios");
      }
    },
  },
  getters: {
    getScenarioById: (state) => (scenarioId) => {
      return state.scenarios.find(
        (scenario) => scenario.id === parseInt(scenarioId),
      );
    },
    getScenarios:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let scenarios = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.scenarios,
        );
        return scenarios;
      },
    getEnrichedGrowerScenarios:
      (state, rootGetters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        // console.log(agreements)
        let agreements = rootGetters.getAllGrowerQuotes;
        let initalScenarios = state.scenarios;
        initalScenarios.forEach((scenario) => {
          // for each org in shared_orgs, add the shared_orgs_names array
          scenario.shared_orgs_names = [];
          scenario.shared_orgs_string = "None";
          scenario.shared_orgs.forEach((org) => {
            let agreement = agreements.find(
              (agreement) => agreement.seller_details.id === parseInt(org),
            );
            if (agreement)
              scenario.shared_orgs_names.push(agreement.seller_details.name);
            if (scenario.shared_orgs_names.length > 0) {
              scenario.shared_orgs_string =
                scenario.shared_orgs_names.join(", ");
            }
          });
        });
        let enrichedScenarios = initalScenarios.map((scenario) => ({
          ...scenario,
          // add additional enriched attributes here
        }));
        console.log("enrichedScenarios", enrichedScenarios);
        // for scenario in enrichedScenarios delete scenario.agreement_object
        let readyScenarios = enrichedScenarios.map((scenario) => {
          // remove unneeded attributes here
          return scenario;
        });
        let finalEnrichedScenarios = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          readyScenarios,
        );
        return finalEnrichedScenarios;
      },
    // getEnrichedScenarios:
    // (rootGetters, state) =>
    // ({
    //   filterFunction = null,
    //   filter = {},
    //   match = "",
    //   sort = [],
    //   pageSize = null,
    //   pageNum = 0,
    // }) => {
    //   // console.log(agreements)
    //   let agreements = rootGetters.getAllGrowerQuotes;
    //   let initalScenarios = state.scenarios;
    //   let enrichedScenarios = initalScenarios.map((scenario) => ({
    //     ...scenario,

    //     // add additional enriched attributes here
    //   }));
    //   // for scenario in enrichedScenarios delete scenario.agreement_object
    //   let readyScenarios = enrichedScenarios.map((scenario) => {
    //     // remove unneeded attributes here
    //     return scenario;
    //   });
    //   let finalEnrichedScenarios = universalGetter(
    //     {
    //       filterFunction: filterFunction,
    //       filter: filter,
    //       match: match,
    //       sort: sort,
    //       pageSize: pageSize,
    //       pageNum: pageNum,
    //     },
    //     readyScenarios
    //   );
    //   return finalEnrichedScenarios;
    // },
  },
};
