<template>
  <template v-if="currentField == null">
    <page-card headingTitle="Field could not be found."> </page-card>
  </template>
  <template v-else>
    <delete-modal
      :open="modalIsActive"
      modalTitle="Delete Field"
      :modalDescription="modalDescription"
      @clickCancel="cancelDelete"
      @clickConfirmDelete="deleteField"
    />
    <slot-modal
      :open="cropModalIsActive"
      modalTitle="New crop"
      modalDescription="Please select a crop type to continue."
      @closeModal="cropModalIsActive = false"
      ><template #icon
        ><CalendarIcon class="text-black-600 h-6 w-6" aria-hidden="true"
      /></template>
      <template #content>
        <div
          class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
        >
          <checkbox-input
            :wideItem="true"
            inputTitle="Copy Existing Crop Details"
            v-model="copyCropDetails"
          />
          <checkbox-input
            :wideItem="true"
            inputTitle="Make new crop the active crop?"
            v-model="newActiveCrop"
          />
          <div class="col-span-6">
            <select-search
              v-model="newCropType"
              :selectOptions="cropOptions"
              :placeholder="'Select a crop type'"
              inputTitle="Crop Type"
              v-if="!copyCropDetails"
              class="mb-48"
            />
          </div>
          <date-input
            :wideItem="true"
            inputTitle="From"
            helpText="Date the crop was planted, or crop year begins for planning and financial purposes."
            v-model="harvest_range_start"
          />
          <date-input
            :wideItem="true"
            inputTitle="To"
            helpText="Date the crop was harvested, or crop year ends for planning and financial purposes."
            v-model="harvest_range_end"
          />
        </div>
      </template>
      <template #buttons
        ><button
          type="button"
          class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
          @click="addCrop"
        >
          Create Crop
        </button>
        <button
          type="button"
          class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
          @click="cropModalIsActive = false"
        >
          Cancel
        </button>
      </template>
    </slot-modal>
    <page-card :headingTitle="currentField.properties.name || ' '">
      <template #buttonArea>
        <base-button
          buttonText="New Crop"
          :redButton="false"
          @buttonClick="cropModalIsActive = true"
        />
        <submit-button
          buttonText="Save"
          :marginLeft="true"
          :errorArray="errorArray"
          :formId="editFieldFormId"
          @submitButtonClick="saveField"
        />
        <base-button
          buttonText="Delete"
          :redButton="true"
          @buttonClick="intializeDeleteModal"
        />
      </template>
      <template #cardDetails>
        <field-form
          :fieldId="fieldId"
          :cropId="currentCropId"
          :formId="editFieldFormId"
          ref="fieldForm"
        />
      </template>
      <template #componentSection>
        <TabGroup as="div">
          <div class="border-b border-gray-200">
            <TabList class="-mb-px flex space-x-8">
              <Tab as="template" v-slot="{ selected }">
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  Map
                </button>
              </Tab>
              <Tab as="template" v-slot="{ selected }">
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  <div class="flex">
                    Records
                    <!-- <div v-if="fieldRecords">
                      &nbsp; ({{ fieldRecords.length }})
                    </div> -->
                  </div>
                </button>
              </Tab>
              <Tab as="template" v-slot="{ selected }">
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  <div class="flex">
                    Crop History
                    <!-- <div v-if="fieldRecords">
                      &nbsp; ({{ fieldRecords.length }})
                    </div> -->
                  </div>
                </button>
              </Tab>
            </TabList>
          </div>
          <TabPanels as="template">
            <TabPanel as="dl" class="pt-10">
              <div class="-mt-8 flex text-sm text-gray-600">
                You can edit your field boundary
                <img
                  :src="require('@/assets/Edit_Vertex.svg')"
                  alt="edit boundary"
                  class="ml-1 h-4 w-4"
                />, exclude areas
                <img
                  :src="require('@/assets/scissors.svg')"
                  alt="edit boundary"
                  class="ml-1 h-4 w-4"
                />, or add parking and entrance markers
                <img
                  :src="require('@/assets/location-marker.svg')"
                  alt="edit boundary"
                  class="ml-1 h-4 w-4"
                />
              </div>
              <div class="flex text-sm text-gray-600">
                There are {{ locations?.length }} locations marked.

                <div v-if="entrance_points > 0">
                  &nbsp;{{ entrance_points }} entrance points
                </div>
                <div v-if="pickup_points > 0 && entrance_points > 0">
                  &nbsp;and
                </div>
                <div v-if="pickup_points > 0">
                  &nbsp;{{ pickup_points }} pick up points
                </div>
              </div>
              <div
                class="border-t border-gray-200 px-4 py-5 sm:col-span-6 sm:px-6"
              >
                <field-leaflet-map
                  :fieldId="fieldHasGeometry ? fieldId : null"
                  @edited="updateFieldShape"
                  :key="fieldId"
                  @location-added="markLocation"
                  @removeMarker="deleteLocation"
                  :layers="layers"
                  :showLayerSelector="true"
                >
                  <modal :show="showLocationModal" title="save a location">
                    <select-dropdown
                      v-model="markedLocationType"
                      :selectOptions="['Entrance', 'Pick up']"
                      :placeholder="'Select a location'"
                      inputTitle="Location Type"
                    />
                    <base-button
                      @click="saveLocation"
                      buttonText="Save"
                    ></base-button>
                    <base-button
                      @click="showLocationModal = false"
                      buttonText="Cancel"
                      :redButton="true"
                    ></base-button>
                  </modal>
                </field-leaflet-map>
              </div>
            </TabPanel>
            <TabPanel class="pt-10" :unmount="false">
              <records-table
                :currentCropId="currentCrop?.id"
                ref="recordsTable"
              />
            </TabPanel>
            <TabPanel class="pt-10" :unmount="false">
              <crop-history :fieldId="fieldId" :cropId="currentCrop?.id" />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </template>
    </page-card>
  </template>
</template>

<script>
import FieldForm from "@/layouts/grower-portal/assets/fields/FieldForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import DateInput from "@/components/form/DateInput.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import FieldLeafletMap from "@/components/map/FieldEditMap.vue";
import RecordsTable from "@/layouts/grower-portal/records/RecordsTable.vue";
import CropHistory from "@/layouts/grower-portal/assets/fields/CropHistory.vue";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import modal from "@/components/modals/PopupModal.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import { CalendarIcon } from "@heroicons/vue/outline";

let cropList = require("@/assets/croplist.js");

export default {
  components: {
    PageCard,
    BaseButton,
    SubmitButton,
    SelectDropdown,
    SelectSearch,
    DateInput,
    FieldForm,
    DeleteModal,
    FieldLeafletMap,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    modal,
    SlotModal,
    CheckboxInput,
    RecordsTable,
    CropHistory,
    CalendarIcon,
  },
  data() {
    return {
      // fieldId: parseInt(this.$route.params.fieldId),
      listOfCropOptionObjects: cropList,
      modalIsActive: false,
      editFieldFormId: "editField",
      errorArray: new Array(),
      showLocationModal: false,
      currentField: (this.currentField = this.$store.getters.getFieldById(
        parseInt(this.$route.params.fieldId),
      )),
      markedLocationType: null,
      cropModalIsActive: false,
      copyCropDetails: false,
      newActiveCrop: true,
      newCropType: null,
      harvest_range_start: new Date().toISOString().split("T")[0],
      harvest_range_end: null,
    };
  },
  computed: {
    fieldId() {
      return parseInt(this.$route.params.fieldId);
    },
    currentCropId() {
      let cropId = null;
      if (this.$route.query.cropId) {
        cropId = parseInt(this.$route.query.cropId);
      }
      // else {
      //   cropId = this.$store.getters.getAnyCropByFieldId(this.fieldId)?.id;
      // }
      return cropId;
    },
    currentCrop() {
      if (this.currentCropId == null) {
        return this.currentField.properties.current_crop;
      }
      return this.$store.getters.getAnyCropById(this.currentCropId);
    },
    cropOptions() {
      let options = [];
      for (let crop of this.listOfCropOptionObjects) {
        options.push(crop.name);
      }
      return options;
    },
    fieldRecords() {
      let fieldRecords = this.$store.getters.getRecordsByField(this.fieldId);
      let field = this.$store.getters.getFieldById(this.fieldId);
      let cropRecords = this.$store.getters.getRecordsByCrop(
        field.properties?.current_crop?.id,
      );
      let records = fieldRecords.concat(cropRecords);
      return records;

      // return this.$store.getters.getRecordsByField(this.fieldId);
    },
    fieldTasks() {
      return this.$store.getters.getTasksByField(this.fieldId);
    },
    fieldHasGeometry() {
      if (this.currentField.geometry?.coordinates?.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    modalDescription() {
      let description = "Are you sure you want to delete this field?";
      if (this.fieldRecords.length == 1) {
        description += ` This field has 1 record, which will also be deleted.`;
      }
      if (this.fieldRecords.length > 1) {
        description += ` This field has ${this.fieldRecords.length} records, which will also be deleted.`;
      }
      if (this.fieldTasks.length == 1) {
        description += ` This field has 1 task, which will also be deleted.`;
      }
      if (this.fieldTasks.length > 1) {
        description += ` This field has ${this.fieldTasks.length} tasks, which will also be deleted.`;
      }
      return description;
    },
    layers() {
      let layers = [];
      let fields = this.$store.state.fields.fields;
      fields = fields.filter((field) => field.id != this.fieldId);
      let fieldJSON = {
        type: "FeatureCollection",
        features: fields.map((field) => ({
          type: "Feature",
          id: field.id,
          geometry: field.geometry,
          properties: {
            name: field.properties.name,
            link: "/app/fields/" + field.id,
          },
        })),
      };
      let fieldLayer = {
        name: "Other Fields",
        geojson: fieldJSON,
        style: { fillColor: "red", opacity: 1, weight: 1, color: "green" },
      };
      //console.log("fields layer", fieldLayer);
      layers.push(fieldLayer);
      return layers;
    },
    locations() {
      let locations = this.$store.getters.getGrowerLocationsByFieldId(
        this.fieldId,
      );
      return locations;
    },
    entrance_points() {
      let locations = this.$store.getters.getGrowerLocationsByFieldId(
        this.fieldId,
      );
      let entrance_points = 0;
      if (locations?.length > 0) {
        if (locations.properties) {
          entrance_points = locations.filter(
            (location) => location.properties?.label == "Entrance",
          )?.length;
        } else {
          entrance_points = locations.filter(
            (location) => location.type == "Entrance",
          )?.length;
        }
      }
      // console.log("entrance points", entrance_points, locations);
      return entrance_points;
    },
    pickup_points() {
      let locations = this.$store.getters.getGrowerLocationsByFieldId(
        this.fieldId,
      );
      let pickup_points = 0;
      if (locations?.length > 0) {
        if (locations.properties) {
          pickup_points = locations.filter(
            (location) => location.properties?.label == "Pick up",
          )?.length;
        } else {
          pickup_points = locations.filter(
            (location) => location.type == "Pick up",
          )?.length;
        }
      }
      return pickup_points;
    },
  },
  methods: {
    // Modal Methods
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    async deleteField() {
      this.modalIsActive = false;
      console.log("DELETE");
      if (this.fieldRecords.length > 0) {
        this.fieldRecords.forEach(async (record) => {
          await this.$store.dispatch("deleteRecord", {
            id: record.id,
          });
        });
      }
      if (this.fieldTasks.length > 0) {
        this.fieldTasks.forEach(async (task) => {
          await this.$store.dispatch("deleteTask", {
            id: task.id,
          });
        });
      }

      await this.$store.dispatch("deleteField", {
        id: this.fieldId,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "fields" });
      }
    },
    updateFieldShape(feature, acres) {
      // console.log("update that shape!")
      // console.log("New vals", feature, acres)
      this.currentField.properties.acres = acres;
      this.currentField.geometry = feature.geometry;
      this.$refs.fieldForm.updateFieldShape(feature, acres);
      // console.log(this.currentField)
    },
    markLocation(location) {
      this.markedLocationPoint = location;
      this.showLocationModal = true;
    },
    saveLocation() {
      this.showLocationModal = false;
      this.$store.dispatch("createGrowerLocation", {
        field: this.fieldId,
        type: this.markedLocationType,
        point: this.markedLocationPoint,
      });
      this.markedLocationType = null;
      this.markedLocationPoint = null;
    },
    deleteLocation(id) {
      this.$store.dispatch("deleteGrowerLocation", { id });
    },
    async saveField() {
      // console.log("save field");
      // this.$refs.recordsTable.saveRecords();
    },
    async addCrop() {
      let newCrop = {};
      if (this.copyCropDetails) {
        newCrop = {
          crop_type: this.currentCrop?.crop_type,
          details: this.currentCrop?.details,
          active: this.newActiveCrop,
        };
      } else {
        newCrop = {
          crop_type: this.newCropType,
          active: this.newActiveCrop,
          details: {
            variety: "", // workaround to have a non empty object
          },
        };
      }
      if (this.harvest_range_start) {
        newCrop.harvest_range_start = this.harvest_range_start;
      }
      if (this.harvest_range_end) {
        newCrop.harvest_range_end = this.harvest_range_end;
      }
      console.log("add crop", newCrop);
      newCrop = await this.$store.dispatch("createCrop", {
        fieldId: this.fieldId,
        crop: newCrop,
      });

      if (newCrop) {
        if (newCrop.active) {
          await this.$store.dispatch("getFields");
          this.currentField = this.$store.getters.getFieldById(
            parseInt(this.$route.params.fieldId),
          );

          this.$router.push({
            name: "single-field",
            params: { fieldId: this.fieldId },
          });
        } else {
          this.$router.push({
            name: "single-field",
            params: { fieldId: this.fieldId },
            query: { cropId: newCrop.id },
          });
        }
      }
      this.cropModalIsActive = false;
    },
  },
  watch: {
    fieldId: {
      handler() {
        let main = document.getElementById("main-content");
        // console.log("route changed", main, main?.scrollTop);
        if (main) main.scrollTo(0, 0);
      },
      immediate: true,
    },
  },
};
</script>
