<template>
  <slot-modal
    :open="modalIsActive"
    modalTitle="Submit an Inquiry"
    modalDescription="Submit your crop inquiry to the seller."
    @closeModal="closeInquiryModal"
  >
    <template #icon
      ><PaperAirplaneIcon class="text-black-600 h-6 w-6" aria-hidden="true"
    /></template>
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <select-dropdown
          :selectOptions="offers"
          inputTitle="Select Offer"
          v-model="inquiryOffer"
        />
        <text-input
          :wideItem="true"
          inputTitle="Crop"
          inputHtmlType="text"
          :displayOnly="true"
          :displayOnlyValue="currentOffer?.crop"
        />
        <number-input
          :wideItem="true"
          inputTitle="Offer Quantity"
          inputHtmlType="text"
          :required="true"
          :displayOnly="true"
          :displayOnlyValue="currentOffer?.quantity"
        />
        <text-input
          :wideItem="true"
          inputTitle="Units"
          inputHtmlType="text"
          :displayOnly="true"
          :displayOnlyValue="currentOffer?.quantity_units"
        />
        <div class="hidden sm:col-span-3" />
        <number-input
          :wideItem="true"
          inputTitle="Quantity You'd Like to Buy"
          inputHtmlType="text"
          :required="true"
          v-model="inquiryQuantity"
        />
      </div>
    </template>
    <template #buttons
      ><button
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
        @click="createInquiry"
      >
        Submit
      </button></template
    >
  </slot-modal>
  <page-card headingTitle="Crop Supply Search">
    <template #buttonArea>
      <base-button
        :buttonText="'Search'"
        :marginLeft="false"
        @buttonClick="searchCropSupply"
      />
    </template>
    <template #cardDetails>
      <select-dropdown
        inputTitle="Crop Type"
        :selectOptions="cropOptions"
        :required="true"
        v-model="crop_type"
        :wideItem="true"
      />
      <checkbox-input
        :wideItem="true"
        inputTitle="Show Advanced Parameters"
        v-model="showAdvancedParams"
      />
      <div
        v-if="showAdvancedParams"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <!-- <select-dropdown
          inputTitle="Variety"
          :selectOptions="[{ label: 'yellow', value: 'yellow' }]"
          v-model="crop_variety"
          :wideItem="true"
        /> -->
        <number-input
          :wideItem="true"
          inputTitle="Acres Min"
          inputHtmlType="text"
          :required="true"
          v-model="acres_min"
        />
        <number-input
          :wideItem="true"
          inputTitle="Acres Max"
          inputHtmlType="text"
          :required="true"
          v-model="acres_max"
        />
        <date-input
          :wideItem="true"
          inputTitle="Harvest Before"
          inputHtmlType="date"
          v-model="harvest_before"
        />
        <date-input
          :wideItem="true"
          inputTitle="Harvest After"
          inputHtmlType="date"
          v-model="harvest_after"
        />
      </div>
    </template>
  </page-card>
  <div class="mt-4"></div>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'name', label: 'Grower' },
          { name: 'crop_type', label: 'Crop' },
          { name: 'acres', label: 'Acres' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="cropSupply"
        @update:derived-collection="derivedCropSupply = $event"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="g in derivedCropSupply" :key="g.id">
        <table-data td-label="Grower">
          {{ g.name }}
        </table-data>
        <table-data td-label="Crop">
          {{ g.crop_type }}
        </table-data>
        <table-data td-label="Acres">
          {{ g.acres }}
        </table-data>
        <table-data class="text-center">
          <button
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
            @click="intializeInquiryModal(g.id)"
          >
            Submit Inquiry
          </button>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import TextInput from "@/components/form/TextInput.vue";
import DateInput from "@/components/form/DateInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import { PaperAirplaneIcon } from "@heroicons/vue/outline";
import { useToast } from "vue-toastification";

import client from "@/api/parcel/api.js";

let cropList = require("@/assets/croplist.js");

export default {
  components: {
    PageCard,
    BaseButton,
    TableHead,
    TableCard,
    TableData,
    SlotModal,
    TextInput,
    DateInput,
    NumberInput,
    CheckboxInput,
    SelectDropdown,
    PaperAirplaneIcon,
  },
  data() {
    return {
      toast: useToast(),
      modalIsActive: false,
      // Crop Supply
      cropSupply: [],
      derivedCropSupply: [],
      // Inquiry Data
      inquiryOffer: null,
      inquiryQuantity: null,
      grower_id: null,
      // Search Query Params
      showAdvancedParams: false,
      crop_type: null, // string
      crop_variety: null, // string
      acres_min: null, // number
      acres_max: null, // number
      harvest_before: null, // date string
      harvest_after: null, // date string
    };
  },
  computed: {
    offers() {
      let offers = this.$store.getters.getBuyerMarketOffers();
      let selectableOffers = [];
      // iterate through offers, creating a new offer where the offer.contract_name is the label and the offer.id is the value
      for (let offer of offers) {
        if (offer.published === true) {
          selectableOffers.push({
            label: offer.contract_name,
            value: offer.id,
          });
        }
      }
      return selectableOffers;
    },
    currentOffer() {
      return this.$store.getters.getBuyerMarketOfferById(this.inquiryOffer);
    },
    cropOptions() {
      let options = [];
      for (let crop of cropList) {
        options.push(crop.name);
      }
      return options;
    },
  },
  methods: {
    async searchCropSupply() {
      let query = {
        crop_type: this.crop_type,
        variety: this.crop_variety,
        acres_min: this.acres_min,
        acres_max: this.acres_max,
        harvest_before: this.harvest_before,
        harvest_after: this.harvest_after,
      };
      // Remove null, undefined, and empty string values from query
      for (let key in query) {
        if (
          query[key] === null ||
          query[key] === undefined ||
          query[key] === ""
        ) {
          delete query[key];
        }
      }
      const { data } = await client.buyer.cropSupply.read(null, query);
      this.cropSupply = data.crop_supply;
    },

    intializeInquiryModal(grower_id) {
      this.modalIsActive = true;
      this.grower_id = grower_id;
    },
    closeInquiryModal() {
      this.modalIsActive = false;
      this.grower_id = null;
    },
    async createInquiry() {
      if (this.inquiryQuantity === null || this.grower_id === null) {
        this.toast.error("Please enter a quantity");
        return;
      }
      let newThreadId = await this.$store.dispatch("createThread", {
        partner: this.grower_id,
        subject: "Crop Inquiry",
      });
      await this.$store.dispatch("createBuyerCropOrder", {
        offer: parseInt(this.inquiryOffer),
        grower: this.grower_id,
        quantity: this.inquiryQuantity,
        units: this.currentOffer?.quantity_units,
        terms:
          this.inquiryQuantity +
          " " +
          this.currentOffer?.quantity_units +
          " of " +
          this.currentOffer?.crop,
        details: {
          crop: this.currentOffer?.crop,
        },
      });
      let messageContent =
        " We are interested in purchasing " +
        this.inquiryQuantity.toString() +
        " " +
        this.currentOffer?.quantity_units +
        " of " +
        this.currentOffer?.crop;
      let message = {
        content: messageContent,
      };
      this.$store.dispatch("createMessage", {
        threadId: newThreadId,
        message,
      });
      // Route to orders
      this.$router.push({ name: "buyer-inquiries" });
      this.modalIsActive = false;
      this.grower_id = null;
    },
  },
};
</script>
