<template>
  <page-card headingTitle="New Delivery">
    <template #buttonArea>
      <base-button
        buttonText="Save"
        :marginLeft="false"
        @ButtonClick="saveDelivery"
      />
    </template>
    <template #cardDetails>
      <form
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <number-input
          :wideItem="true"
          inputTitle="Crop Order"
          :displayOnly="true"
          :displayOnlyValue="currentDelivery.crop_order"
        />
        <text-input
          :wideItem="true"
          inputTitle="Delivery Location"
          :required="true"
          v-model="currentDelivery.delivery_location"
        />
        <number-input
          :wideItem="true"
          inputTitle="Quantity"
          :required="true"
          v-model="currentDelivery.quantity_delivered"
        />
        <select-dropdown
          :wideItem="true"
          inputTitle="Units"
          :required="true"
          :selectOptions="unitsOptions"
          v-model="currentDelivery.quantity_units"
        />
        <select-search
          :wide-item="true"
          input-title="Field"
          :select-options="fieldCrops"
          v-model="currentDelivery.crop"
        />
      </form>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    TextInput,
    NumberInput,
    SelectSearch,
    SelectDropdown,
  },
  data() {
    return {
      unitsOptions: [
        { label: "Bushels", value: "bu" },
        { label: "Tons", value: "tons" },
        { label: "Pounds", value: "lbs" },
      ],
      currentDelivery: { crop_order: this.$route.params.orderId, crop: null },
    };
  },
  computed: {
    currentOrder() {
      return this.$store.getters.getGrowerCropOrderById(
        this.$route.params.orderId,
      );
    },
    fieldCrops() {
      const results = [{ label: "", value: null }];
      for (let field of this.$store.state.fields.fields) {
        results.push({
          label: field.properties.name,
          value: field.properties.current_crop.id,
        });
      }
      return results;
    },
  },
  methods: {
    async saveDelivery() {
      console.log("save delivery");
      await this.$store.dispatch("createGrowerDelivery", this.currentDelivery);
      if (!this.$store.getters.getApiError) {
        this.$router.push({
          name: "grower-portal-produce-order",
          orderId: this.currentDelivery.crop_order,
        });
      }
    },
  },
  mounted() {},
};
</script>
