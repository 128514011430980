import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      growerDeliveries: [],
    };
  },
  mutations: {
    SET_GROWER_CROP_DELIVERIES(state, deliveryData) {
      state.growerDeliveries = deliveryData;
    },
    ADD_GROWER_CROP_DELIVERY(state, deliveryObject) {
      state.growerDeliveries.push(deliveryObject);
    },
    UPDATE_GROWER_CROP_DELIVERY(state, deliveryObject) {
      state.growerDeliveries = state.growerDeliveries.filter(function (obj) {
        return obj.id !== deliveryObject.id;
      });
      state.growerDeliveries.push(deliveryObject);
    },
    DELETE_GROWER_CROP_DELIVERY(state, deliveryObject) {
      state.growerDeliveries = state.growerDeliveries.filter(function (obj) {
        return obj.id !== deliveryObject.id;
      });
    },
    RESET_GROWER_CROP_DELIVERIES(state) {
      state.growerDeliveries = [];
    },
  },
  actions: {
    async getGrowerDeliveries({ commit }) {
      const { data, errors } = await client.grower.cropDeliveries.read();
      if (errors) {
        console.log(errors);
        // dispatch("catchParcelApiError", errors)
      } else {
        commit("SET_GROWER_CROP_DELIVERIES", data.crop_deliveries);
      }
    },
    async createGrowerDelivery({ commit, dispatch }, delivery) {
      const { data, errors } = await client.grower.cropDeliveries.create(
        null,
        delivery,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("ADD_GROWER_CROP_DELIVERY", data);
        this.dispatch("getGrowerDeliveries");
      }
    },
    async updateGrowerDelivery({ commit, dispatch }, delivery) {
      const { data, errors } = await client.grower.cropDeliveries.update(
        delivery.id,
        delivery,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_GROWER_CROP_DELIVERY", data);
        this.dispatch("getGrowerDeliveries");
      }
    },
    async deleteGrowerDelivery({ commit, dispatch }, delivery) {
      const { data, errors } = await client.grower.cropDeliveries.delete(
        delivery.id,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        console.log(data);
        commit("DELETE_GROWER_CROP_DELIVERY", delivery);
        this.dispatch("getGrowerDeliveries");
      }
    },
    async uploadGrowerCropDeliveryAttachment(
      { dispatch },
      deliveryIdAttachment,
    ) {
      const { data, errors } =
        await client.grower.cropDeliveries.createAttachment(
          deliveryIdAttachment.deliveryId,
          deliveryIdAttachment.file,
        );
      console.log(data, errors);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        return data;
      }
    },
    async removeGrowerCropDeliveryAttachment({ dispatch }, attachmentId) {
      const { data, errors } =
        await client.grower.cropDeliveries.deleteAttachment(attachmentId);
      console.log(data, errors);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        return data;
      }
    },
  },
  getters: {},
};
