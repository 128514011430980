import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      buyerOrders: [],
    };
  },
  mutations: {
    SET_BUYER_CROP_ORDERS(state, orderData) {
      state.buyerOrders = orderData;
    },
    ADD_BUYER_CROP_ORDER(state, orderObject) {
      state.buyerOrders.push(orderObject);
    },
    UPDATE_BUYER_CROP_ORDER(state, orderObject) {
      state.buyerOrders = state.buyerOrders.filter(function (obj) {
        return obj.id !== orderObject.id;
      });
      state.buyerOrders.push(orderObject);
    },
    DELETE_BUYER_CROP_ORDER(state, orderObject) {
      state.buyerOrders = state.buyerOrders.filter(function (obj) {
        return obj.id !== orderObject.id;
      });
    },
    RESET_BUYER_CROP_ORDERS(state) {
      state.buyerOrders = [];
    },
  },
  actions: {
    async getBuyerCropOrders({ commit }) {
      const { data, errors } = await client.buyer.cropOrders.read();
      if (errors) {
        console.log(errors);
      } else {
        // console.log("getBuyerCropOrders", data);
        commit("SET_BUYER_CROP_ORDERS", data.orders);
      }
    },
    async createBuyerCropOrder({ commit, dispatch }, order) {
      const { data, errors } = await client.buyer.cropOrders.create(
        null,
        order,
      );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("ADD_BUYER_CROP_ORDER", data);
        this.dispatch("getBuyerCropOrders");
      }
    },
    async updateBuyerCropOrder({ commit, dispatch }, order) {
      const { data, errors } = await client.buyer.cropOrders.update(order.id, {
        buyer_signature: order.buyer_signature,
      });
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_BUYER_CROP_ORDER", data);
        this.dispatch("getBuyerCropOrders");
      }
    },
    async updateBuyerCropOrderNotes({ commit, dispatch }, order) {
      const { data, errors } = await client.buyer.cropOrders.update(order.id, {
        buyer_notes: order.buyer_notes,
      });
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_BUYER_CROP_ORDER", data);
        this.dispatch("getBuyerCropOrders");
      }
    },
    async deleteBuyerCropOrder({ commit, dispatch }, order) {
      const { data, errors } = await client.buyer.cropOrders.delete(order.id);
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("DELETE_BUYER_CROP_ORDER", order);
        this.dispatch("getBuyerCropOrders");
      }
    },
    // actions for the following endpoints
    // createPremium: httpPOST("buyer/crop_orders/{order_id}/premiums"),
    //   updatePremium: httpPUT("buyer/crop_orders/premiums/{id}"),
    //   deletePremium: httpDELETE("buyer/crop_orders/premiums/{id}"),
    // payload needs to be object with {orderId: orderIdValue, premium: {premiumKey1: premiumValue1, premiumKey2: premiumValue2, ...}}
    async createBuyerCropOrderPremium({ dispatch }, { orderId, premium }) {
      console.log("createBuyerCropOrderPremium", orderId, premium);
      const { data, errors } = await client.buyer.cropOrders.createPremium(
        orderId,
        premium,
      );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        // commit("ADD_BUYER_CROP_ORDER", data);
        this.dispatch("getBuyerCropOrders");
      }
    },
    async updateBuyerCropOrderPremium({ dispatch }, premium) {
      const { data, errors } = await client.buyer.cropOrders.updatePremium(
        premium.id,
        premium,
      );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        // commit("ADD_BUYER_CROP_ORDER", data);
        this.dispatch("getBuyerCropOrders");
      }
    },
    async deleteBuyerCropOrderPremium({ dispatch }, premium) {
      console.log("deleteBuyerCropOrderPremium", premium);
      const { data, errors } = await client.buyer.cropOrders.deletePremium(
        premium.id,
      );
      if (errors) {
        console.log(errors, data);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        // commit("ADD_BUYER_CROP_ORDER", data);
        this.dispatch("getBuyerCropOrders");
      }
    },
  },
  getters: {
    getAllBuyerOrders: (state) => {
      return state.buyerOrders;
    },
    getEnrichedBuyerOrders:
      (state, getters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        // console.log("getEnrichedBuyerOrders")
        let buyerAllocations = getters.getAllBuyerAllocations;
        // console.log("buyerAllocations", buyerAllocations)
        let initialBuyerOrders = state.buyerOrders;
        // console.log("initialBuyerOrders", initialBuyerOrders);
        initialBuyerOrders.forEach((buyerOrder) => {
          // console.log("buyerOrder", buyerOrder.units, buyerOrder.quantity, buyerOrder);
          // Get crop Type
          let crops = buyerOrder.crop_type || "";
          let varieties = buyerOrder.crop_variety || "";
          if (crops == "" && buyerOrder.details?.crop)
            crops = buyerOrder.details.crop;
          let allocatedAcres = 0;
          for (let allocation of buyerAllocations) {
            if (
              allocation.crop_order == buyerOrder.id &&
              crops.indexOf(allocation.shared_data.crop_details?.crop_type) ==
                -1
            ) {
              if (crops.length > 0) crops += ", ";
              crops += allocation.shared_data.crop_details?.crop_type;
            }
            // Get crop Variety
            if (
              allocation.crop_order == buyerOrder.id &&
              varieties.indexOf(
                allocation.shared_data.crop_details?.details?.variety,
              ) == -1
            ) {
              if (varieties.length > 0) varieties += ", ";
              varieties +=
                allocation.shared_data.crop_details?.details?.variety;
            }
            // Get allocated acres
            if (allocation.crop_order == buyerOrder.id) {
              allocatedAcres += allocation.acres;
            }
          }
          buyerOrder.crop_type = crops;
          buyerOrder.crop_variety = varieties;
          buyerOrder.allocated_acres = allocatedAcres;
        });
        // console.log("initialBuyerOrders", initialBuyerOrders);
        // let expandedBuyerOrders = initialBuyerOrders.map((order) => ({
        //   ...order,
        // }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterBuyerOrders = filterFunction
          ? _.filter(initialBuyerOrders, filterFunction)
          : initialBuyerOrders;
        let filteredBuyerOrders = _.isEmpty(filter)
          ? functionFilterBuyerOrders
          : _.filter(functionFilterBuyerOrders, filter);
        let matchedBuyerOrders = _.isEmpty(match)
          ? filteredBuyerOrders
          : _.filter(filteredBuyerOrders, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedBuyerOrders = _.isEmpty(sort)
          ? matchedBuyerOrders
          : _.sortBy(matchedBuyerOrders, sort);
        let paginatedBuyerOrders =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedBuyerOrders,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedBuyerOrders;
        return paginatedBuyerOrders;
      },
    getBuyerOrderById: (state) => (orderId) => {
      return state.buyerOrders.find((order) => order.id === parseInt(orderId));
    },
    // Inquiries
    getBuyerInquiries: (state) => {
      return state.buyerOrders.filter((order) => order.status === "Inquiry");
    },
    getBuyerInquiriesBySeller: (state, getters) => (seller) => {
      const orders = state.buyerOrders.filter(
        (order) => order.seller_details.id == seller,
      );
      const inquiries = orders.filter((order) => order.status === "Inquiry");
      return inquiries.map((order) => {
        order.allocated_acres = getters
          .getBuyerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    getBuyerInquirySellers: (state) => {
      const orders = state.buyerOrders.filter(
        (order) => order.status === "Inquiry",
      );
      // console.log("Inquiry", orders);
      return orders.reduce((sellers, order) => {
        if (sellers[order.seller_details.id] == null) {
          sellers[order.seller_details.id] = order.seller;
          sellers[order.seller_details.id].tons_inquired = 0;
          // sellers[order.seller_details.id].chat_thread_id = 0;
        }
        sellers[order.seller_details.id].tons_inquired += order.quantity;
        return sellers;
      }, {});
    },
    // In Progress
    getBuyerInProgressOrders: (state, getters) => () => {
      let orders = state.buyerOrders.filter(
        (order) => order.status === "In Progress",
      );
      orders = orders.filter(
        (order) =>
          getters.getVendorById(order.seller_details.id)?.seller_confirmed,
      );
      return orders.map((order) => {
        order.allocated_acres = getters
          .getBuyerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    getBuyerInProgressOrdersBySeller: (state, getters) => (seller) => {
      const orders = state.buyerOrders.filter(
        (order) => order.seller_details.id == seller,
      );
      const inProgress = orders.filter(
        (order) => order.status === "In Progress",
      );
      return inProgress.map((order) => {
        order.allocated_acres = getters
          .getBuyerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    getBuyerInProgressOrderSellers: (state, getters) => {
      let orders = state.buyerOrders.filter(
        (order) => order.status === "In Progress",
      );
      orders = orders.filter(
        (order) =>
          getters.getVendorById(order.seller_details.id)?.seller_confirmed,
      );

      // console.log("In Progress Orders", orders);
      return orders.reduce((sellers, order) => {
        if (sellers[order.seller_details.id] == null) {
          sellers[order.seller_details.id] = order.seller;
          sellers[order.seller_details.id].tons_ordered = 0;
          sellers[order.seller_details.id].allocated_acres = 0;
        }
        if (order.units == "Tons") {
          sellers[order.seller_details.id].tons_ordered += order.quantity;
        }
        let allocations = getters.getBuyerAllocationsByOrder(order.id);
        let crop_types = sellers[order.seller_details.id].crop_types || "";
        let varieties = sellers[order.seller_details.id].varieties || "";
        allocations.forEach((alloc) => {
          if (alloc.crop_details?.crop_type) {
            if (crop_types.indexOf(alloc.crop_details.crop_type) == -1) {
              if (crop_types.length != 0) {
                crop_types += ", ";
              }
              crop_types += alloc.crop_details.crop_type;
            }
          }
          if (alloc.crop_details?.details?.variety) {
            if (varieties.indexOf(alloc.crop_details?.details?.variety) == -1) {
              if (varieties.length != 0) {
                varieties += ", ";
              }
              varieties += alloc.crop_details?.details?.variety;
            }
          }
        });
        sellers[order.seller_details.id].crop_types = crop_types;
        sellers[order.seller_details.id].varieties = varieties;
        sellers[order.seller_details.id].allocated_acres += getters
          .getBuyerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);

        return sellers;
      }, {});
    },
    // Canceled
    getBuyerCanceledOrders: (state, getters) => () => {
      let orders = state.buyerOrders.filter(
        (order) => order.status === "Canceled",
      );
      orders = orders.filter(
        (order) =>
          getters.getVendorById(order.seller_details.id)?.seller_confirmed,
      );
      return orders;
    },
    getBuyerCanceledOrdersBySeller: (state, getters) => (seller) => {
      const orders = state.buyerOrders.filter(
        (order) => order.seller_details.id == seller,
      );
      const inProgress = orders.filter((order) => order.status === "Canceled");
      return inProgress.map((order) => {
        order.allocated_acres = getters
          .getBuyerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    // Completed
    getBuyerCompletedOrders: (state, getters) => () => {
      let orders = state.buyerOrders.filter(
        (order) => order.status === "Complete",
      );
      orders = orders.filter(
        (order) =>
          getters.getVendorById(order.seller_details.id)?.seller_confirmed,
      );
      return orders;
    },
    //
    getBuyerCropOrdersBySeller: (state, getters) => (seller) => {
      const orders = state.buyerOrders.filter(
        (order) => order.seller_details.id == seller,
      );
      return orders.map((order) => {
        order.allocated_acres = getters
          .getBuyerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    getBuyerCropOrdersByMarketOffer: (state, getters) => (marketOffer) => {
      const orders = state.buyerOrders.filter(
        (order) => order.offer == marketOffer,
      );
      return orders.map((order) => {
        order.allocated_acres = getters
          .getBuyerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    getBuyerCropOrderSellers: (state, getters) => {
      return state.buyerOrders.reduce((sellers, order) => {
        if (sellers[order.seller_details.id] == null) {
          sellers[order.seller_details.id] = order.seller;
          sellers[order.seller_details.id].tons_ordered = 0;
          sellers[order.seller_details.id].allocated_acres = 0;
        }
        if ((order.units == "Tons") == "Tons") {
          sellers[order.seller_details.id].tons_ordered += order.quantity;
        }
        sellers[order.seller_details.id].allocated_acres += getters
          .getBuyerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return sellers;
      }, {});
    },
    getBuyerCropOrderGrowers: (state) => {
      // iterate through orders and get the unique growers from order.seller_details.id and order.seller_details.name
      let growers = [];
      state.buyerOrders.forEach((order) => {
        let grower = {
          id: order.seller_details.id,
          name: order.seller_details.name,
        };
        if (!growers.some((g) => g.id === grower.id)) {
          growers.push(grower);
        }
      });
      return growers;
    },
    getBuyerCropOrderPremiums: (state) => {
      // iterate through orders
      // for each order, extract all premiums from the array order.premiums
      // add an order_id attribute to each premium from the order.id
      // return all premiums
      let premiums = [];
      state.buyerOrders.forEach((order) => {
        order.premiums.forEach((premium) => {
          premium.order_id = order.id;
          // if (!premiums.some((p) => p.id === premium.id)) {
          //   premiums.push(premium);
          // }
          premiums.push(premium);
        });
      });
      return premiums;
    },
    getDerivedBuyerCropOrders:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedBuyerOrders = state.buyerOrders.map((order) => ({
          ...order,
          // seller_name: order.seller_details.name,
        }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterBuyerOrders = filterFunction
          ? _.filter(expandedBuyerOrders, filterFunction)
          : expandedBuyerOrders;
        let filteredBuyerOrders = _.isEmpty(filter)
          ? functionFilterBuyerOrders
          : _.filter(functionFilterBuyerOrders, filter);
        let matchedBuyerOrders = _.isEmpty(match)
          ? filteredBuyerOrders
          : _.filter(filteredBuyerOrders, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedBuyerOrders = _.isEmpty(sort)
          ? matchedBuyerOrders
          : _.sortBy(matchedBuyerOrders, sort);
        let paginatedBuyerOrders =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedBuyerOrders,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedBuyerOrders;
        return paginatedBuyerOrders;
      },
    // getDerivedBuyerCropOrders:
    //   (state) =>
    //   ({
    //     filter = {},
    //     match = "",
    //     sort = [],
    //     pageSize = null,
    //     pageNum = 0,
    //   }) => {
    //     let expandedBuyerCropOrders = state.buyerOrders;
    //     //console.log("expandedBuyerCropOrders", expandedBuyerCropOrders);

    //     // This section checks if a filter or set of filters exists. If it does, it loops through and checks if any have nested objects to filter,
    //     // like "user.name". If it does, it substitutes the "user.name:value" key for an actual object {user{name:value}}
    //     // NOTE: only currently used in tasks, if it becomes generally needed, we can move to TableHeaderCellWithFilterButton to make it take effect
    //     // globally.
    //     if (
    //       filter &&
    //       Object.keys(filter).length > 0 &&
    //       Object.getPrototypeOf(filter) === Object.prototype
    //     ) {
    //       //console.log("break up", filter);
    //       let newFilter = {};
    //       // loop through each key in the filter to see if we need to parse it.
    //       for (let key in filter) {
    //         //console.log("filterClause", `${key}:${filter[key]}`);
    //         if (key && key.split(".").length > 1) {
    //           var schema = newFilter; // a moving reference to internal objects within obj
    //           var pList = key.split(".");
    //           var len = pList.length;

    //           for (var i = 0; i < len - 1; i++) {
    //             var elem = pList[i];
    //             if (!schema[elem]) schema[elem] = {};
    //             schema = schema[elem];
    //           }
    //           schema[pList[len - 1]] = filter[key];
    //           //console.log("cleaned",newFilter);
    //         } else {
    //           // Not one we need to break up, just copy it to our new object.
    //           newFilter[key] = filter[key];
    //         }
    //       }
    //       filter = newFilter;
    //     }

    //     let filteredBuyerCropOrders = _.isEmpty(filter)
    //       ? expandedBuyerCropOrders
    //       : _.filter(expandedBuyerCropOrders, filter);
    //     let matchedBuyerCropOrders = _.isEmpty(match)
    //       ? filteredBuyerCropOrders
    //       : _.filter(filteredBuyerCropOrders, (item) =>
    //           _.some(item, (val) =>
    //             _.includes(_.lowerCase(val), _.lowerCase(match))
    //           )
    //         );
    //     let sortColumns = Object.keys(sort);
    //     let sortOrders = Object.values(sort);
    //     //console.log("sort in index",sort,sortColumns, sortOrders);
    //     let sortedBuyerCropOrders = _.isEmpty(sort)
    //       ? matchedBuyerCropOrders
    //       : _.orderBy(matchedBuyerCropOrders, sortColumns, sortOrders);
    //     let paginatedBuyerCropOrders =
    //       _.isNumber(pageSize) && _.isNumber(pageNum)
    //         ? _.slice(
    //             sortedBuyerCropOrders,
    //             pageSize * pageNum, // skip
    //             pageSize * pageNum + pageSize // limit
    //           )
    //         : sortedBuyerCropOrders;
    //     return paginatedBuyerCropOrders;
    //   },
  },
};
