<template>
  <div :class="wideItem == true ? 'sm:col-span-3 ' : ''">
    <label class="block text-sm font-medium text-gray-700">
      {{ inputTitle }}
      <span v-if="required" class="text-red-600">*</span>
      <span v-if="helpText">
        <ToolTip :helpText="helpText" :helpTitle="helpTitle" />
      </span>
      <div class="relative mt-1 block w-full">
        <input
          :type="type"
          v-model="inputValue"
          :required="required"
          :disabled="displayOnly"
          :placeholder="placeholder"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-twilight-500 focus:ring-twilight-500 sm:text-sm"
          :class="conditionalInputStyling"
          :step="step"
          @blur="$emit('blur', inputValue)"
          @focus="$emit('focus')"
        />
        <div
          v-if="!valid"
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
        >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
        </div>
      </div>
    </label>
  </div>
</template>
<script>
import {
  ExclamationCircleIcon,
  // InformationCircleIcon,
} from "@heroicons/vue/solid";
// import { InformationCircleIcon } from "@heroicons/vue/outline";

// import Modal from "../modals/PopupModal.vue";
// import BaseButton from "../buttons/BaseButton.vue";
import ToolTip from "../modals/ToolTip.vue";

export default {
  components: {
    ExclamationCircleIcon,
    // InformationCircleIcon,
    // Modal,
    // BaseButton,
    ToolTip,
  },
  props: {
    type: { type: [String, Number], default: "text" },
    inputTitle: { type: String },
    placeholder: { type: String },
    displayOnly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    modelValue: {},
    wideItem: { type: Boolean, default: false },
    isValid: { type: Boolean, default: true },
    classProp: { type: String, default: null },
    step: { type: Number, default: 0.1 },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  emits: ["update:modelValue", "blur", "focus"],
  data() {
    return {
      inputValue: this.modelValue,
      valid: this.isValid,
      showHelp: false,
    };
  },
  computed: {
    conditionalInputStyling() {
      let style = "";
      if (this.displayOnly) style = "bg-gray-200 ";
      if (this.classProp) style += this.classProp;
      return style;
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
    isValid(value) {
      this.valid = value;
    },
    modelValue(value) {
      this.inputValue = value;
    },
  },
};
</script>
