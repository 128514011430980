<template>
  <page-card headingTitle="Crop Supply Search Participation">
    <template #buttonArea>
      <base-button
        :buttonText="cropSupplyParticipation ? 'Opt Out' : 'Opt In'"
        :marginLeft="false"
        @buttonClick="toggleCropSupplyParticipation"
      />
    </template>
    <template #cardDetails>
      <text-input
        :wideItem="true"
        inputTitle="Can Affiliates Search for Your Crop Supply?"
        inputHtmlType="date"
        :displayOnly="true"
        :displayOnlyValue="cropSupplyParticipation ? 'Yes' : 'No'"
      />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TextInput from "@/components/form/TextInput.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    TextInput,
  },
  data() {
    return {};
  },
  computed: {
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
    cropSupplyParticipation() {
      return this.currentOrg.share_crop_supply;
    },
  },
  methods: {
    toggleCropSupplyParticipation() {
      // unroll the current Org and then replace the share_crop_supply with the opposite of what it is
      this.$store.dispatch("updateOrg", {
        ...this.currentOrg,
        share_crop_supply: !this.cropSupplyParticipation,
      });
    },
  },
};
</script>
