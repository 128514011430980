<template>
  <slot-modal
    :open="modalIsActive"
    modalTitle="Respond to Offer"
    modalDescription="Submit your response to the interested buyer. Parcel will connect you with the buyer upon submission so that specific terms may be confirmed."
    @closeModal="cancelRequest"
  >
    <template #icon
      ><PaperAirplaneIcon class="text-black-600 h-6 w-6" aria-hidden="true"
    /></template>
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <text-input
          :wideItem="true"
          inputTitle="Crop"
          inputHtmlType="text"
          :displayOnly="true"
          :displayOnlyValue="currentOffer.crop"
        />
        <number-input
          :wideItem="true"
          inputTitle="Buyer's Desired Quantity"
          inputHtmlType="text"
          :required="true"
          :displayOnly="true"
          :displayOnlyValue="currentOffer.quantity"
        />
        <text-input
          :wideItem="true"
          inputTitle="Order Units"
          inputHtmlType="text"
          :displayOnly="true"
          :displayOnlyValue="currentOffer.quantity_units"
        />
        <div class="hidden sm:col-span-3" />
        <number-input
          :wideItem="true"
          inputTitle="Quantity You'd Like to Sell'"
          inputHtmlType="text"
          :required="true"
          v-model="inquiryQuantity"
        />
        <div class="sm:col-span-6">
          <CheckboxInput
            :wideItem="true"
            inputTitle="I confirm I will provide the crop and / or data as required in the terms if accepted"
            v-model="termsConfirmed"
            :required="true"
          />
        </div>
      </div>
    </template>
    <template #buttons
      ><button
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
        @click="openOfferInquiry"
      >
        Submit
      </button></template
    >
  </slot-modal>
  <page-card headingTitle="View Offer to Buy">
    <template #buttonArea>
      <base-button
        buttonText="Open Inquiry"
        :marginLeft="false"
        @buttonClick="intializeRequestModal(offerId)"
      />
      <base-button
        buttonText="Back"
        @buttonClick="exitOffer"
        :redButton="true"
      />
    </template>
    <template #cardDetails>
      <offer-form :offerId="offerId" />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import OfferForm from "@/layouts/grower-portal/sell/offers/OfferForm.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import { PaperAirplaneIcon } from "@heroicons/vue/outline";
import { useToast } from "vue-toastification";
import CheckboxInput from "@/components/form/CheckboxInput.vue";

export default {
  components: {
    SlotModal,
    PageCard,
    BaseButton,
    OfferForm,
    TextInput,
    NumberInput,
    PaperAirplaneIcon,
    CheckboxInput,
  },
  data() {
    return {
      modalIsActive: false,
      offerIdToRequest: null,
      inquiryQuantity: null,
      toast: useToast(),
      termsConfirmed: true,
    };
  },
  computed: {
    offerId() {
      return this.$route.params.offerId;
    },
    currentOffer() {
      return this.$store.getters.getGrowerMarketOfferById(this.offerId);
    },
  },
  methods: {
    async openOfferInquiry() {
      if (!this.currentOffer) {
        await this.$store.dispatch("getGrowerMarketOffers");
      }
      console.log("openOfferInquiry", this.currentOffer);
      if (this.inquiryQuantity === null) {
        this.toast.error("Please enter a quantity");
        return;
      }
      let newThreadId = await this.$store.dispatch("createThread", {
        partner: this.currentOffer.org,
        subject: "Order Discussion ",
      });
      console.log("newThreadId", newThreadId);
      let order = await this.$store.dispatch("createGrowerCropOrder", {
        offer: parseInt(this.offerId),
        quantity: this.inquiryQuantity,
        units: this.currentOffer.quantity_units,
        terms:
          // this.inquiryQuantity +
          // " " +
          // this.currentOffer.quantity_units +
          // " of " +
          // this.currentOffer.crop +
          // ". Additional Details: " +
          this.currentOffer.contract_description,
        details: {
          threadId: newThreadId,
          crop: this.currentOffer.crop,
        },
      });
      if (this.termsConfirmed) {
        await this.$store.dispatch("updateGrowerCropOrder", {
          id: order.id,
          grower_signature: true,
        });
      }
      let messageContent =
        " We are interested in providing " +
        this.inquiryQuantity.toString() +
        " " +
        this.currentOffer.quantity_units +
        " of " +
        this.currentOffer.crop;
      let message = {
        content: messageContent,
      };
      this.$store.dispatch("createMessage", {
        threadId: newThreadId,
        message,
      });
      // Route to orders
      this.$router.push({ name: "grower-portal-produce-inquiries" });
      this.offerIdToRequest = undefined;
      this.modalIsActive = false;
    },
    intializeRequestModal(offerId) {
      this.modalIsActive = true;
      this.offerIdToRequest = offerId;
    },
    cancelRequest() {
      this.modalIsActive = false;
      this.offerIdToRequest = undefined;
    },
    exitOffer() {
      this.$router.push({ name: "grower-portal-market-offers" });
    },
  },
};
</script>
