import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import localForage from "localforage";
import { clone } from "pouchdb-utils";

let vuexLocal;
if (process.env.VUE_APP_STORE_DEBUG_MODE !== "1") {
  vuexLocal = new VuexPersistence({
    key: "vuex",
    storage: localForage,
    asyncStorage: true,
    reducer: (state) => clone(state),
  });
}

/* common */
import load from "./parcelApi/load.js";
import error from "./parcelApi/error.js";
import session from "./parcelApi/common/session.js";
import companies from "./parcelApi/common/companies.js";
import customers from "./parcelApi/common/customers.js";
import vendors from "./parcelApi/common/vendors.js";
import fields from "./parcelApi/common/fields.js";
import crops from "./parcelApi/common/crops.js";
import equipment from "./parcelApi/common/equipment.js";
import orgs from "./parcelApi/common/orgs.js";
import affiliateOrgs from "./parcelApi/common/affiliateOrgs.js";
import integrations from "./parcelApi/common/integrations.js";
import settings from "./parcelApi/common/settings.js";
import profile from "./parcelApi/common/profile.js";
import tasks from "./parcelApi/common/tasks.js";
import records from "./parcelApi/common/records.js";
import sharedRecords from "./parcelApi/common/shared-records.js";
import sharedCrops from "./parcelApi/common/shared-crops.js";
import sharedFields from "./parcelApi/common/shared-fields.js";
import sharedEquipment from "./parcelApi/common/shared-equipment.js";
import sharedTasks from "./parcelApi/common/shared-tasks.js";
import users from "./parcelApi/common/users.js";
import externalOrgs from "./parcelApi/common/externalOrgs.js";
import messages from "./parcelApi/common/messages.js";
import notifications from "./parcelApi/common/notifications.js";
import threads from "./parcelApi/common/threads.js";
import scenarios from "./parcelApi/common/scenarios.js";

/* shared */
import sharedScenarios from "./parcelApi/shared/shared-scenarios.js";

/* buyer */
import buyerListings from "./parcelApi/buyer/crop-listings.js";
import buyerAllocations from "./parcelApi/buyer/crop-allocations.js";
import buyerDeliveries from "./parcelApi/buyer/crop-deliveries.js";
import buyerOrders from "./parcelApi/buyer/crop-orders.js";
import buyerMarketOffers from "./parcelApi/buyer/market-offers.js";
import buyerCropVendors from "./parcelApi/buyer/crop-vendors.js";
import buyerContractOriginators from "./parcelApi/buyer/contract-originators.js";
import controllerContracts from "./parcelApi/controller/contracts.js";
import controllerMarketAffiliates from "./parcelApi/controller/market-affiliates.js";
import controllerMarketOffers from "./parcelApi/controller/market-offers.js";
import controllerAllocations from "./parcelApi/controller/crop-allocations.js";
import controllerOrders from "./parcelApi/controller/crop-orders.js";
import controllerDeliveries from "./parcelApi/controller/crop-deliveries.js";
import controllerSummaries from "./parcelApi/controller/summary.js";

/* grower */

import growerListings from "./parcelApi/grower/crop-listings.js";
import growerCropOrders from "./parcelApi/grower/crop-orders.js";
import growerAllocations from "./parcelApi/grower/crop-allocations.js";
import growerDeliveries from "./parcelApi/grower/crop-deliveries.js";

import growerProducts from "./parcelApi/grower/services.js";
import growerQuotes from "./parcelApi/grower/service-agreements.js";
import growerServiceOrders from "./parcelApi/grower/service-orders.js";

import growerLocations from "./parcelApi/grower/service-locations.js";
import growerInvoices from "./parcelApi/grower/service-invoices.js";
import growerRecommendations from "./parcelApi/grower/service-recommendations.js";
import growerOpportunities from "./parcelApi/grower/service-opportunities.js";

import growerMarketOffers from "./parcelApi/grower/market-offers.js";

import growerCropCustomers from "./parcelApi/grower/crop-customers.js";

/* vendor */
import vendorProducts from "./parcelApi/vendor/services.js";
import vendorQuotes from "./parcelApi/vendor/service-agreements.js";
import vendorOrders from "./parcelApi/vendor/service-orders.js";
import vendorTerritories from "./parcelApi/vendor/service-territories.js";
import vendorLocations from "./parcelApi/vendor/service-locations.js";
import vendorInvoices from "./parcelApi/vendor/service-invoices.js";
import vendorRecommendations from "./parcelApi/vendor/service-recommendations.js";
import vendorOpportunities from "./parcelApi/vendor/service-opportunities.js";

/* Input Sales */
import vendorInputAgreements from "./parcelApi/input_sales/vendor_input_agreements.js";
import vendorInputListings from "./parcelApi/input_sales/vendor_input_listings.js";
import vendorInputOrders from "./parcelApi/input_sales/vendor_input_orders.js";
import growerInputAgreements from "./parcelApi/input_sales/grower_input_agreements.js";
import growerInputListings from "./parcelApi/input_sales/grower_input_listings.js";
import growerInputOrders from "./parcelApi/input_sales/grower_input_orders.js";

// Products
import growerHauling from "./products/grower-hauling/index.js";
import workingAllocations from "./products/working-allocations/index.js";
import viewState from "./products/view-state/index.js";
import subscriptionPreference from "./products/subscription-preference.js";
import blog from "./products/blog/index.js";
import linesOfBusiness from "./products/lines-of-business";
import shoppingCart from "./products/shopping-cart.js";
import actionsGrower from "./products/actions-grower.js";
import signupReferrer from "./products/signup-referrer.js";
import actionsBuyerAffiliate from "./products/actions-buyer-affiliate.js";
import actionsBuyerController from "./products/actions-buyer-controller.js";
import whiteLabel from "./products/white-label.js";
// import actionsBuyer from "./products/actions-buyer.js";
// import actionsVendor from "./products/actions-vendor.js";

let initialState;
const store = createStore({
  modules: {
    // Parcel Api
    /* common */
    load,
    error,
    session,
    customers,
    vendors,
    companies,
    equipment,
    fields,
    crops,
    messages,
    notifications,
    orgs,
    affiliateOrgs,
    integrations,
    settings,
    profile,
    tasks,
    records,
    threads,
    users,
    externalOrgs,
    sharedRecords,
    sharedCrops,
    sharedFields,
    sharedEquipment,
    sharedTasks,
    scenarios,

    /* shared */
    sharedScenarios,

    /* buyer */
    buyerListings,
    buyerOrders,
    buyerAllocations,
    buyerDeliveries,
    buyerMarketOffers,
    buyerCropVendors,
    buyerContractOriginators,
    /* controller */
    controllerContracts,
    controllerMarketAffiliates,
    controllerMarketOffers,
    controllerAllocations,
    controllerOrders,
    controllerDeliveries,
    controllerSummaries,

    /* grower */

    growerAllocations,
    growerDeliveries,
    growerListings,
    growerCropOrders,

    growerProducts,
    growerQuotes,
    growerServiceOrders,
    growerLocations,
    growerInvoices,
    growerRecommendations,
    growerOpportunities,

    growerMarketOffers,
    growerCropCustomers,

    /* vendor */
    vendorProducts,
    vendorQuotes,
    vendorOrders,
    vendorTerritories,
    vendorLocations,
    vendorInvoices,
    vendorRecommendations,
    vendorOpportunities,

    /* Input Sales */
    vendorInputAgreements,
    vendorInputListings,
    vendorInputOrders,
    growerInputAgreements,
    growerInputListings,
    growerInputOrders,

    // Products
    growerHauling,
    workingAllocations,
    viewState,
    subscriptionPreference,
    blog,
    linesOfBusiness,
    shoppingCart,
    actionsGrower,
    signupReferrer,
    actionsBuyerAffiliate,
    actionsBuyerController,
    whiteLabel,
    // actionsBuyer,
    // actionsVendor,
  },
  state() {
    return {};
  },
  mutations: {
    CLEAR_APP_DATA(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      });
    },
  },
  actions: {
    clearAppData({ commit }) {
      commit("CLEAR_APP_DATA");
    },
  },
  plugins: vuexLocal && [vuexLocal.plugin],
});
initialState = structuredClone(store.state);

export default store;
