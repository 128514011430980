//import { ref, computed } from 'vue'
// corn is 56 lbs per bushel
// soybeans are 60 lbs per bushel
// wheat is 60 lbs per bushel
// barley is 48 lbs per bushel

export function tonsToBushels(crop, tons) {
  let bushels = 0;
  switch (crop) {
    case "Corn":
      bushels = (tons * 2000) / 56;
      break;
    case "Soybeans":
      bushels = (tons * 2000) / 60;
      break;
    case "Wheat":
      bushels = (tons * 2000) / 60;
      break;
    case "Barley":
      bushels = (tons * 2000) / 48;
      break;
    default:
      bushels = tons;
      break;
  }
  //console.log("converted tons to bushels: ", tons, bushels);
  return bushels ?? 0; // in case tons is null or undefined
}

export function gallonsToBTU(gallons, fuel) {
  let btu = 0;
  switch (fuel) {
    case "Diesel":
      btu = gallons * 138690;
      break;
    case "Gasoline":
      btu = gallons * 125000;
      break;
    case "Propane":
      btu = gallons * 91500;
      break;
    case "Natural Gas":
      btu = gallons * 100000;
      break;
    default:
      btu = gallons * 138690;
      break;
  }
  //console.log("converted gallons to btu: ", gallons, btu);
  return btu;
}

export function btuToGallons(btu, fuel) {
  let gallons = 0;
  switch (fuel) {
    case "Diesel":
      gallons = btu / 138690;
      break;
    case "Gasoline":
      gallons = btu / 125000;
      break;
    case "Propane":
      gallons = btu / 91500;
      break;
    case "Natural Gas":
      gallons = btu / 100000;
      break;
    default:
      gallons = btu / 138690;
      break;
  }
  //console.log("converted btu to gallons: ", btu, gallons);
  return gallons;
}

export function bushelsToTons(crop, bushels) {
  let tons = 0;
  switch (crop) {
    case "Corn":
      tons = (bushels * 56) / 2000;
      break;
    case "Soybeans":
      tons = (bushels * 60) / 2000;
      break;
    case "Wheat":
      tons = (bushels * 60) / 2000;
      break;
    case "Barley":
      tons = (bushels * 48) / 2000;
      break;
    default:
      bushels = tons;
      break;
  }
  //console.log("converted bushels to tons: ", bushels, tons);
  return tons;
}

export function harvestUnits(crop) {
  let units = {};
  switch (crop) {
    // bushel crops
    case "Apples":
      units = { label: "Bushels", value: "bu" };
      break;
    case "Barley":
      units = { label: "Bushels", value: "bu" };
      break;
    case "Corn":
      units = { label: "Bushels", value: "bu" };
      break;
    case "Corn - Silage":
      units = { label: "Tons", value: "tons" };
      break;
    case "Soybeans":
      units = { label: "Bushels", value: "bu" };
      break;
    case "Wheat":
      units = { label: "Bushels", value: "bu" };
      break;
    // ton crops
    case "Grape":
      units = { label: "Tons", value: "tons" };
      break;
    case "Hay":
      units = { label: "Tons", value: "tons" };
      break;
    default:
      units = { label: "Pounds", value: "lbs" };
      break;
  }
  return units;
}

export function getNPKEquivalents(form) {
  let nitrogen = 0;
  let phosphorus = 0;
  let potassium;

  switch (form) {
    case "Ammonia":
      nitrogen = 82;
      phosphorus = 0;
      potassium = 0;
      break;
    case "Ammonium Nitrate":
      nitrogen = 34;
      phosphorus = 0;
      potassium = 0;
      break;
    case "Ammonium Sulfate":
      nitrogen = 21;
      phosphorus = 0;
      potassium = 0;
      break;
    case "Diammonium Phosphate":
      nitrogen = 18;
      phosphorus = 46;
      potassium = 0;
      break;
    case "Monoammonium Phosphate":
      nitrogen = 11;
      phosphorus = 52;
      potassium = 0;
      break;
    case "Potash":
      nitrogen = 0;
      phosphorus = 0;
      potassium = 60;
      break;
    case "Urea":
      nitrogen = 46;
      phosphorus = 0;
      potassium = 0;
      break;
    case "Urea Ammonium Nitrate":
      nitrogen = 28;
      phosphorus = 0;
      potassium = 0;
      break;
  }
  return { nitrogen, phosphorus, potassium };
}
