<template>
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Core
  </div>
  <number-input
    :required="true"
    :wideItem="true"
    inputTitle="Crop Yield (bushels/acre)"
    inputHtmlType="text"
    v-model="currentInputs.crop_yield"
  />
  <number-input
    :required="true"
    :wideItem="true"
    inputTitle="Farm Size (acres)"
    inputHtmlType="text"
    v-model="currentInputs.farm_size"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Energy
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Diesel (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.diesel"
  />
  <number-input
    :wideItem="true"
    inputTitle="Electricity (kWh/acre)"
    inputHtmlType="text"
    v-model="currentInputs.electricity"
  />
  <number-input
    :wideItem="true"
    inputTitle="Gasoline (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.gasoline"
  />
  <number-input
    :wideItem="true"
    inputTitle="Liquefied Petroleum Gas (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.liquefied_petroleum_gas"
  />
  <number-input
    :wideItem="true"
    inputTitle="Natural Gas (ft3/acre)"
    inputHtmlType="text"
    v-model="currentInputs.natural_gas"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Nitrogen Fertilizer
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Ammonia (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.ammonia"
  />
  <number-input
    :wideItem="true"
    inputTitle="Ammonium Nitrate (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.ammonium_nitrate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Ammonium Sulfate (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.ammonium_sulfate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Diammonium Phosphate (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.n_diammonium_phosphate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Monoammonium Phosphate (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.n_monoammonium_phosphate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Urea (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.urea"
  />
  <number-input
    :wideItem="true"
    inputTitle="Urea Ammonium Nitrate Solution (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.urea_ammonium_nitrate_solution"
  />

  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Phosphorus Fertilizer
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Diammonium Phosphate (lbs P2O5/acre)"
    inputHtmlType="text"
    v-model="currentInputs.p_diammonium_phosphate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Monoammonium Phosphate (lbs P2O5/acre)"
    inputHtmlType="text"
    v-model="currentInputs.p_monoammonium_phosphate"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Potash Fertilizer
  </div>
  <!-- Content goes here -->
  <number-input
    :wideItem="true"
    inputTitle="Potash Fertilizer (lbs K2O/acre)"
    inputHtmlType="text"
    v-model="currentInputs.potash_fertilizer"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Other
  </div>
  <select-dropdown
    :wideItem="true"
    inputTitle="Source of Ammonia"
    :selectOptions="[
      {
        label: 'Conventional',
        value: 'Conventional',
      },
      { label: 'Green', value: 'Green' },
    ]"
    v-model="currentInputs.source_of_ammonia"
  />
  <number-input
    :wideItem="true"
    inputTitle="Herbicide (g/acre)"
    inputHtmlType="text"
    v-model="currentInputs.herbicide"
  />
  <number-input
    :wideItem="true"
    inputTitle="Insecticide (g/acre)"
    inputHtmlType="text"
    v-model="currentInputs.insecticide"
  />
  <number-input
    :wideItem="true"
    inputTitle="Lime (lbs/acre)"
    inputHtmlType="text"
    v-model="currentInputs.lime"
  />
</template>

<script>
import NumberInput from "@/components/form/NumberInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  emits: ["updateScenarioInputs"],
  props: {
    scenarioInputsInitial: {
      type: Object,
      default: null,
    },
  },
  components: {
    NumberInput,
    SelectDropdown,
  },
  data() {
    return {
      currentInputs: this.scenarioInputsInitial
        ? this.scenarioInputsInitial
        : {
            farm_size: null,
            crop_yield: null,
            diesel: null,
            gasoline: null,
            natural_gas: null,
            liquefied_petroleum_gas: null,
            electricity: null,
            ammonia: null,
            urea: null,
            ammonium_nitrate: null,
            ammonium_sulfate: null,
            urea_ammonium_nitrate_solution: null,
            n_monoammonium_phosphate: null,
            n_diammonium_phosphate: null,
            p_monoammonium_phosphate: null,
            p_diammonium_phosphate: null,
            potash_fertilizer: null,
            lime: null,
            herbicide: null,
            insecticide: null,
            source_of_ammonia: "Conventional",
          },
    };
  },
  computed: {},
  methods: {
    resetScenarioInputs() {
      this.currentInputs = {
        farm_size: null,
        crop_yield: null,
        diesel: null,
        gasoline: null,
        natural_gas: null,
        liquefied_petroleum_gas: null,
        electricity: null,
        ammonia: null,
        urea: null,
        ammonium_nitrate: null,
        ammonium_sulfate: null,
        urea_ammonium_nitrate_solution: null,
        n_monoammonium_phosphate: null,
        n_diammonium_phosphate: null,
        p_monoammonium_phosphate: null,
        p_diammonium_phosphate: null,
        potash_fertilizer: null,
        lime: null,
        herbicide: null,
        insecticide: null,
        source_of_ammonia: "Conventional",
      };
    },
  },
  watch: {
    currentInputs: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.$emit("updateScenarioInputs", value);
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
