<template>
  <!-- <alert-modal
    :open="modalIsActive"
    modalTitle="Field Shape Required"
    modalDescription="You must draw a shape to save a new field."
    @clickDismiss="dismissModal"
  /> -->
  <page-card headingTitle="Carbon Score Form">
    <template #buttonArea>
      <base-button
        :buttonText="'Submit'"
        :marginLeft="false"
        @buttonClick="submitCarbonScenario"
      />
    </template>
    <template #cardDetails>
      <div
        class="mt-2 text-left text-sm font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
      >
        Any empty input will use the model defaults
      </div>
      <div
        class="mt-2 text-left text-sm font-medium tracking-wider text-gray-500 sm:col-span-6"
        v-if="sharingCarbonAgreements"
      >
        Submit this as a shared scenario
        <checkbox-input inputTitle="Show" v-model="showSharedScenario" />
        <div v-if="showSharedScenario">
          <select-dropdown
            inputTitle="Create Scenario For"
            :selectOptions="uniqueOrgNames"
            v-model="orgNameToShareWith"
            :wideItem="true"
          />
        </div>
      </div>
      <div
        class="mt-2 text-left text-sm font-medium tracking-wider text-gray-500 sm:col-span-6"
      >
        Use Field Selection for Acres Calculation
        <checkbox-input inputTitle="Show" v-model="showFieldSelect" />
        <div v-if="showFieldSelect">
          <table-card>
            <template #header>
              <table-head
                :columns="[
                  { name: 'properties.farm', label: 'Farm' },
                  { name: 'properties.name', label: 'Field', filtered: false },
                  { name: 'properties.crop', label: 'Crop' },
                  { name: 'properties.details.variety', label: 'Variety' },
                  { name: 'properties.acres', label: 'Acres', filtered: false },
                ]"
                :collection="fields"
                @update:derived-collection="derivedFields = $event"
                selectionMode="Multiple"
                @update:selectedCollection="selectedFields = $event"
              >
                <template #toolbarButtons>
                  <div class="hidden sm:flex">
                    <table-toolbar-button
                      buttonText="Apply Selected Acres"
                      :usePlusIcon="true"
                      @buttonClick="applySelectedAcres"
                    />
                  </div>
                  <!-- <table-toolbar-button
                    buttonText="New"
                    :usePlusIcon="true"
                    @buttonClick="showAlert('New')"
                  /> -->
                </template>
              </table-head>
            </template>
            <tbody>
              <tr v-for="field in derivedFields" :key="field">
                <table-data td-label="Selected">
                  <input
                    type="checkbox"
                    v-model="field.selected"
                    class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
                    @change="
                      selectedFields = derivedFields.filter(
                        (field) => field.selected,
                      )
                    "
                  />
                </table-data>
                <table-data td-label="Farm">
                  {{ field.properties.farm }}
                </table-data>
                <table-data td-label="Field">
                  <div class="flex">
                    <field-thumbnail
                      :title="field.properties.name"
                      :geojson="field"
                      class="mr-6"
                    ></field-thumbnail>
                    {{ field.properties.name }}
                  </div>
                </table-data>
                <table-data td-label="Crop">
                  {{ field.properties.crop }}
                </table-data>
                <table-data td-label="Variety">
                  {{
                    field.properties?.details?.variety
                      ? field.properties?.details?.variety
                      : ""
                  }}
                </table-data>
                <table-data td-label="Acres">
                  {{ field.properties.acres }}
                </table-data>
              </tr>
            </tbody>
          </table-card>
        </div>
      </div>
      <div
        class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
      >
        Scenario Details
      </div>
      <select-dropdown
        :required="true"
        :wideItem="true"
        inputTitle="Crop"
        :selectOptions="cropSelectOptions"
        v-model="scenarioCrop"
      />
      <text-input
        :wideItem="true"
        :required="true"
        inputTitle="Scenario Name"
        v-model="scenarioName"
      />
      <text-input
        v-if="scenarioCrop === 'other'"
        :wideItem="true"
        :required="true"
        inputTitle="Crop Type"
        v-model="otherCropName"
      />
      <text-input
        v-if="scenarioCrop === 'other'"
        :wideItem="true"
        :required="true"
        inputTitle="Harvest Units"
        help-text="Enter the units of harvest for the crop type. For example, bushels, tons, etc."
        v-model="otherCropHarvestUnits"
      />
      <component
        :is="cropInputType"
        :scenarioInputsInitial="scenarioInputs"
        @updateScenarioInputs="updateScenarioInputs"
        ref="scenarioCropInputComponent"
      />
    </template>
  </page-card>
</template>

<script>
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TextInput from "@/components/form/TextInput.vue";
import { useToast } from "vue-toastification";
// import AlertModal from "@/components/modals/AlertModal.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

import CornInputs from "@/layouts/common/carbon/scenarioCropInputs/CornInputs.vue";
import RiceInputs from "@/layouts/common/carbon/scenarioCropInputs/RiceInputs.vue";
import SorghumInputs from "@/layouts/common/carbon/scenarioCropInputs/SorghumInputs.vue";
import SoybeanInputs from "@/layouts/common/carbon/scenarioCropInputs/SoybeanInputs.vue";
import SugarcaneInputs from "@/layouts/common/carbon/scenarioCropInputs/SugarcaneInputs.vue";
import OtherInputs from "@/layouts/common/carbon/scenarioCropInputs/OtherInputs.vue";

import {
  gallonsToBTU,
  btuToGallons,
} from "@/components/composables/convertUnits";
Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0;
};

export default {
  props: {
    scenarioId: {
      type: [Number, String],
      default: null,
    },
    onSubmitNavigationRouteName: {
      type: String,
      required: true,
    },
    sharingCarbonAgreements: {
      type: Array,
      default: null,
    },
    // needed to allow a submit button outside the form tags to submit the form
    // formId: {
    //   type: String,
    //   required: true,
    // },
  },
  components: {
    SelectDropdown,
    // AlertModal,
    PageCard,
    BaseButton,
    TextInput,
    CheckboxInput,
    TableHead,
    TableCard,
    TableData,
    TableToolbarButton,
    FieldThumbnail,
    CornInputs,
    RiceInputs,
    SorghumInputs,
    SoybeanInputs,
    SugarcaneInputs,
    OtherInputs,
  },
  data() {
    return {
      scenarioName: null,
      scenarioCrop: null,
      scenarioInputs: null,
      modalIsActive: false,
      toast: useToast(),
      showFieldSelect: false,
      showSharedScenario: false,
      orgNameToShareWith: null,
      derivedFields: [],
      // Metadata
      selectedFields: [],
      otherCropName: null,
      otherCropHarvestUnits: null,
      cropSelectOptions: [
        { label: "Corn", value: "corn" },
        { label: "Rice", value: "rice" },
        { label: "Sorghum", value: "sorghum" },
        { label: "Soybean", value: "soybean" },
        { label: "Sugarcane", value: "sugarcane" },
        { label: "Other", value: "other" },
      ],
    };
  },
  computed: {
    currentScenario() {
      let scenario = this.$store.getters.getScenarioById(this.scenarioId);
      // convert inputs to display units
      scenario.inputs.manure_transportation_gallons =
        btuToGallons(scenario.inputs.manure_transportation_energy, "diesel") *
        (scenario.inputs.beef_cattle +
          scenario.inputs.chicken +
          scenario.inputs.dairy_cow +
          scenario.inputs.swine) *
        scenario.inputs.manure_transportation_distance;
      scenario.inputs.manure_application_gallons =
        btuToGallons(scenario.inputs.manure_application_energy, "diesel") *
        scenario.inputs.farm_size;
      scenario.inputs.cover_crop_farming_gallons =
        btuToGallons(scenario.inputs.cover_crop_farming_energy, "diesel") *
        scenario.inputs.farm_size;
      // console.log("loaded scenario", scenario);
      return scenario;
    },
    selectedFieldIds() {
      return this.selectedFields?.map((field) => field.id);
    },
    submissionScenario() {
      let scenario = {
        name: this.scenarioName,
        crop: this.scenarioCrop,
        inputs: this.scenarioInputs,
        metadata: {
          fields: this.selectedFieldIds,
          other_crop_type: this.otherCropName,
          harvest_units: this.otherCropHarvestUnits,
        },
      };
      return scenario;
    },
    cropInputType() {
      switch (this.scenarioCrop) {
        case "corn":
          return "CornInputs";
        case "rice":
          return "RiceInputs";
        case "sorghum":
          return "SorghumInputs";
        case "soybean":
          return "SoybeanInputs";
        case "sugarcane":
          return "SugarcaneInputs";
        case "other":
          return "OtherInputs";
        default:
          return "CornInputs";
      }
    },
    fields() {
      return this.$store.state.fields?.fields;
    },
    uniqueOrgNames() {
      let orgNames = [];
      this.sharingCarbonAgreements.forEach((agreement) => {
        orgNames.push(agreement.buyer_details.name);
      });
      return [...new Set(orgNames)];
    },
    orgToShareWith() {
      if (this.orgNameToShareWith) {
        return this.sharingCarbonAgreements.find(
          (agreement) =>
            agreement.buyer_details.name === this.orgNameToShareWith,
        ).buyer_details.id;
      } else {
        return null;
      }
    },
  },
  methods: {
    updateScenarioInputs(e) {
      this.scenarioInputs = e;
    },
    // resetScenarioInputs() {
    //   this.$refs.scenarioCropInputComponent.resetScenarioInputs();
    //   console.log("resetting scenario inputs");
    // },
    submitCarbonScenario() {
      if (
        this.scenarioName === null ||
        this.scenarioCrop === null ||
        this.scenarioInputs.farm_size === null ||
        this.scenarioInputs.crop_yield === null
      ) {
        this.toast.error("Core Attribtues Not Complete", {
          timeout: 1500,
          hideProgressBar: true,
        });
        return;
      }
      if (
        this.scenarioCrop === "other" &&
        (this.otherCropName === null || this.otherCropHarvestUnits === null)
      ) {
        this.toast.error("Other Crop Attribtues Not Complete", {
          timeout: 1500,
          hideProgressBar: true,
        });
        return;
      }
      // convert inputs to model units
      if (this.scenarioInputs.manure_transportation_gallons) {
        //from gallons to btu/ton manure/mile
        this.scenarioInputs.manure_transportation_energy =
          gallonsToBTU(
            this.scenarioInputs.manure_transportation_gallons,
            "diesel",
          ) /
          (this.scenarioInputs.beef_cattle +
            this.scenarioInputs.chicken +
            this.scenarioInputs.dairy_cow +
            this.scenarioInputs.swine) /
          this.scenarioInputs.manure_transportation_distance;
        delete this.scenarioInputs.manure_transportation_gallons;
      }
      if (this.scenarioInputs.manure_application_gallons) {
        this.scenarioInputs.manure_application_energy =
          gallonsToBTU(
            this.scenarioInputs.manure_application_gallons,
            "diesel",
          ) / this.scenarioInputs.farm_size;
        delete this.scenarioInputs.manure_application_gallons;
      }
      if (this.scenarioInputs.cover_crop_farming_gallons) {
        this.scenarioInputs.cover_crop_farming_energy =
          gallonsToBTU(
            this.scenarioInputs.cover_crop_farming_gallons,
            "diesel",
          ) / this.scenarioInputs.farm_size;
        delete this.scenarioInputs.cover_crop_farming_gallons;
      }
      if (this.scenarioId) {
        this.updateScenario();
      } else {
        this.createScenario();
      }
    },
    // Form Methods
    readScenario() {
      this.scenarioCrop = this.currentScenario.crop;
      this.scenarioName = this.currentScenario.name;
      this.scenarioInputs = this.currentScenario.inputs;
      this.selectedFields = this.currentScenario.metadata?.fields?.map(
        (field) => this.$store.getters.getFieldById(field),
      );
      this.otherCropName = this.currentScenario.metadata?.other_crop_type;
      this.otherCropHarvestUnits = this.currentScenario.metadata?.harvest_units;
      console.log(this.selectedFields);
    },
    async createScenario() {
      let paramsObject = null;
      if (this.orgToShareWith) {
        let scenarioToShare = {
          ...this.submissionScenario,
          org: this.orgToShareWith,
        };
        await this.$store.dispatch("createSharedScenario", scenarioToShare);
        if (!this.$store.getters.getApiError) {
          this.$router.push({
            name: "vendor-carbon",
          });
        }
      } else {
        let res = await this.$store.dispatch(
          "createScenario",
          this.submissionScenario,
        );
        paramsObject = { scenarioId: res.id };
        if (!this.$store.getters.getApiError && paramsObject) {
          this.$router.push({
            name: this.onSubmitNavigationRouteName,
            params: paramsObject,
          });
        }
      }
    },
    updateScenario() {
      let updatedScenario = {
        id: this.scenarioId,
        ...this.submissionScenario,
      };
      for (const key in updatedScenario.inputs) {
        if (
          typeof updatedScenario.inputs[key] === "string" &&
          updatedScenario.inputs[key] === ""
        ) {
          updatedScenario.inputs[key] = null;
        }
      }
      this.$store.dispatch("updateScenario", updatedScenario);
      if (!this.$store.getters.getApiError) {
        this.toast.success("Scenario Updated!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      } else {
        this.toast.error("Error Updating Scenario", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
    },
    clearForm() {
      this.scenarioName = null;
      this.scenarioCrop = "corn";
      this.scenarioInputs = null;
    },
    applySelectedAcres() {
      if (this.selectedFields.length === 0) {
        this.toast.error("No Fields Selected", {
          timeout: 1500,
          hideProgressBar: true,
        });
        return;
      }
      let acres = 0;
      this.selectedFields.forEach((field) => {
        acres += field.properties.acres;
      });
      this.scenarioInputs.farm_size = acres;
    },

    // displayNoGeomModal() {
    //   this.modalIsActive = true;
    // },
    // dismissModal() {
    //   this.modalIsActive = false;
    // },
  },
  watch: {
    scenarioId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the scenarioId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readScenario();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    // scenarioCrop() {
    //   this.resetScenarioInputs();
    // },
  },
};
</script>
