<template>
  <page-card headingTitle="External Org Access">
    <template #cardDetails>
      <div class="sm:col-span-6">
        <table-card>
          <template #header>
            <table-head
              :columns="[
                { name: 'name', label: 'Org', filtered: false },
                { name: 'actions', label: '', filtered: false },
              ]"
              :collection="orgs"
              @update:derived-collection="derivedOrgs = $event"
              :limit="20"
            >
            </table-head>
          </template>
          <tbody>
            <tr v-for="org in derivedOrgs" :key="org.id">
              <table-data td-label="Org">
                <div class="flex">
                  <Avatar :username="org.name" :src="org.logo_url" />
                  {{ org.name }}
                </div>
              </table-data>
              <table-data class="text-center">
                <base-button
                  v-if="org.id !== currentOrgId"
                  buttonText="Switch"
                  :marginLeft="false"
                  @buttonClick="() => switchOrg(org.id, org.internal)"
                />
                <base-button
                  v-else
                  buttonText="Active"
                  :disabled="true"
                  :marginLeft="false"
                  @buttonClick="() => switchOrg(org.id, org.internal)"
                />
              </table-data>
            </tr>
          </tbody>
        </table-card>
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";

import Avatar from "@/components/identity/UserAvatar.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    TableCard,
    TableHead,
    TableData,
    Avatar,
  },
  props: {},
  data() {
    return {
      derivedOrgs: [],
    };
  },
  methods: {
    async switchOrg(orgId, internal) {
      await this.$store.dispatch(
        "loginSwitchContext",
        internal ? { org: orgId } : { affiliate_org: orgId },
      );
      this.$router.push({ name: "landing-page" });
    },
  },
  computed: {
    orgs() {
      return this.$store.state.affiliateOrgs.affiliateOrgs;
    },
    currentOrgId() {
      return this.$store.getters.getCurrentOrg?.id;
    },
  },
};
</script>
