<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Remove Contract"
    modalDescription="Are you sure you want to permanently remove this contract?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="delistContract"
  />
  <page-card headingTitle="Edit Contract">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="newContractFormId"
      />
      <base-button
        buttonText="Results Report"
        :marginLeft="true"
        @click="exportContract"
        :redButton="false"
      />
      <base-button
        buttonText="Delist"
        :marginLeft="true"
        @click="modalIsActive = true"
        :redButton="true"
      />
      <!-- <base-button
        buttonText="Results Report"
        button-type="page-card"
        heroIcon="DownloadIcon"
        :margin-left="true"
        @action="exportContract"
      /> -->
    </template>
    <template #cardDetails>
      <contract-form :contractId="contractId" :formId="newContractFormId" />
    </template>
    <template #componentSection>
      <!-- Tabs Below Grid -->
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Assignments
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Inquiries
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Orders
              </button>
            </Tab>
            <!-- <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Grower Map
              </button>
            </Tab> -->
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10">
            <buyer-assignments :contractId="contractId" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <controller-inquiries-table :contractId="contractId" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <controller-orders-table :contractId="contractId" />
          </TabPanel>
          <!-- <TabPanel as="dl" class="pt-10">
            <growers-map :contractId="contractId" />
          </TabPanel> -->
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import ContractForm from "@/layouts/buyer-portal/bp-controller/contracts/ContractForm.vue";
// import DropdownButton from "@/components/buttons/DropdownButton.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import { Tab, TabList, TabPanel, TabPanels, TabGroup } from "@headlessui/vue";
// import GrowersMap from "@/layouts/buyer-portal/bp-controller/dashboard/GrowersMap.vue";
import ControllerOrdersTable from "@/pages/buyer-portal/bp-controller/orders/ControllerOrdersTable.vue";
import ControllerInquiriesTable from "@/pages/buyer-portal/bp-controller/orders/ControllerInquiriesTable.vue";
import BuyerAssignments from "@/pages/buyer-portal/bp-controller/assignments/BuyerAssignments.vue";

export default {
  components: {
    PageCard,
    SubmitButton,
    BaseButton,
    // DropdownButton,
    ContractForm,
    DeleteModal,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    TabGroup,
    // GrowersMap,
    ControllerOrdersTable,
    ControllerInquiriesTable,
    BuyerAssignments,
  },
  data() {
    return {
      newContractFormId: "new-contract-form",
      modalIsActive: false,
    };
  },
  computed: {
    contractId() {
      return this.$route.params.contractId;
    },
  },
  methods: {
    delistContract() {
      this.$store.dispatch("deleteContract", { id: this.contractId });
      this.$router.push({ name: "buyer-contracts" });
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    exportContract() {
      // if (e === "print") {
      this.$router.push({
        name: "buyer-bp-controller-contract-report",
        contractId: this.contractId,
      });
      // } else if (e === "csv") {
      //   console.log("Exporting CSV");
      //   // exportToCSV(exportData);
      // }
    },
  },
};
</script>
