<template>
  <page-card headingTitle="New Order">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="newCropOrderFormId"
      />
    </template>
    <template #cardDetails>
      <internal-crop-order-form :formId="newCropOrderFormId" />
      <allocations-aggregate :orderId="parseInt($route.params.orderId)" />
    </template>
    <template #componentSection>
      <!-- Tabs Below Grid -->
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <!-- <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Allocate Fields To Order
              </button>
            </Tab> -->
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <!-- <TabPanel as="dl" class="pt-10">
            <allocations-manager :orderId="parseInt($route.params.orderId)" />
          </TabPanel> -->
          <TabPanel as="dl" class="pt-10">
            <add-attachments-table :attachments="[]" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import InternalCropOrderForm from "@/layouts/grower-portal/sell/orders/InternalCropOrderForm.vue";
import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import AllocationsAggregate from "@/layouts/grower-portal/sell/orders/AllocationAggregate.vue";
// import AllocationsManager from "@/layouts/grower-portal/sell/orders/AllocationsManager.vue";

export default {
  components: {
    PageCard,
    SubmitButton,
    InternalCropOrderForm,
    AddAttachmentsTable,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    // AllocationsManager,
    AllocationsAggregate,
  },
  data() {
    return {
      newCropOrderFormId: "newCropOrder",
      orderId: null,
    };
  },
  methods: {
    // saveAllocations(orderId) {
    //   // console.log("saving allocations on add");
    //   this.$store.dispatch("saveWorkingAllocations", orderId);
    //   this.$router.push({ name: "grower-portal-produce-orders" });
    // },
  },
};
</script>
