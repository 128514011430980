<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
-->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enter-to="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 md:scale-100"
            leave-to="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <DialogPanel
              class="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl"
            >
              <div
                class="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8"
              >
                <button
                  type="button"
                  class="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                  @click="dismiss"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>

                <div
                  class="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8"
                >
                  <div class="sm:col-span-4 lg:col-span-5">
                    <div
                      class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100"
                    >
                      <img
                        :src="product.imageSrc"
                        :alt="product.imageAlt"
                        class="h-auto w-80 object-cover object-center"
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-8 lg:col-span-7">
                    <h2 class="text-2xl font-bold text-gray-900 sm:pr-12">
                      {{ product.name }}
                    </h2>

                    <section aria-labelledby="information-heading" class="mt-3">
                      <h3 id="information-heading" class="sr-only">
                        Product information
                      </h3>

                      <p class="text-2xl text-gray-900">{{ product.price }}</p>

                      <!-- Rating -->
                      <div class="mt-3" v-if="product.rating">
                        <h4 class="sr-only">Reviews</h4>
                        <div class="flex items-center">
                          <div class="flex items-center">
                            <StarIcon
                              v-for="rating in [0, 1, 2, 3, 4]"
                              :key="rating"
                              :class="[
                                product.rating > rating
                                  ? 'text-yellow-400'
                                  : 'text-gray-200',
                                'h-5 w-5 flex-shrink-0',
                              ]"
                              aria-hidden="true"
                            />
                          </div>
                          <p class="sr-only">
                            {{ product.rating }} out of 5 stars
                          </p>
                        </div>
                      </div>

                      <div class="mt-6">
                        <h4 class="sr-only">Description</h4>

                        <p class="text-sm text-gray-700">
                          {{ product.description }}
                        </p>
                      </div>
                    </section>

                    <section aria-labelledby="options-heading" class="mt-6">
                      <h3 id="options-heading" class="sr-only">
                        Product options
                      </h3>

                      <form @submit.prevent="purchaseAction">
                        <!-- Colors -->
                        <!-- <div>
                          <h4 class="text-sm text-gray-600">Color</h4>

                          <RadioGroup v-model="selectedColor" class="mt-2">
                            <RadioGroupLabel class="sr-only">
                              Choose a color
                            </RadioGroupLabel>
                            <div class="flex items-center space-x-3">
                              <RadioGroupOption
                                as="template"
                                v-for="color in product.colors"
                                :key="color.name"
                                :value="color"
                                v-slot="{ active, checked }"
                              >
                                <div
                                  :class="[
                                    color.selectedColor,
                                    active && checked
                                      ? 'ring ring-offset-1'
                                      : '',
                                    !active && checked ? 'ring-2' : '',
                                    'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none',
                                  ]"
                                >
                                  <RadioGroupLabel as="span" class="sr-only">
                                    {{ color.name }}
                                  </RadioGroupLabel>
                                  <span
                                    aria-hidden="true"
                                    :class="[
                                      color.bgColor,
                                      'h-8 w-8 rounded-full border border-black border-opacity-10',
                                    ]"
                                  />
                                </div>
                              </RadioGroupOption>
                            </div>
                          </RadioGroup>
                        </div> -->
                        <div v-if="product.buy_link">
                          <number-input
                            v-model="quantity"
                            :min="1"
                            :max="100"
                            inputTitle="Quantity"
                            :step="1"
                          />
                        </div>
                        <div class="mt-6" v-if="product.buy_link">
                          <button
                            type="submit"
                            class="flex w-full items-center justify-center rounded-md border border-transparent bg-parcelOrange-500 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                          >
                            Purchase
                          </button>
                        </div>

                        <!-- <p
                          class="absolute top-4 left-4 text-center sm:static sm:mt-6"
                        >
                          <a
                            :href="product.href"
                            class="font-medium text-indigo-600 hover:text-indigo-500"
                            >View full details</a
                          >
                        </p> -->
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  //   RadioGroup,
  //   RadioGroupLabel,
  //   RadioGroupOption,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import { StarIcon } from "@heroicons/vue/solid";
import NumberInput from "@/components/form/NumberInput.vue";

export default {
  components: {
    Dialog,
    DialogPanel,
    // RadioGroup,
    // RadioGroupLabel,
    // RadioGroupOption,
    TransitionChild,
    TransitionRoot,
    XIcon,
    StarIcon,
    NumberInput,
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
    open: { type: Boolean, default: false },
  },
  emits: ["clickDismiss"],
  data() {
    return {
      suggestedProduct: this.product,
      quantity: 1,
    };
  },
  methods: {
    dismiss() {
      this.$emit("clickDismiss");
    },
    purchaseAction() {
      if (this.product.buy_link) window.open(this.product.buy_link, "_blank");
    },
  },
};
</script>
