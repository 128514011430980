<template>
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Variety"
    inputHtmlType="text"
    v-model="variety"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Est. Yield (TPA)"
    inputHtmlType="text"
    v-model="tpa"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Rows"
    inputHtmlType="text"
    v-model="rows"
  />
  <div class="mt-4">
    <radio-input
      inputTitle=""
      :wideItem="true"
      v-model="organic"
      :selectOptions="[
        { value: 'organic', label: 'Organic' },
        { value: 'conventional', label: 'Conventional' },
      ]"
    />
  </div>
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Planted"
    inputHtmlType="text"
    v-model="planted"
  />
  <div class="mt-4">
    <radio-input
      inputTitle=""
      :wideItem="true"
      v-model="grafted"
      :selectOptions="[
        { value: 'grafted', label: 'Grafted' },
        { value: 'planted', label: 'Planted' },
      ]"
    />
  </div>
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Rootstock"
    inputHtmlType="text"
    v-model="rootstock"
  />

  <div class="mt-4">
    <radio-input
      inputTitle=""
      :wideItem="true"
      v-model="trellis"
      :selectOptions="[
        { value: 'trellised', label: 'Trellised' },
        { value: 'conventional', label: 'Conventional' },
      ]"
    />
  </div>

  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Number of Trees"
    inputHtmlType="text"
    v-model="stand_count"
  />
  <select-search
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Trellis Type"
    inputHtmlType="text"
    v-model="trellis_type"
    :selectOptions="['None', 'Vertical', 'Angled']"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Row Spacing"
    inputHtmlType="text"
    v-model="rowspacing"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Tree Spacing"
    inputHtmlType="text"
    v-model="plantspacing"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Pole Height"
    inputHtmlType="text"
    v-model="pole_height"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Block Quality"
    inputHtmlType="text"
    v-model="quality"
  />

  <select-search
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Ranch Manager"
    inputHtmlType="text"
    v-model="ranch_manager"
    :selectOptions="usersArray"
  />
  <select-search
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Orchard Manager"
    inputHtmlType="text"
    v-model="orchard_manager"
    :selectOptions="usersArray"
  />
  <text-area
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Notes"
    v-model="notes"
  />
  <div class="mt-6 flex flex-row">
    <checkbox-input
      inputTitle="Section / Subblock"
      :wideItem="true"
      v-model="isSection"
    />
  </div>
</template>

<script>
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import RadioInput from "@/components/form/RadioInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import TextArea from "@/components/form/TextArea.vue";

export default {
  components: {
    VariableStringTypeInput,
    RadioInput,
    CheckboxInput,
    SelectSearch,
    TextArea,
  },
  emits: ["updateFieldDetails"],
  props: {
    crop: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    let details = this.crop?.details;
    let parent = null;

    if (!details || details == null) {
      details = {};
    }
    // console.log("details", details, field, listing, this.$route.params);
    return {
      tpa: details.tpa,
      rows: details.rows,
      variety: details.variety,
      planted: details.planted,
      rootstock: details.rootstock,
      rowspacing: details.rowspacing,
      plantspacing: details.plantspacing,
      trellis: details.trellis,
      quality: details.quality,
      ranch_manager: details.ranch_manager,
      orchard_manager: details.orchard_manager,
      organic: details.organic,
      isSection: details.isSection,
      grafted: details.grafted,
      notes: details.notes,
      stand_count: details.stand_count,
      trellis_type: details.trellis_type,
      pole_height: details.pole_height,
      parent,
    };
  },
  methods: {},
  computed: {
    detailsToReturn() {
      return {
        tpa: this.tpa,
        rows: this.rows,
        variety: this.variety,
        planted: this.planted,
        rootstock: this.rootstock,
        rowspacing: this.rowspacing,
        plantspacing: this.plantspacing,
        trellis: this.trellis,
        quality: this.quality,
        ranch_manager: this.ranch_manager,
        orchard_manager: this.orchard_manager,
        organic: this.organic,
        isSection: this.isSection,
        grafted: this.grafted,
        notes: this.notes,
        stand_count: this.stand_count,
        trellis_type: this.trellis_type,
        pole_height: this.pole_height,
      };
    },
    usersArray() {
      let users = this.$store.state.users.users;
      let usersArray = users.map((user) => {
        return {
          value: user.id,
          label: user.name,
        };
      });
      return usersArray;
    },
  },
  watch: {
    detailsToReturn(value) {
      return this.$emit("updateFieldDetails", value);
    },
  },
};
</script>
