<template>
  <form
    @submit.prevent="submitContractForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Contract Details
    </div>
    <text-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Contract Name"
      inputHtmlType="text"
      v-model="currentContract.contract_name"
    />
    <text-area
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Contract Description"
      inputHtmlType="text"
      v-model="currentContract.contract_description"
    />
    <text-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Program Name"
      inputHtmlType="text"
      v-model="currentContract.program_name"
    />
    <text-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Program Year"
      inputHtmlType="text"
      v-model="currentContract.program_year"
    />
    <date-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Payment Date"
      inputHtmlType="text"
      v-model="currentContract.payment_date"
    />
    <currency-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Contract Partner Payment"
      inputHtmlType="text"
      v-model="currentContract.contract_partner_payment"
    />
    <text-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Contract Partner Payment Units"
      inputHtmlType="text"
      v-model="currentContract.contract_partner_payment_units"
    />
    <currency-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Contract Total Value"
      inputHtmlType="text"
      v-model="currentContract.contract_total_value"
    />
    <select-dropdown
      v-if="currentContract"
      :wide-item="true"
      input-title="Status"
      v-model="currentContract.status"
      :select-options="[
        { label: 'Active', value: 'active' },
        { label: 'Complete', value: 'complete' },
        { label: 'Canceled', value: 'canceled' },
      ]"
    />
    <div></div>
    <checkbox-input
      v-if="currentContract"
      :wide-item="true"
      input-title="Invoice Sent"
      v-model="currentContract.invoice_sent"
    />
    <checkbox-input
      v-if="currentContract"
      :wide-item="true"
      input-title="Invoice Paid"
      v-model="currentContract.invoice_paid"
    />
    <checkbox-input
      v-if="currentContract"
      :wide-item="true"
      input-title="Growers Paid"
      v-model="currentContract.growers_paid"
    />
    <checkbox-input
      v-if="currentContract"
      :wide-item="true"
      input-title="Affiliates Paid"
      v-model="currentContract.affiliates_paid"
    />
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Crop
    </div>
    <select-search
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Crop"
      inputHtmlType="text"
      v-model="currentContract.crop"
      :selectOptions="cropOptions"
      class="ml-1 resize-none whitespace-pre-wrap sm:col-span-3"
      classProp="resize-none"
    />
    <select-search
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Quantity Units"
      inputHtmlType="text"
      v-model="currentContract.quantity_units"
      :select-options="unitOptions"
    />
    <number-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Quantity"
      inputHtmlType="text"
      v-model="currentContract.quantity"
    />
    <text-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Deliver To"
      inputHtmlType="text"
      v-model="currentContract.elevator"
    />
    <date-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Deliver By Date"
      inputHtmlType="text"
      v-model="currentContract.deliver_by"
    />
    <text-area
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Delivery Location(s)"
      inputHtmlType="text"
      v-model="currentContract.delivery_location"
    />
    <!-- <text-area
      :wideItem="true"
      inputTitle="Contract Description"
      inputHtmlType="text"
      v-model="currentContract.contract_description"
      class-prop="h-20"
    /> -->
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Premiums
    </div>
    <!-- <number-input
      :wideItem="true"
      inputTitle="Number of Premiums"
      :step="1"
      inputHtmlType="text"
      v-model="numberOfPremiums"
      @update:model-value="resizePremiumArray"
    />
    <div
      v-for="i in numberOfPremiums"
      :key="i"
      class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
    >
      <p class="text-gray-500 sm:col-span-6">Premium {{ i }}</p>
      <contract-premium-form
        :initial-premium="initialPremiums[i - 1]"
        @premiumUpdated="setSubmissionPremium($event, i - 1)"
      />
    </div> -->
    <div
      class="mt-2 text-left text-sm font-medium tracking-wider text-gray-500 sm:col-span-6"
    >
      <contract-premiums-interface
        v-if="currentContract"
        :premiums="currentContract.premiums"
        :unit-options="premiumUnitOptions"
        @updatePremiums="localPremiums = $event"
      />
    </div>
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Client Information (Internal Use Only, Not shared with growers)
    </div>
    <text-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Client Name"
      inputHtmlType="text"
      v-model="currentContract.client_name"
    />
    <email-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Client Email"
      inputHtmlType="text"
      v-model="currentContract.client_email"
    />
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Contract Sourcing Area
    </div>
    <div class="col-span-6">
      <map-editor
        v-if="currentContract"
        featureLabel="Territory"
        :initialFeature="{
          type: 'Feature',
          geometry: currentContract.territory,
          properties: {},
        }"
        @edited="updateShape"
      />
    </div>
  </form>
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import EmailInput from "@/components/form/EmailInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";
// import RadioInput from "@/components/form/RadioInput.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
import ContractPremiumsInterface from "@/layouts/buyer-portal/bp-controller/contracts/ContractPremiumsInterface.vue";
import DateInput from "@/components/form/DateInput.vue";
import { harvestUnits } from "@/components/composables/convertUnits";
import MapEditor from "@/components/map/MapEditor.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";

let cropList = require("@/assets/croplist.js");

export default {
  props: {
    // contractId: {
    //   type: [Number, String],
    //   default: null,
    // },
    formId: {
      type: String,
      required: true,
    },
  },
  components: {
    TextInput,
    CurrencyInput,
    // RadioInput,
    TextArea,
    NumberInput,
    EmailInput,
    SelectSearch,
    SelectDropdown,
    CheckboxInput,
    // CheckboxInput,
    ContractPremiumsInterface,
    // SelectDropdown,
    DateInput,
    MapEditor,
  },
  data() {
    return {
      currentContract: null,
      // temp until build multiple premium support
      listOfCropOptionObjects: cropList,
    };
  },
  computed: {
    contractId() {
      return this.$route.params.contractId;
    },
    cropOptions() {
      let options = ["Any Crop"];
      for (let crop of this.listOfCropOptionObjects) {
        options.push(crop.name);
      }
      return options;
    },
    unitOptions() {
      let unitOptions = [{ label: "Acres", value: "acres" }];
      let cropUnit = harvestUnits(this.currentContract.crop);
      if (cropUnit) {
        unitOptions.push(cropUnit);
      }
      return unitOptions;
    },
    premiumUnitOptions() {
      let unitOptions = [];
      // console.log(
      //   "currentContract",
      //   this.currentContract,
      //   harvestUnits(this.currentContract.crop),
      // );
      if (
        this.currentContract.quantity_units ==
        harvestUnits(this.currentContract.crop)?.value
      ) {
        // console.log(
        //   "harvestUnits available premium choice",
        //   harvestUnits(this.currentContract.crop),
        // );
        unitOptions.push(harvestUnits(this.currentContract.crop));
      } else if (this.currentContract.quantity_units == "acres") {
        unitOptions.push({ label: "Acres", value: "acres" });
      }
      if (unitOptions.length == 0 || unitOptions[0]?.value != "acres")
        unitOptions.push({ label: "Acres", value: "acres" });
      return unitOptions;
    },
    readyToReadContract() {
      if (
        this.contractId &&
        this.$store.getters.getContractById(this.contractId)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    submitContractForm() {
      if (this.contractId) {
        this.updateContract();
      } else {
        this.createContract();
      }
    },
    readContract() {
      if (!this.readyToReadContract) {
        return null;
      }
      let currentContract = this.$store.getters.getContractById(
        this.contractId,
      );
      this.currentContract = currentContract;
    },
    async createContract() {
      await this.$store.dispatch("createContract", this.currentContract);
      this.$router.push({ name: "buyer-contracts" });
    },
    async updateContract() {
      await this.$store.dispatch("updateContract", this.currentContract);

      this.$router.push({ name: "buyer-contracts" });
    },
    clearForm() {
      this.currentContract = {
        crop: null, // string,
        quantity: 0,
        quantity_units: null, // string,
        contract_name: null, // string,
        contract_description: null, // string,
        program_name: null, // string,
        program_year: null, // string,
        deliver_by: null, // string DATE
        delivery_location: null, // string,
        elevator: null, // string,
        payment_date: null, // string DATE
        contract_partner_payment: null, // string,
        contract_partner_payment_units: null, // string,
        contract_total_value: null, // number
        territory: {
          type: "MultiPolygon",
          coordinates: [],
        },
        premiums: [
          // {
          //   score_target: 0,
          //   score_units: null, // string,
          //   base_premium: 0,
          //   premium_per_point: 0,
          //   maximum_points: 0,
          // },
        ], // array of objects
        client_name: null, // string,
        client_email: null, // string,
        // contract_description: null, // string,
        // expected_harvest_date: null,
      };
    },
    updateShape(feature) {
      // console.log(feature);
      this.currentContract.territory = feature.geometry;
    },
  },
  watch: {
    contractId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readContract();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    readyToReadContract: {
      handler(value) {
        if (value && this.contractId) {
          this.readContract();
        }
      },
      immediate: true,
    },
  },
};
</script>
