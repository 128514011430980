import Papa from "papaparse";
export function exportToCSV(data, filename) {
  if (!filename) filename = "export.csv";
  let options = {
    quotes: false, //or array of booleans
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ",",
    header: true,
    newline: "\r\n",
    skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
    columns: null, //or array of strings
  };
  const csv = Papa.unparse(data, options);
  console.log(csv);
  const blob = new Blob([csv]);
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob, { type: "text/plain" });
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
export function exportToGeoJSON(data, filename) {
  if (!filename) filename = "export.json";
  let geoJSON = {
    type: "FeatureCollection",
    features: [],
  };
  data.forEach((item) => {
    let properties = {};
    Object.keys(item.properties).forEach((key) => {
      if (key !== "details" && key !== "current_crop") {
        properties[key] = item.properties[key];
      }
    });
    Object.keys(item.properties?.current_crop).forEach((key) => {
      if (key !== "details") {
        properties[key] = item.properties.current_crop[key];
      }
    });
    Object.keys(item.properties?.current_crop?.details).forEach((key) => {
      properties[key] = item.properties.current_crop.details[key];
    });
    geoJSON.features.push({
      type: "Feature",
      geometry: item.geometry,
      properties: properties,
    });
  });
  console.log(geoJSON);
  const blob = new Blob([JSON.stringify(geoJSON)]);
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob, { type: "text/plain" });
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
