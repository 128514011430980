<template>
  <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            User
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }" v-if="userIsAdmin">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Organization
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }" v-if="userIsAdmin">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Navigation
          </button>
        </Tab>
        <feature-flagged
          :requiresOne="['kn-integration', 'trimble-integration']"
        >
          <Tab as="template" v-slot="{ selected }" v-if="userIsAdmin">
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Integrations
            </button>
          </Tab>
        </feature-flagged>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <user-settings-form
          :isUserProfile="true"
          :notificationEvents="[
            { event: 'task_assigned', label: 'Task Assigned' },
            { event: 'chat_received', label: 'Chat Received' },
            // { event: 'quote_request_received', label: 'Quote Request Received' },
            {
              event: 'quote_response_received',
              label: 'Quote Response Received',
            },
            // { event: 'order_request_received', label: 'Order Received' },
            {
              event: 'order_response_received',
              label: 'Order Response Received',
            },
            { event: 'order_canceled', label: 'Order Canceled' },
            {
              event: 'opportunity_received',
              label: 'Product Recommendation Received',
            },
            {
              event: 'recommendation_received',
              label: 'Activity Recommendation Received',
            },
            {
              event: 'scenario_created',
              label: 'Carbon Scenario Score Created',
              flag: 'gp-carbon',
            },
            { event: 'crop_inquiry_submitted', label: 'Crop Inquiry Received' },
            { event: 'crop_order_created', label: 'Crop Order Created' },
            {
              event: 'crop_order_premium_created',
              label: 'Premium Added to Crop Order',
            },
          ]"
        />
      </TabPanel>
      <TabPanel class="pt-10" v-if="userIsAdmin">
        <org-settings-form
          routeUserNew="settings-new-user"
          routeExternalOrgNew="settings-new-external-org"
          routeUserEdit="single-user"
        />
        <div class="mt-4"></div>
        <crop-supply-search-participation />
      </TabPanel>
      <TabPanel class="pt-10" v-if="userIsAdmin">
        <navigation-settings-form />
      </TabPanel>
      <TabPanel class="pt-10" v-if="userIsAdmin">
        <feature-flagged requires="kn-integration">
          <kubota-integration-form />
        </feature-flagged>
        <p><br /></p>
        <feature-flagged requires="trimble-integration">
          <page-card headingTitle="Trimble Integration">
            <template #buttonArea> </template>
            <template #cardDetails>
              <base-button
                :buttonText="
                  trimbleSetup
                    ? 'Manage Integration with Trimble'
                    : 'Setup Integration with Trimble'
                "
                @buttonClick="setupTrimbleIntegration"
                :disabled="fetchingTrimbleLink"
              />
            </template>
          </page-card>
        </feature-flagged>
        <feature-flagged requires="johndeere-integration">
          <page-card headingTitle="John Deere Integration">
            <template #buttonArea> </template>
            <template #cardDetails>
              <base-button
                :buttonText="
                  jdSetup
                    ? 'Manage Integration with John Deere'
                    : 'Setup Integration with John Deere'
                "
                @buttonClick="setupJDIntegration"
                :disabled="fetchingJDLink"
              />
            </template>
          </page-card>
        </feature-flagged>
        <!-- <feature-flagged requires="g3-integration">
          <page-card headingTitle="G3 Integration">
            <template #buttonArea> </template>
            <template #cardDetails>
              <base-button
                :buttonText="
                  g3Setup
                    ? 'Manage Integration with G3'
                    : 'Setup Integration with G3'
                "
                @buttonClick="setupG3Integration"
                :disabled="fetchingG3Link"
              />
            </template>
          </page-card>
        </feature-flagged> -->
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import UserSettingsForm from "@/layouts/common/UserSettingsForm.vue";
import OrgSettingsForm from "@/layouts/common/OrgSettingsForm.vue";
import NavigationSettingsForm from "@/layouts/grower-portal/settings/GrowerNavigationSettingsForm.vue";
import KubotaIntegrationForm from "@/layouts/grower-portal/settings/KubotaIntegrationForm.vue";
import CropSupplySearchParticipation from "@/layouts/grower-portal/settings/CropSupplySearchParticipation.vue";
import FeatureFlagged from "@/components/containers/FeatureFlagged.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PageCard from "@/components/cards/PageCard.vue";
import client from "@/api/parcel/api.js";

export default {
  components: {
    UserSettingsForm,
    OrgSettingsForm,
    NavigationSettingsForm,
    KubotaIntegrationForm,
    CropSupplySearchParticipation,
    FeatureFlagged,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    BaseButton,
  },
  data() {
    const trimbleSetup =
      this.$store.getters.getIntegrationByType("trimble") !== undefined;
    const jdSetup =
      this.$store.getters.getIntegrationByType("johndeere") !== undefined;
    // const g3Setup =
    //   this.$store.getters.getIntegrationByType("g3") !== undefined;
    const tabs = ["user", "organization", "navigation", "integrations"];
    let selectedTab = 0;
    if (this.$route.params.tab) {
      selectedTab = tabs.indexOf(this.$route.params.tab);
    }
    return {
      selectedTab: selectedTab,
      tabs,
      fetchingTrimbleLink: false,
      trimbleSetup,
      fetchingJDLink: false,
      jdSetup,
      // fetchingG3Link: false,
      // g3Setup,
    };
  },
  computed: {
    userIsAdmin() {
      return this.$store.getters.getUserAdminStatus;
    },
  },
  methods: {
    async setupTrimbleIntegration() {
      this.fetchingTrimbleLink = true;
      const { data } = await client.integrations.trimbleSetup();
      window.location = data.link;
    },
    async setupJDIntegration() {
      this.fetchingJDLink = true;
      const { data } = await client.integrations.johndeereSetup();
      window.location = data.link;
    },
    // async setupG3Integration() {
    //   this.fetchingG3Link = true;
    //   const { data } = await client.integrations.g3Setup();
    //   window.location = data.link;
    // },
    changeTab(index) {
      this.selectedTab = index;
      this.$router.push({
        name: "grower-portal-settings-tab",
        params: { tab: this.tabs[index] },
      });
    },
  },
};
</script>
