<!-- Combobox provides a freeform text area to fill data, or allow the user to pick from a set of options. -->
<!-- the options do not need to be de-duplicated beforehand, the component will only show unique values -->
<!-- expects two props: label and values. selectOptions is an array of options. (options is the deduped set of values)

        Example: <ComboBox label="Address" :selectOptions="values" v-model="input value"/>

        ...

        values : [
        "123 Main Street, Anywhere CA 44856",
        "234 Main Street, Anywhere CA 20432",
        "567 Main Street, Anywhere CA 95443",
        "234 Main Street, Anywhere CA 20432",
        ]
-->

<template>
  <section :class="{ 'sm:col-span-3': wideItem }">
    <label class="block text-sm font-medium text-gray-700">
      {{ inputTitle }}
      <span v-if="required" class="text-red-600">*</span>
      <span v-if="helpText">
        <ToolTip :helpText="helpText" :helpTitle="helpTitle" />
      </span>
      <div class="mt-1 flex h-full w-full">
        <textarea
          v-model="inputValue"
          :required="required"
          :placeholder="placeholder"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-twilight-500 focus:ring-twilight-500 sm:text-sm"
          :class="conditionalInputStyling"
          :disabled="displayOnly"
          @blur="this.$emit('onBlur', inputValue)"
          :rows="controlHeight"
        ></textarea>

        <Menu as="div" class="relative inline-block text-left">
          <MenuButton
            class="-mx-8 my-2 flex items-center text-gray-500"
            :disabled="displayOnly"
          >
            <span class="sr-only">Open options</span>
            <ChevronDownIcon class="h-6 w-6" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 h-48 origin-top-right overflow-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              :class="{ 'w-80': wideItem, 'w-56': !wideItem }"
              style="overflow-anchor: none"
            >
              <MenuItem v-for="option in uniqueSelectOptions" :key="option">
                <a
                  href="#"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-twilight-600"
                  @click="selectedOption(option)"
                  >{{ option }}</a
                >
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </label>
  </section>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import ToolTip from "@/components/modals/ToolTip.vue";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ToolTip,
  },
  props: {
    inputTitle: String,
    selectOptions: {
      type: Array,
      default: () => [],
    },
    modelValue: String,
    displayOnly: Boolean,
    displayOnlyValue: [String, Number],
    expandToContent: { type: Boolean, default: true },
    classProp: String,
    required: Boolean,
    wideItem: Boolean,
    placeholder: {
      type: String,
      default: "",
    },
    helpText: String,
    helpTitle: String,
  },
  emits: ["update:modelValue", "onBlur"],
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  computed: {
    uniqueSelectOptions() {
      return [...new Set(this.selectOptions)]; // Use Set for unique values
    },
    conditionalInputStyling() {
      let style = "";
      if (this.displayOnly) style = "bg-gray-200 ";
      if (this.classProp) style += this.classProp;
      // calculate enough height for textarea to fit content
      var lines = " "; //default if no content yet
      if (this.inputValue) lines = this.inputValue.split("\n");
      var count = lines.length;
      if (this.expandToContent) style += " h-[" + (count * 1.25 + 1) + "rem]";
      //console.log("lines", count, style);
      return style;
    },
    controlHeight() {
      let height = 1;
      if (this.inputValue && this.expandToContent) {
        height = this.inputValue.split("\n").length;
      }
      return height;
    },
  },
  watch: {
    modelValue(value) {
      this.inputValue = value;
    },
    displayOnlyValue(value) {
      if (this.displayOnly) {
        this.inputValue = value;
      }
    },
  },
  methods: {
    selectedOption(option) {
      this.inputValue = option;
      this.$emit("update:modelValue", option);
    },
  },
  mounted() {
    // Component no longer needs a mounted lifecycle hook just for deduplication
  },
};
</script>
