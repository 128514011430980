<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'farm', label: 'Farm' },
          { name: 'field', label: 'Field', filtered: false },
          {
            name: 'crop_type',
            label: 'Crop',
          },
          { name: 'details.variety', label: 'Variety' },
          {
            name: 'acres',
            label: 'Total Acres',
            filtered: false,
          },
          {
            name: 'acresAllocated',
            label: 'Acres Allocated to Current Order',
            filtered: false,
          },
          {
            name: 'remainingAcres',
            label: 'Available Acres',
            filtered: false,
          },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="crops"
        @update:derived-collection="derivedCrops = $event"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="crop in derivedCrops" :key="crop.id">
        <table-data td-label="Farm">
          {{ crop.farm }}
        </table-data>
        <table-data td-label="Field">
          <a
            :href="`/app/fields/${crop.fieldId}?order=${orderId}`"
            class="flex text-violet-500 hover:text-twilight-900 hover:underline sm:pr-6 print:hidden"
          >
            {{ crop.field }}
            <!--<external-link-icon class="ml-2 h-3 w-3" />-->
          </a>
        </table-data>
        <table-data td-label="Crop">
          {{ crop.crop_type }}
        </table-data>
        <table-data td-label="Variety">
          {{ crop.details?.variety }}
        </table-data>
        <table-data td-label="Total Acres">
          {{ crop.acres }}
        </table-data>
        <table-data td-label="Acres Allocated to Current Order">
          <input
            v-if="crop.currentOrderAllocationId"
            :step="0.01"
            :value="crop.currentOrderAcresAllocated"
            :disabled="crop.historical"
            @blur="
              updateAllocationAcres(
                $event.target.value,
                crop.currentOrderAllocationId,
              )
            "
            class="block h-8 w-full rounded-md border border-gray-300 px-2 shadow-sm focus:border-twilight-500 focus:ring-twilight-500 sm:text-sm"
            :class="crop.historical ? 'bg-gray-200 ' : 'bg-white'"
          />
          <span v-else> {{ crop.currentOrderAcresAllocated.toFixed(1) }} </span>
        </table-data>
        <table-data td-label="Available Acres">
          {{ crop.remainingAcres.toFixed(1) }}
        </table-data>
        <!-- <table-data td-label="Est Allocated Qty">
          {{ crop.estimatedAllocatedQty }}
        </table-data> -->
        <table-data td-label="Control Allocation to Order">
          <button
            v-if="crop.currentOrderAllocationId"
            @click="removeAllocation(crop)"
            class="ml-1 cursor-pointer rounded-lg border border-gray-300 bg-red-300 p-1 text-left shadow-sm hover:bg-gray-100 focus:outline-none focus-visible:border-twilight-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          >
            <OutlineHeroIcon
              name="XIcon"
              classProp="w-5 h-5 text-gray-700"
              aria-hidden="true"
            />
          </button>
          <button
            v-else
            @click="addAllocation(crop)"
            class="ml-1 cursor-pointer rounded-lg border border-gray-300 bg-green-300 p-1 text-left shadow-sm hover:bg-gray-100 focus:outline-none focus-visible:border-twilight-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          >
            <OutlineHeroIcon
              name="PlusIcon"
              classProp="w-5 h-5 text-gray-700"
              aria-hidden="true"
            />
          </button>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>
<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
// import { ExternalLinkIcon } from "@heroicons/vue/outline";
import { useToast } from "vue-toastification";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    OutlineHeroIcon,
    // ExternalLinkIcon,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      derivedCrops: [],
      toast: useToast(),
    };
  },
  computed: {
    allocations() {
      return this.$store.getters.getGrowerAllocationsByOrderId(this.orderId);
    },
    crops() {
      let crops = structuredClone(this.$store.getters.getExpandedCrops({}));
      crops.forEach((crop) => {
        let allocation = this.allocations.find(
          (allocation) => allocation.crop === crop.id,
        );
        if (allocation) {
          crop.currentOrderAllocationId = allocation.id;
          crop.currentOrderAcresAllocated = allocation.acres;
        } else {
          crop.currentOrderAllocationId = null;
          crop.currentOrderAcresAllocated = 0;
        }
      });
      let cropHistory = this.$store.getters.getExpandedCropHistory({});
      for (let crop of cropHistory) {
        let allocation = this.allocations.find(
          (allocation) => allocation.crop === crop.id,
        );
        if (allocation) {
          crop.currentOrderAllocationId = allocation.id;
          crop.currentOrderAcresAllocated = allocation.acres;
          crop.historical = true;
          crops.push(crop);
        }
      }
      let orderedCrops = crops.sort(function (x, y) {
        // sort by 'isAllocated' field first
        if (x.currentOrderAllocationId != y.currentOrderAllocationId) {
          return x.currentOrderAllocationId < y.currentOrderAllocationId
            ? 1
            : -1;
        }
      });
      return orderedCrops;
    },
  },
  methods: {
    async addAllocation(crop) {
      let allocationToSubmit = {
        crop_order: this.orderId,
        crop: crop.id,
        acres: crop.remainingAcres,
      };
      await this.$store.dispatch("createGrowerAllocation", allocationToSubmit);
      if (!this.$store.getters.getApiError) {
        this.toast.success("Allocation Saved to Order!", {
          timeout: 750,
          hideProgressBar: true,
        });
      }
    },
    async updateAllocationAcres(inputValue, allocationId) {
      // console.log("updateAllocationAcres", inputValue, allocationId);
      await this.$store.dispatch("updateGrowerAllocation", {
        id: allocationId,
        acres: inputValue,
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Allocation Updated on Order!", {
          timeout: 750,
          hideProgressBar: true,
        });
      }
    },
    removeAllocation(crop) {
      this.$store.dispatch("deleteGrowerAllocation", {
        id: crop.currentOrderAllocationId,
      });
      this.toast.success("Allocation Removed from Order!", {
        timeout: 750,
        hideProgressBar: true,
      });
    },
  },
};
</script>
