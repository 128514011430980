<template>
  <modal :show="showImageModal" title="Please select the image url to display">
    <text-input
      inputTitle="Image URL"
      :wideItem="false"
      :required="true"
      v-model="imageURL"
    />
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        @click="insertImage"
        class="inline-flex w-full justify-center rounded-md border border-transparent bg-twilight-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
      >
        OK
      </button>
      <button
        @click="showImageModal = false"
        class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-twilight-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
      >
        Cancel
      </button>
    </div>
  </modal>
  <div :class="wideItem == true ? 'sm:col-span-6 ' : 'sm:col-span-3'">
    <label class="block text-sm font-medium text-gray-700" v-if="inputTitle">
      {{ inputTitle }}
      <span v-if="required" class="text-red-600">*</span>
      <span v-if="helpText"
        ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
      /></span>
    </label>
    <div v-if="!editHTML">
      <!-- show WYSIWYG editor -->
      <div v-if="editor">
        <!-- editor toolbar-->
        <button
          title="Edit Raw HTML"
          @click="editHTML = true"
          class="rounded border border-transparent bg-gray-200 px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <svg height="24" width="24">
            <use :href="GridSVG + '#gridicons-code'" />
          </svg>
        </button>
        <button
          title="Bold"
          @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{
            'bg-gray-200 text-white': !editor.isActive('bold'),
            'bg-twilight-700': editor.isActive('bold'),
          }"
          class="ml-2 rounded border border-transparent px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <svg height="24" width="24">
            <use :href="GridSVG + '#gridicons-bold'" />
          </svg>
        </button>
        <button
          title="Italicize"
          @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{
            'bg-gray-200': !editor.isActive('italic'),
            'bg-twilight-700': editor.isActive('italic'),
          }"
          class="ml-2 rounded border border-transparent px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <svg height="24" width="24">
            <use :href="GridSVG + '#gridicons-italic'" />
          </svg>
        </button>
        <button
          title="Underline"
          @click="editor.chain().focus().toggleUnderline().run()"
          :disabled="!editor.can().chain().focus().toggleUnderline().run()"
          :class="{
            'bg-gray-200': !editor.isActive('underline'),
            'bg-twilight-700': editor.isActive('underline'),
          }"
          class="ml-2 rounded border border-transparent px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <svg height="24" width="24">
            <use :href="GridSVG + '#gridicons-underline'" />
          </svg>
        </button>

        <button
          title="Bullet List"
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{
            'bg-gray-200': !editor.isActive('bulletList'),
            'bg-twilight-700': editor.isActive('bulletList'),
          }"
          class="ml-2 rounded border border-transparent px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <svg height="24" width="24">
            <use :href="GridSVG + '#gridicons-list-unordered'" />
          </svg>
        </button>
        <button
          title="Ordered List"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{
            'bg-gray-200': !editor.isActive('orderedList'),
            'bg-twilight-700': editor.isActive('orderedList'),
          }"
          class="ml-2 rounded border border-transparent px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <svg height="24" width="24">
            <use :href="GridSVG + '#gridicons-list-ordered'" />
          </svg>
        </button>
        <button
          title="Add Image"
          @click="addImage"
          :class="{
            'bg-gray-200': !showImageModal,
            'bg-twilight-700': showImageModal,
          }"
          class="ml-2 rounded border border-transparent px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <svg height="24" width="24">
            <use :href="GridSVG + '#gridicons-add-image'" />
          </svg>
        </button>
        <!-- Heading menu -->
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton
              class="ml-2 inline-flex w-full justify-center rounded px-2.5 py-1.5 text-sm text-gray-900 shadow-sm"
              :class="{
                'bg-gray-200': !editor.isActive('heading'),
                'bg-twilight-700': editor.isActive('heading'),
              }"
            >
              <svg height="24" width="24">
                <use :href="GridSVG + '#gridicons-heading'" />
              </svg>
              <ChevronDownIcon
                class="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-2 w-12 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem>
                  <button
                    title="Heading 1"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 1 }).run()
                    "
                    :disabled="
                      !editor
                        .can()
                        .chain()
                        .focus()
                        .toggleHeading({ level: 1 })
                        .run()
                    "
                    :class="{
                      'bg-gray-200': !editor.isActive('heading', { level: 1 }),
                      'bg-twilight-700': editor.isActive('heading', {
                        level: 1,
                      }),
                    }"
                    class="mx-1 rounded border border-transparent px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <svg height="24" width="24">
                      <use :href="GridSVG + '#gridicons-heading-h1'" />
                    </svg>
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    title="Heading 2"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 2 }).run()
                    "
                    :disabled="
                      !editor
                        .can()
                        .chain()
                        .focus()
                        .toggleHeading({ level: 2 })
                        .run()
                    "
                    :class="{
                      'bg-gray-200': !editor.isActive('heading', { level: 2 }),
                      'bg-twilight-700': editor.isActive('heading', {
                        level: 2,
                      }),
                    }"
                    class="mx-1 mt-1 rounded border border-transparent px-2 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <svg height="24" width="24">
                      <use :href="GridSVG + '#gridicons-heading-h2'" />
                    </svg>
                  </button>
                </MenuItem>

                <MenuItem>
                  <button
                    title="Heading 3"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 3 }).run()
                    "
                    :disabled="
                      !editor
                        .can()
                        .chain()
                        .focus()
                        .toggleHeading({ level: 3 })
                        .run()
                    "
                    :class="{
                      'bg-gray-200': !editor.isActive('heading', { level: 3 }),
                      'bg-twilight-700': editor.isActive('heading', {
                        level: 3,
                      }),
                    }"
                    class="mx-1 mt-1 rounded border border-transparent px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <svg height="24" width="24">
                      <use :href="GridSVG + '#gridicons-heading-h3'" />
                    </svg>
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <!-- Alignment menu-->
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton
              class="ml-4 inline-flex w-full justify-center rounded bg-gray-200 px-2.5 py-1.5 text-sm text-gray-900 shadow-sm"
            >
              <svg height="24" width="24">
                <use :href="GridSVG + '#gridicons-align-left'" />
              </svg>
              <ChevronDownIcon
                class="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-2 w-12 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem>
                  <button
                    title="Left Align"
                    @click="editor.chain().focus().setTextAlign('left').run()"
                    class="mx-1 rounded border border-transparent bg-gray-200 px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <svg height="24" width="24">
                      <use :href="GridSVG + '#gridicons-align-left'" />
                    </svg>
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    title="Center Align"
                    @click="editor.chain().focus().setTextAlign('center').run()"
                    class="mx-1 rounded border border-transparent bg-gray-200 px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <svg height="24" width="24">
                      <use :href="GridSVG + '#gridicons-align-center'" />
                    </svg>
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    title="Right Align"
                    @click="editor.chain().focus().setTextAlign('right').run()"
                    class="mx-1 rounded border border-transparent bg-gray-200 px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <svg height="24" width="24">
                      <use :href="GridSVG + '#gridicons-align-right'" />
                    </svg>
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    title="Justify Alignment"
                    @click="
                      editor.chain().focus().setTextAlign('justify').run()
                    "
                    class="mx-1 rounded border border-transparent bg-gray-200 px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <svg height="24" width="24">
                      <use :href="GridSVG + '#gridicons-align-justify'" />
                    </svg>
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    title="Turn Off Alignment"
                    @click="editor.chain().focus().unsetTextAlign.run()"
                    class="mx-1 rounded border border-transparent bg-gray-200 px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <svg height="24" width="24">
                      <use :href="GridSVG + '#gridicons-cross'" />
                    </svg>
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <!-- <button
        @click="editor.chain().focus().toggleStrike().run()"
        :disabled="!editor.can().chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
      >
        strike
      </button>
      <button
        @click="editor.chain().focus().toggleCode().run()"
        :disabled="!editor.can().chain().focus().toggleCode().run()"
        :class="{ 'is-active': editor.isActive('code') }"
      >
        code
      </button>
      <button @click="editor.chain().focus().unsetAllMarks().run()">
        clear marks
      </button>
      <button @click="editor.chain().focus().clearNodes().run()">
        clear nodes
      </button>
      <button
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
      >
        paragraph
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        h1
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        h2
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      >
        h3
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
      >
        h4
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
      >
        h5
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
      >
        h6
      </button>
      <button
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        bullet list
      </button>
      <button
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
      >
        ordered list
      </button>
      <button
        @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
      >
        code block
      </button>
      <button
        @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ 'is-active': editor.isActive('blockquote') }"
      >
        blockquote
      </button>
      <button @click="editor.chain().focus().setHorizontalRule().run()">
        horizontal rule
      </button>
      <button @click="editor.chain().focus().setHardBreak().run()">
        hard break
      </button>
      <button
        @click="editor.chain().focus().undo().run()"
        :disabled="!editor.can().chain().focus().undo().run()"
      >
        undo
      </button>
      <button
        @click="editor.chain().focus().redo().run()"
        :disabled="!editor.can().chain().focus().redo().run()"
      >
        redo
      </button> -->
      </div>
      <div
        class="relative mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-twilight-500 focus:ring-twilight-500 sm:text-sm"
      >
        <editor-content :editor="editor" />
        <div
          v-if="!valid"
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
        >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
        </div>
      </div>
    </div>

    <div v-else>
      <button
        @click="editHTML = false"
        class="rounded border border-transparent bg-twilight-700 px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
      >
        <svg height="24" width="24">
          <use :href="GridSVG + '#gridicons-code'" />
        </svg>
      </button>
      <textarea
        v-model="rawHTML"
        class="mt-2 h-40 w-full rounded-md border border-gray-300 p-2"
        raw
        editor
        @change="this.$emit('update:modelValue', rawHTML)"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import { Editor, EditorContent } from "@tiptap/vue-3";
import Modal from "@/components/modals/PopupModal.vue";
import TextInput from "@/components/form/TextInput.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/outline";
import GridSVG from "gridicons/svg-sprite/gridicons.svg";
import ToolTip from "@/components/modals/ToolTip.vue";

export default {
  components: {
    EditorContent,
    ExclamationCircleIcon,
    Modal,
    TextInput,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ToolTip,
  },
  props: {
    type: { type: [String, Number], default: "text" },
    inputTitle: { type: String },
    placeholder: { type: String },
    displayOnly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    modelValue: {
      type: String,
      default: "",
    },
    wideItem: { type: Boolean, default: false },
    isValid: { type: Boolean, default: true },
    classProp: { type: String, default: null },
    step: { type: Number, default: 0.1 },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      editor: null,
      valid: this.isValid,
      rawHTML: this.modelValue,
      editHTML: false,
      showImageModal: false,
      imageURL: "",
      GridSVG,
    };
  },
  methods: {
    addImage() {
      this.showImageModal = true;

      //   const url = window.prompt("URL");

      //   if (url) {
      //     this.editor.chain().focus().setImage({ src: url }).run();
      //   }
    },
    insertImage() {
      if (!this.imageURL) {
        return;
      }
      this.editor.chain().focus().setImage({ src: this.imageURL }).run();
      this.imageURL = "";
      this.showImageModal = false;
      //   this.rawHTML = this.editor.getHTML();
    },
  },
  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      this.rawHTML = value;
      this.editor.commands.setContent(value, false);
    },
    rawHTML(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Image,
        Underline,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
      ],
      content: this.modelValue,
      editorProps: {
        attributes: {
          class:
            "prose dark:prose-invert prose-sm sm:prose-base  m-5 focus:outline-none",
        },
      },
      onUpdate: () => {
        this.$emit("update:modelValue", this.editor.getHTML());
        this.rawHTML = this.editor.getHTML();
      },
    });
  },

  beforeUnmount() {
    if (this.editor) this.editor.destroy();
  },
};
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
  max-width: 120ch;
  min-height: 150px;
  //   max-height: 200px;
  overflow-y: auto;
  font-size: 0.875rem;
}
</style>
