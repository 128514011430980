<template>
  <div class="mt-2"></div>
  <table-toolbar>
    <template #toolbarComponents>
      <div></div>
      <FileSelect
        buttonText="Add Attachment"
        @input="addFileToPendingAttachment"
        accept="*"
      />
    </template>
  </table-toolbar>
  <div v-if="attachments.length === 0" class="text-center">
    <h3 class="mt-2 text-sm font-semibold text-gray-900">
      There's Nothing Here
    </h3>
    <p class="mt-1 text-sm text-gray-500">No attachments have been added.</p>
  </div>
  <base-table v-else>
    <template #headerCells>
      <table-header-cell :isLeadingCell="true" headerText="Attachment" />
      <table-header-cell
        :isEmptyHeader="true"
        headerText="Delete Attachments"
      />

      <table-header-cell :isEmptyHeader="true" headerText="View Attachments" />
    </template>
    <template #bodyRows>
      <tr v-for="attachment in attachments" :key="attachment.attachment_url">
        <table-body-cell
          :cellData="attachment.attachment_name"
          :isLeadingCell="true"
        />
        <table-body-cell
          :isButtonCell="true"
          buttonDisplayText="Delete"
          @buttonCellClick="addFileToPendingAttachmentRemovals(attachment)"
        />
        <table-body-cell
          :isButtonCell="true"
          buttonDisplayText="View"
          @buttonCellClick="openFileTab(attachment.attachment_url)"
        />
      </tr>
    </template>
  </base-table>
</template>

<script>
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import TableToolbar from "@/components/table/TableToolbar.vue";
import FileSelect from "@/components/form/FileSelect.vue";

export default {
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["pendingAttachmentRemovalsUpdated", "pendingAttachmentsUpdated"],
  components: {
    BaseTable,
    TableBodyCell,
    TableHeaderCell,
    TableToolbar,
    FileSelect,
  },
  methods: {
    addFileToPendingAttachment(file) {
      this.$emit("pendingAttachmentsUpdated", file);
    },
    addFileToPendingAttachmentRemovals(file) {
      this.$emit("pendingAttachmentRemovalsUpdated", file);
    },
    openFileTab(url) {
      window.open(url, "_blank");
    },
    // For use in the parent component
    // The emits should call these functions in the parent and the parent should submit them to the correct attachment store
    //
    // addFileToPendingAttachment(file) {
    //   this.pendingProductAttachments.push(file);
    // },
    // addFileToPendingAttachmentRemovals(file_url) {
    //   this.pendingProductAttachmentRemovals.push(file_url);
    // },
    // async uploadVendorProductAttachments(vendorProductId) {
    //   for (let file of this.pendingProductAttachments) {
    //     await this.$store.dispatch("uploadVendorProductAttachment", {
    //       productId: vendorProductId,
    //       file,
    //     });
    //   }
    // },
    // async removeVendorProductAttachments(vendorProductId) {
    //   for (let attachment of this.pendingProductAttachmentRemovals) {
    //     let attachmentUrl = attachment.attachment_url.split("/").pop();
    //     await this.$store.dispatch("removeVendorProductAttachment", {
    //       productId: vendorProductId,
    //       attachment_url: attachmentUrl,
    //     });
    //   }
    // },
  },
};
</script>
