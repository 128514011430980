<template>
  <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
    <div
      class="col-span-1 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl sm:col-span-3 sm:w-full sm:max-w-lg sm:p-6"
      v-for="lob in linesOfBusiness"
      :key="lob.serviceType"
    >
      <div class="">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-parcelGreen-100"
        >
          <OutlineHeroIcon
            :name="lob.iconName"
            classProp="h-6 w-6 text-parcelGreen-600"
            aria-hidden="true"
          />
        </div>
        <div class="mt-3 text-center">
          <div as="h3" class="text-2xl font-medium leading-6 text-gray-900">
            {{ lob.title }}
          </div>
          <!-- <div class="mt-2">
                <p class="text-sm text-gray-500">
                  {{ lob.description }}
                </p>
              </div> -->
        </div>
      </div>
      <div class="mt-5" @click="toggleFeatureFlag(lob.featureFlag)">
        <button
          class="inline-flex w-full justify-center rounded-md border border-transparent bg-parcelOrange-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          :class="
            currentOrg.feature_flags.includes(lob.featureFlag)
              ? ''
              : 'bg-parcelGreen-500 hover:bg-green-700 focus:ring-green-500'
          "
        >
          {{
            currentOrg.feature_flags.includes(lob.featureFlag) ? "Hide" : "Show"
          }}
        </button>
      </div>
    </div>
    <div class="col-span-6 text-center">
      Toggle to show/hide menu items. Note that this will affect
      <span class="underline">all</span>
      users.
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";

export default {
  components: {
    OutlineHeroIcon,
  },
  props: {},
  data() {
    return {
      toast: useToast(),
      orgUpdate: {
        name: null, // string
        contact_phone: null, // string
        contact_email: null, // string
        application_flag: null, // string
        feature_flags: [], // array
        services: [], // array
      },
    };
  },
  methods: {
    toggleFeatureFlag(featureFlagToToggle) {
      console.log("CURRENT ORG FF", this.currentOrg.feature_flags);
      let submitOrg = {
        name: this.currentOrg.name,
        feature_flags: [],
      };
      this.currentOrg.feature_flags.forEach((el) => {
        submitOrg.feature_flags.push(el);
      });
      if (submitOrg.feature_flags.includes(featureFlagToToggle)) {
        submitOrg.feature_flags = submitOrg.feature_flags.filter(
          (el) => el !== featureFlagToToggle,
        );
      } else {
        submitOrg.feature_flags.push(featureFlagToToggle);
      }
      console.log("SAVE ORG", submitOrg);
      this.$store.dispatch("updateOrg", submitOrg);
      this.toast.success("Navigation Settings Saved!", {
        timeout: 1500,
        hideProgressBar: true,
      });
    },
  },
  computed: {
    // {iconName: "OutlineHeroIcon", title: "Quotes", description: "Create and manage quotes", featureFlag: "quotes"},
    // gp-carbon, gp-buy, gp-chat, gp-discover, gp-hauling, gp-help, gp-recs, gp-sell
    // gp-carbon is a default, gp-recs & gp-discover isn't necessary for anything
    linesOfBusiness() {
      return [
        {
          iconName: "ShoppingCartIcon",
          title: "Buy",
          description: "Manage quotes and orders with your vendors",
          featureFlag: "gp-buy",
        },
        {
          iconName: "CurrencyDollarIcon",
          title: "Sell",
          description: "Manage your crop sales",
          featureFlag: "gp-sell",
        },
        {
          iconName: "TruckIcon",
          title: "Hauling",
          description: "Manage your hauling orders",
          featureFlag: "gp-hauling",
        },
        {
          iconName: "TemplateIcon",
          title: "FMS",
          description: "Manage your work orders and crop plans",
          featureFlag: "gp-fms",
        },
        // {
        //   iconName: "ShoppingCartIcon",
        //   title: "Discover",
        //   description: "Find new vendors and products",
        //   featureFlag: "gp-discover",
        // },
      ];
    },
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
  },
};
</script>

<style></style>
