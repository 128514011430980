<template>
  <div class="my-3 overflow-hidden bg-white shadow sm:rounded-lg">
    <div class="flex items-center justify-between px-4 py-6 sm:px-6">
      <div>
        <h3 class="text-base font-semibold leading-7 text-gray-900">
          {{ title }}
        </h3>
        <p
          class="mt-1 max-w-2xl text-sm leading-6 text-gray-500"
          v-show="!isCollapsed"
        >
          {{ description }}
        </p>
      </div>
      <div class="flex items-center space-x-2">
        <!-- Toggle Collapse Icon -->
        <button @click="toggleCollapse">
          <OutlineHeroIcon
            :name="isCollapsed ? 'ChevronRightIcon' : 'ChevronDownIcon'"
            classProp="h-5 w-5 text-gray-400 cursor-pointer"
          />
        </button>
        <!-- Placeholder Close Icon -->
        <button @click="$emit('closeProcess')">
          <OutlineHeroIcon
            name="XIcon"
            classProp="h-5 w-5 text-gray-400 cursor-pointer"
          />
        </button>
      </div>
    </div>
    <!-- Collapsible content -->
    <div class="border-t border-gray-100 px-4 py-6" v-show="!isCollapsed">
      <ul
        role="list"
        class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
      >
        <slot name="gridItems"></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";

export default {
  components: {
    OutlineHeroIcon,
  },
  emits: ["closeProcess"],
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isCollapsed: false, // Tracks whether the card is collapsed
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>
