import client from "@/api/parcel/api.js";

export default {
  state() {
    return { affiliateOrgs: [] };
  },
  mutations: {
    SET_AFFILIATE_ORGS(state, data) {
      state.affiliateOrgs = data;
    },
    RESET_AFFILIATE_ORGS(state) {
      state.affiliateOrgs = [];
    },
  },
  actions: {
    async getAffiliateOrgs({ commit, dispatch }) {
      const { data, errors } = await client.orgs.readAffiliates();
      if (errors) {
        dispatch("catchParcelApiError", errors);
      } else {
        commit("SET_AFFILIATE_ORGS", data.affiliate_orgs);
      }
    },
  },
  getters: {
    currentOrgIsFirstParty: (state, getters, rootState, rootGetters) => {
      // if the affiliateOrgs array is empty, return true to prevent the banner from showing prior to loading the affiliate orgs
      if (state.affiliateOrgs.length === 0) {
        return true;
      }
      return getters.getAffiliateOrgById(rootGetters.getCurrentOrg?.id)
        ?.internal;
    },
    getAffiliateOrgById: (state) => (id) => {
      return state.affiliateOrgs.find((org) => org.id === id);
    },
  },
};
