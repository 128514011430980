<template>
  <page-card headingTitle="View Offer">
    <template #buttonArea>
      <base-button
        v-if="currentOffer && !currentOffer.published"
        buttonText="Publish"
        :marginLeft="false"
        @buttonClick="() => updatePublishedStatus(true)"
      />
      <base-button
        v-else-if="currentOffer && currentOffer.published"
        buttonText="Unpublish"
        :redButton="true"
        :marginLeft="false"
        @buttonClick="() => updatePublishedStatus(false)"
      />
    </template>
    <template #cardDetails>
      <text-input
        v-if="currentOriginator"
        inputTitle="Contract Originator"
        :display-only="true"
        :display-only-value="currentOriginator.originator_details.name"
        :wideItem="true"
      />
      <offer-form :offerId="offerId" :readOnly="true" form-id="viewOfferForm" />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import OfferForm from "@/layouts/buyer-portal/bp-affiliate/offers/OfferForm.vue";
import TextInput from "@/components/form/TextInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: {
    PageCard,
    OfferForm,
    TextInput,
    BaseButton,
  },
  data() {
    return {};
  },
  computed: {
    offerId() {
      return this.$route.params.offerId;
    },
    currentOffer() {
      if (this.$store.getters.getBuyerMarketOfferById(this.offerId)) {
        return this.$store.getters.getBuyerMarketOfferById(this.offerId);
      } else {
        return null;
      }
    },
    originators() {
      if (this.$store.getters.getBuyerContractOriginators()) {
        return this.$store.getters.getBuyerContractOriginators();
      } else {
        return [];
      }
    },
    currentOriginator() {
      if (this.originators == []) return null;
      return this.originators.find(
        (originator) =>
          originator?.originator_details?.id === this.currentOffer?.assigned_by,
      );
    },
  },
  methods: {
    updatePublishedStatus(published) {
      this.$store.dispatch("updateBuyerMarketOffer", {
        id: this.offerId,
        published,
      });
    },
  },
};
</script>
