<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Inquiry"
    modalDescription="Are you sure you want to permanently delete this inquiry?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteCropInquiry"
  />
  <page-card :headingTitle="'Inquiry: ' + $route.params.inquiryId">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="editCropInquiryFormId"
      />
      <base-button
        buttonText="Delete"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
      />
    </template>
    <template #cardDetails>
      <crop-inquiry-form
        :orderId="parseInt($route.params.inquiryId)"
        :formId="editCropInquiryFormId"
      />
    </template>
    <template #componentSection>
      <!-- Tabs Below Grid -->
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Chat
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10">
            <crop-premium-display :premiums="currentOrder?.premiums" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <add-attachments-table :attachments="[]" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <chat-single-view :thread-id="currentOrder?.details?.threadId" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import CropInquiryForm from "@/layouts/buyer-grower/crop-inquiries/CropInquiryForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import CropPremiumDisplay from "@/layouts/buyer-grower/crop-orders/CropPremiumDisplay.vue";
import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";
import ChatSingleView from "@/layouts/common/ChatSingleView.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    BaseButton,
    SubmitButton,
    CropInquiryForm,
    DeleteModal,
    CropPremiumDisplay,
    AddAttachmentsTable,
    ChatSingleView,
  },
  data() {
    return {
      modalIsActive: false,
      editCropInquiryFormId: "editCropInquiry",
    };
  },
  computed: {
    inquiryId() {
      return parseInt(this.$route.params.inquiryId);
    },
    currentOrder() {
      return this.$store.getters.getGrowerCropOrderById(this.inquiryId);
    },
  },
  methods: {
    // Modal Methods
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    async deleteCropInquiry() {
      this.modalIsActive = false;
      // console.log("DELETE");
      await this.$store.dispatch("deleteGrowerCropOrder", {
        id: this.$route.params.inquiryId,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-inquiries" });
      }
    },
  },
};
</script>
