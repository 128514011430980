<template>
  <div class="overflow-hidden bg-white px-4 py-16 sm:px-6 lg:px-8 lg:py-24">
    <div class="relative mx-auto max-w-xl">
      <svg
        class="absolute left-full translate-x-1/2 transform"
        width="404"
        height="404"
        fill="none"
        viewBox="0 0 404 404"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="85737c0e-0916-41d7-917f-596dc7edfa27"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="404"
          fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
        />
      </svg>
      <svg
        class="absolute bottom-0 right-full -translate-x-1/2 transform"
        width="404"
        height="404"
        fill="none"
        viewBox="0 0 404 404"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="85737c0e-0916-41d7-917f-596dc7edfa27"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="404"
          fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
        />
      </svg>
      <div class="text-center">
        <h2
          class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight"
        >
          Contact Us
        </h2>
        <p class="mt-4 text-lg leading-6 text-gray-500">{{ blurb }}</p>
      </div>
      <div class="mt-12">
        <form
          @submit.prevent="submitContactForm"
          class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
        >
          <div>
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
              >First name</label
            >
            <div class="mt-1">
              <input
                v-model="contactRequest.first_name"
                type="text"
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                class="block w-full rounded-md border-gray-300 px-4 py-3 shadow-sm focus:border-parcelOrange-500 focus:ring-parcelOrange-500"
              />
            </div>
          </div>
          <div>
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
              >Last name</label
            >
            <div class="mt-1">
              <input
                v-model="contactRequest.last_name"
                type="text"
                name="last-name"
                id="last-name"
                autocomplete="family-name"
                class="block w-full rounded-md border-gray-300 px-4 py-3 shadow-sm focus:border-parcelOrange-500 focus:ring-parcelOrange-500"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="company" class="block text-sm font-medium text-gray-700"
              >Company</label
            >
            <div class="mt-1">
              <input
                v-model="contactRequest.company"
                type="text"
                name="company"
                id="company"
                autocomplete="organization"
                class="block w-full rounded-md border-gray-300 px-4 py-3 shadow-sm focus:border-parcelOrange-500 focus:ring-parcelOrange-500"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-700"
              >Email<span class="text-red-600"> *</span></label
            >
            <div class="mt-1">
              <input
                v-model="contactRequest.email"
                required
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                class="block w-full rounded-md border-gray-300 px-4 py-3 shadow-sm focus:border-parcelOrange-500 focus:ring-parcelOrange-500"
              />
            </div>
          </div>
          <!-- <div class="sm:col-span-2">
            <label for="phone-number" class="block text-sm font-medium text-gray-700">Phone Number</label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 flex items-center">
                <label for="country" class="sr-only">Country</label>
                <select id="country" name="country" class="h-full py-0 pl-4 pr-8 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-parcelOrange-500 focus:border-parcelOrange-500">
                  <option>US</option>
                  <option>CA</option>
                  <option>EU</option>
                </select>
              </div>
              <input type="text" name="phone-number" id="phone-number" autocomplete="tel" class="block w-full px-4 py-3 pl-20 border-gray-300 rounded-md focus:ring-parcelOrange-500 focus:border-parcelOrange-500" placeholder="+1 (555) 987-6543" />
            </div>
          </div> -->
          <div class="sm:col-span-2">
            <label for="message" class="block text-sm font-medium text-gray-700"
              >Message<span class="text-red-600"> *</span></label
            >
            <div class="mt-1">
              <textarea
                v-model="contactRequest.message"
                required
                id="message"
                name="message"
                rows="4"
                class="block w-full rounded-md border border-gray-300 px-4 py-3 shadow-sm focus:border-parcelOrange-500 focus:ring-parcelOrange-500"
              />
            </div>
          </div>
          <!-- <div class="sm:col-span-2">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <Switch v-model="agreed" :class="[agreed ? 'bg-parcelOrange-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-parcelOrange-500']">
                  <span class="sr-only">Agree to policies</span>
                  <span aria-hidden="true" :class="[agreed ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
              </div>
              <div class="ml-3">
                <p class="text-base text-gray-500">
                  By selecting this, you agree to the
                  {{ ' ' }}
                  <a href="#" class="font-medium text-gray-700 underline">Privacy Policy</a>
                  {{ ' ' }}
                  and
                  {{ ' ' }}
                  <a href="#" class="font-medium text-gray-700 underline">Cookie Policy</a>.
                </p>
              </div>
            </div>
          </div> -->
          <div class="sm:col-span-2">
            <button
              type="submit"
              class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-parcelOrange-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-parcelOrange-600 focus:outline-none focus:ring-2 focus:ring-parcelOrange-500 focus:ring-offset-2"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ky from "ky";
const BASE_URL = process.env.VUE_APP_API_URL || "https://api.parcel.ag";
const CONTACT_URL = BASE_URL + "/v1/contact";

export default {
  props: {
    blurb: {
      type: String,
      default:
        "We'd love to hear from you. Please fill out the form below and we'll get back to you as soon as possible.",
    },
    routeName: {
      type: String,
      default: "landing-page", //can be set to _SELF to stay on the page, and emit a close event
    },
    first: {
      type: String,
      default: "",
    },
    last: {
      type: String,
      default: "",
    },
    phone_number: {
      type: String,
      default: "",
    },
    email_address: {
      type: String,
      default: "",
    },
    company_name: {
      type: String,
      default: "",
    },
    message_text: {
      type: String,
      default: "",
    },
  },
  events: ["close"],
  data() {
    return {
      contactRequest: {
        email: this.email_address,
        phone: this.phone_number,
        first_name: this.first,
        last_name: this.last,
        company: this.company_name,
        message: this.message_text,
      },
    };
  },
  methods: {
    async submitContactForm() {
      // console.log(BASE_URL);
      const json = await ky
        .post(CONTACT_URL, { json: this.contactRequest })
        .json();
      console.log(json);
      if (this.routeName === "_SELF") {
        this.$emit("close");
      } else {
        this.$router.push({ name: this.routeName });
      }
      //this.$router.push({ name: "landing-page" });
    },
  },
};
</script>
