<template>
  <!-- <alert-modal
      :open="modalIsActive"
      modalTitle="Field Shape Required"
      modalDescription="You must draw a shape to save a new field."
      @clickDismiss="dismissModal"
    /> -->
  <page-card headingTitle="Submit New Records">
    <template #buttonArea>
      <base-button
        :buttonText="'Submit'"
        :marginLeft="false"
        @buttonClick="createRecords"
      />
    </template>
    <template #cardDetails>
      <div
        class="mt-2 text-left text-sm font-medium tracking-wider text-gray-500 sm:col-span-6"
      >
        <select-dropdown
          inputTitle="Asset Type"
          :selectOptions="assetTypes"
          v-model="assetType"
          :wideItem="true"
        />

        <select-dropdown
          inputTitle="Record Type"
          :selectOptions="recordTypes"
          v-model="recordType"
          :wideItem="true"
        />
      </div>
      <div
        class="mt-2 text-left text-sm font-medium tracking-wider text-gray-500 sm:col-span-6"
        v-if="assetType === 'field'"
      >
        <!-- Select the fields this record will apply to -->
        <text-input
          :displayOnly="true"
          :required="true"
          helpText="Every selected field will receive a copy of the record you create below."
          :displayOnlyValue="selectedFieldNames"
          inputTitle="Selected Fields"
          class="mt-2"
        />
        <checkbox-input inputTitle="Show" v-model="showFieldSelect" />
        <div v-if="showFieldSelect" class="max-h-96 overflow-auto">
          <table-card>
            <template #header>
              <table-head
                :columns="[
                  { name: 'properties.farm', label: 'Farm' },
                  { name: 'properties.name', label: 'Field', filtered: false },
                  { name: 'properties.crop', label: 'Crop' },
                  { name: 'properties.details.variety', label: 'Variety' },
                  { name: 'properties.acres', label: 'Acres', filtered: false },
                ]"
                :collection="fields"
                @update:derived-collection="derivedFields = $event"
                selectionMode="Multiple"
                @update:selectedCollection="selectedFields = $event"
              >
              </table-head>
            </template>
            <tbody>
              <tr v-for="field in derivedFields" :key="field">
                <table-data td-label="Selected">
                  <input
                    type="checkbox"
                    v-model="field.selected"
                    class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
                    @change="
                      selectedFields = derivedFields.filter(
                        (field) => field.selected,
                      )
                    "
                  />
                </table-data>
                <table-data td-label="Farm">
                  {{ field.properties.farm }}
                </table-data>
                <table-data td-label="Field">
                  <div class="flex">
                    <field-thumbnail
                      :title="field.properties.name"
                      :geojson="field"
                      class="mr-6"
                    ></field-thumbnail>
                    {{ field.properties.name }}
                  </div>
                </table-data>
                <table-data td-label="Crop">
                  {{ field.properties.crop }}
                </table-data>
                <table-data td-label="Variety">
                  {{
                    field.properties?.details?.variety
                      ? field.properties?.details?.variety
                      : ""
                  }}
                </table-data>
                <table-data td-label="Acres">
                  {{ field.properties.acres }}
                </table-data>
              </tr>
            </tbody>
          </table-card>
        </div>
      </div>
      <div
        class="mt-2 text-left text-sm font-medium tracking-wider text-gray-500 sm:col-span-6"
        v-if="assetType === 'equipment'"
      >
        <!-- Select the fields this record will apply to -->
        <text-input
          :displayOnly="true"
          :required="true"
          helpText="Each selected equipment will receive a copy of the record you create below."
          :displayOnlyValue="selectedEquipmentNames"
          inputTitle="Selected Equipment"
          class="mt-2"
        />
        <checkbox-input inputTitle="Show" v-model="showEquipmentSelect" />
        <div v-if="showEquipmentSelect" class="max-h-96 overflow-auto">
          <table-card>
            <template #header>
              <table-head
                :columns="[
                  { name: 'alias', label: 'Name', filtered: false },
                  { name: 'equipment_model', label: 'Model' },
                  { name: 'equipment_class', label: 'Type' },
                  {
                    name: 'last_service_date',
                    label: 'Last Service Date',
                    filtered: false,
                  },
                ]"
                :collection="equipment"
                @update:derived-collection="derivedEquipment = $event"
                selectionMode="Multiple"
                @update:selectedCollection="selectedEquipment = $event"
              >
              </table-head>
            </template>
            <tbody>
              <tr v-for="asset in derivedEquipment" :key="asset.id">
                <table-data td-label="Selected">
                  <input
                    type="checkbox"
                    v-model="asset.selected"
                    class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
                    @change="
                      selectedEquipment = derivedEquipment.filter(
                        (e) => e.selected,
                      )
                    "
                  />
                </table-data>
                <table-data td-label="Name">
                  {{ asset.alias }}
                </table-data>
                <table-data td-label="Model">
                  {{ asset.equipment_model }}
                </table-data>
                <table-data td-label="Type">
                  {{ asset.equipment_class }}
                </table-data>
                <table-data td-label="Last Service Date">
                  {{ asset.last_service_date?.substring(0, 10) }}
                </table-data>
              </tr>
            </tbody>
          </table-card>
        </div>
      </div>
      <div
        v-if="selectedRecordSchema"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <div
          class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
        >
          Record Details
        </div>
        <record-form-layout
          @update:record="record = $event"
          :recordProp="record"
          :recordType="recordType"
        />

        <!-- <component
          v-if="selectedRecordSchema.data.result"
          :is="selectedRecordSchema.data.result.component"
          :inputTitle="selectedRecordSchema.data.result.label"
          :full-width="true"
          :required="true"
          v-model="record.result"
        />
        <template
          v-for="formInput in selectedRecordSchema.data.payload"
          :key="formInput"
        >
          <select-search
            v-if="formInput.component == 'SelectSearch'"
            inputTitle="Record Type"
            :selectOptions="formInput?.selectOptions"
            v-model="record.payload[formInput.value]"
            :wideItem="true"
          />
          <combo-box
            v-else-if="formInput.component == 'ComboBox'"
            :inputTitle="formInput.label"
            :wideItem="formInput.wideItem"
            :displayOnly="formInput.displayOnly"
            :selectOptions="formInput?.selectOptions"
            v-model="record.payload[formInput.value]"
            @on-blur="record.payload[formInput.value] = $event"
          />
          <component
            v-else
            :is="formInput.component"
            :inputTitle="formInput.label"
            :wideItem="formInput.wideItem"
            v-model="record.payload[formInput.value]"
          />
        </template>
        <file-select
          class="h-full"
          :buttonText="selectedRecordSchema.data.attachment.label"
          accept="*"
          @input="uploadAttachment"
          v-if="selectedRecordSchema.data.attachment"
        /> -->
      </div>
    </template>
  </page-card>
</template>

<script>
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { useToast } from "vue-toastification";
// import AlertModal from "@/components/modals/AlertModal.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";
import TextInput from "@/components/form/TextInput.vue";

// RecordTypes imports
import RecordFormLayout from "@/layouts/grower-portal/records/RecordFormLayout.vue";

Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0;
};

const RecordTypeSchemas = require("@/layouts/grower-portal/records/RecordTypes.json");

export default {
  props: {
    // needed to allow a submit button outside the form tags to submit the form
    // formId: {
    //   type: String,
    //   required: true,
    // },
  },
  components: {
    SelectDropdown,
    RecordFormLayout,
    // AlertModal,
    PageCard,
    BaseButton,
    CheckboxInput,
    TableHead,
    TableCard,
    TableData,
    FieldThumbnail,
    TextInput,
  },
  data() {
    return {
      modalIsActive: false,
      toast: useToast(),
      showFieldSelect: true,
      showEquipmentSelect: true,
      derivedFields: [],
      derivedEquipment: [],
      // Metadata
      recordType: null,
      assetType: "field",
      selectedFields: [],
      selectedEquipment: [],
      // Submit
      record: {
        result: null,
        payload: {},
      },
    };
  },
  computed: {
    selectedFieldNames() {
      return this.selectedFields
        .map((field) => field.properties.name)
        .join(", ");
    },
    selectedFieldIds() {
      return this.selectedFields.map((field) => field.id);
    },
    selectedEquipmentNames() {
      return this.selectedEquipment.map((e) => e.alias).join(", ");
    },
    selectedEquipmentIds() {
      return this.selectedEquipment.map((e) => e.id);
    },
    fields() {
      return this.$store.state.fields?.fields;
    },
    equipment() {
      return this.$store.state.equipment.equipment;
    },
    assetTypes() {
      return [
        { label: "Field", value: "field" },
        { label: "Equipment", value: "equipment" },
      ];
    },
    recordTypes() {
      let recordTypeSchemas = RecordTypeSchemas.filter(
        (recordTypeSchema) => !recordTypeSchema.readonly,
      );
      let filteredRecordTypeSchemas = recordTypeSchemas.filter(
        (recordTypeSchema) => recordTypeSchema.assets === this.assetType,
      );
      recordTypeSchemas = filteredRecordTypeSchemas.map((recordTypeSchema) => {
        return {
          label: recordTypeSchema.category,
          value: recordTypeSchema.category,
        };
      });
      recordTypeSchemas.sort((a, b) => a.label.localeCompare(b.label));
      return recordTypeSchemas;
    },
    selectedRecordSchema() {
      return RecordTypeSchemas.find(
        (recordTypeSchema) => recordTypeSchema.category === this.recordType,
      );
    },
  },
  methods: {
    async createRecords() {
      if (this.assetType === "field") {
        for (let fieldId of this.selectedFieldIds) {
          let record = {
            ...this.record,
            field: fieldId,
            category: this.recordType,
            completed_by: this.$store.getters.getCurrentUser.id,
          };
          await this.$store.dispatch("createRecord", record, true);
        }
        this.selectedFields = [];
        for (let field of this.derivedFields) {
          field.selected = false;
        }
      } else if (this.assetType === "equipment") {
        for (let equipmentId of this.selectedEquipmentIds) {
          let record = {
            ...this.record,
            equipment: equipmentId,
            category: this.recordType,
            completed_by: this.$store.getters.getCurrentUser.id,
          };
          await this.$store.dispatch("createRecord", record, true);
        }
        this.selectedEquipment = [];
        for (let equipment of this.derivedEquipment) {
          equipment.selected = false;
        }
      }

      await this.$store.dispatch("getRecords");
      this.$router.push({ name: "records" });
    },
    uploadAttachment(file) {
      this.record.attachment = file;
    },
    // displayNoGeomModal() {
    //   this.modalIsActive = true;
    // },
    // dismissModal() {
    //   this.modalIsActive = false;
    // },
  },
};
</script>
