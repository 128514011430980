<template>
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Core
  </div>
  <number-input
    :required="true"
    :wideItem="true"
    inputTitle="Crop Yield (bushels/acre)"
    inputHtmlType="text"
    v-model="currentInputs.crop_yield"
  />
  <number-input
    :required="true"
    :wideItem="true"
    inputTitle="Farm Size (acres)"
    inputHtmlType="text"
    v-model="currentInputs.farm_size"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Region
  </div>

  <select-search
    :wideItem="true"
    :helpText="`If outside the US, please pick 'Other' crop type. Corn is based on US data and lifecycle analysis`"
    inputTitle="Location State"
    v-model="currentInputs.location_state"
    :selectOptions="states"
  />
  <select-search
    :wideItem="true"
    inputTitle="Location County"
    v-model="currentInputs.location_county"
    :selectOptions="counties(currentInputs.location_state)"
  />
  <!-- <select-dropdown
    :wideItem="true"
    inputTitle="Climate Zone"
    :selectOptions="[
      {
        label: 'No consideration',
        value: 'No consideration',
      },
      { label: 'Wet or Moist', value: 'Wet or Moist' },
    ]"
    v-model="currentInputs.climate_zone"
  /> -->

  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Energy Used
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Diesel (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.diesel"
  />
  <number-input
    :wideItem="true"
    inputTitle="Electricity (kWh/acre)"
    inputHtmlType="text"
    v-model="currentInputs.electricity"
  />
  <number-input
    :wideItem="true"
    inputTitle="Gasoline (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.gasoline"
  />
  <number-input
    :wideItem="true"
    inputTitle="Liquefied Petroleum Gas (gallons/acre)"
    inputHtmlType="text"
    v-model="currentInputs.liquefied_petroleum_gas"
  />
  <number-input
    :wideItem="true"
    inputTitle="Natural Gas (ft3/acre)"
    inputHtmlType="text"
    v-model="currentInputs.natural_gas"
  />

  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Nitrogen Fertilizer
  </div>
  <select-dropdown
    :wideItem="true"
    inputTitle="Source of Ammonia"
    :selectOptions="[
      {
        label: 'Conventional',
        value: 'Conventional',
      },
      { label: 'Green', value: 'Green' },
    ]"
    v-model="currentInputs.source_of_ammonia"
  />
  <select-dropdown
    :wideItem="true"
    inputTitle="Technology for Nitrogen Management"
    :selectOptions="[
      {
        label: 'Business as usual',
        value: 'Business as usual',
      },
      {
        label: '4R (Right time, Right place, Right form, and Right rate)',
        value: '4R (Right time, Right place, Right form, and Right rate)',
      },
      {
        label: 'Enhanced Efficiency Fertilizer',
        value: 'Enhanced Efficiency Fertilizer',
      },
    ]"
    v-model="currentInputs.technology_for_nitrogen_management"
  />
  <number-input
    :wideItem="true"
    inputTitle="Ammonia (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.ammonia"
  />
  <number-input
    :wideItem="true"
    inputTitle="Ammonium Nitrate (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.ammonium_nitrate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Ammonium Sulfate (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.ammonium_sulfate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Diammonium Phosphate (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.n_diammonium_phosphate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Monoammonium Phosphate (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.n_monoammonium_phosphate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Urea (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.urea"
  />
  <number-input
    :wideItem="true"
    inputTitle="Urea Ammonium Nitrate Solution (lbs N/acre)"
    inputHtmlType="text"
    v-model="currentInputs.urea_ammonium_nitrate_solution"
  />

  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Phosphorus Fertilizer
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Diammonium Phosphate (lbs P2O5/acre)"
    inputHtmlType="text"
    v-model="currentInputs.p_diammonium_phosphate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Monoammonium Phosphate (lbs P2O5/acre)"
    inputHtmlType="text"
    v-model="currentInputs.p_monoammonium_phosphate"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Potash Fertilizer
  </div>
  <!-- Content goes here -->
  <number-input
    :wideItem="true"
    inputTitle="Potash Fertilizer (lbs K2O/acre)"
    inputHtmlType="text"
    v-model="currentInputs.potash_fertilizer"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Cover Crop
  </div>
  <select-dropdown
    :wideItem="true"
    inputTitle="Cover Crop Rotation"
    :selectOptions="[
      {
        label: 'Cover crop',
        value: 'Cover crop',
      },
      {
        label: 'No cover crop',
        value: 'No cover crop',
      },
    ]"
    v-model="currentInputs.cover_crop_rotation"
  />
  <number-input
    :wideItem="true"
    inputTitle="Cover Crop Farming Fuel (gallons)"
    inputHtmlType="text"
    v-model="currentInputs.cover_crop_farming_gallons"
    :helpText="`Fuel used for cover crop farming, do not include in total fuel used in the Energy section.`"
  />
  <number-input
    :wideItem="true"
    inputTitle="Cover Crop Herbicide Application Rate (g/acre)"
    inputHtmlType="text"
    v-model="currentInputs.cover_crop_herbicide_application_rate"
  />
  <number-input
    :wideItem="true"
    inputTitle="Cover Crop Yield (dry ton/acre)"
    inputHtmlType="text"
    v-model="currentInputs.cover_crop_yield"
    :helpText="`Report any crops removed from field, do not report residue left in field.`"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Manure
  </div>
  <select-dropdown
    :wideItem="true"
    inputTitle="Manure"
    :selectOptions="[
      {
        label: 'Manure',
        value: 'Manure',
      },
      {
        label: 'No manure',
        value: 'No manure',
      },
    ]"
    v-model="currentInputs.manure"
  />
  <number-input
    :wideItem="true"
    inputTitle="Manure Application Fuel Used (gallons)"
    inputHtmlType="text"
    v-model="currentInputs.manure_application_gallons"
    :helpText="`Fuel used for manure application, do not include in total fuel used in the Energy section.`"
  />
  <number-input
    :wideItem="true"
    inputTitle="Manure Transportation Distance (miles)"
    inputHtmlType="text"
    v-model="currentInputs.manure_transportation_distance"
  />
  <number-input
    :wideItem="true"
    inputTitle="Manure Transportation Fuel Used (gallons)"
    inputHtmlType="text"
    v-model="currentInputs.manure_transportation_gallons"
    :helpText="`Fuel used for manure transportation from source to field, do not include in total fuel used in the Energy section.`"
  />
  <number-input
    :wideItem="true"
    inputTitle="Beef Cattle (tons manure/acre)"
    inputHtmlType="text"
    v-model="currentInputs.beef_cattle"
  />
  <number-input
    :wideItem="true"
    inputTitle="Chicken (tons manure/acre)"
    inputHtmlType="text"
    v-model="currentInputs.chicken"
  />
  <number-input
    :wideItem="true"
    inputTitle="Dairy Cow (tons manure/acre)"
    inputHtmlType="text"
    v-model="currentInputs.dairy_cow"
  />
  <number-input
    :wideItem="true"
    inputTitle="Swine (tons manure/acre)"
    inputHtmlType="text"
    v-model="currentInputs.swine"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Other
  </div>
  <number-input
    :wideItem="true"
    inputTitle="Herbicide (g/acre)"
    inputHtmlType="text"
    v-model="currentInputs.herbicide"
  />
  <number-input
    :wideItem="true"
    inputTitle="Insecticide (g/acre)"
    inputHtmlType="text"
    v-model="currentInputs.insecticide"
  />
  <number-input
    :wideItem="true"
    inputTitle="Lime (lbs/acre)"
    inputHtmlType="text"
    v-model="currentInputs.lime"
  />

  <select-dropdown
    :wideItem="true"
    inputTitle="Tillage"
    :selectOptions="[
      {
        label: 'Conventional tillage',
        value: 'Conventional tillage',
      },
      { label: 'Reduced tillage', value: 'Reduced tillage' },
      { label: 'No till', value: 'No till' },
    ]"
    v-model="currentInputs.tillage"
  />
</template>

<script>
// import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
let stateList = require("@/assets/state_county.js");

export default {
  emits: ["updateScenarioInputs"],
  props: {
    scenarioInputsInitial: {
      type: Object,
      default: null,
    },
  },
  components: {
    // TextInput,
    NumberInput,
    SelectDropdown,
    SelectSearch,
  },
  data() {
    return {
      stateList: stateList,
      currentInputs: this.scenarioInputsInitial
        ? this.scenarioInputsInitial
        : {
            farm_size: null,
            crop_yield: null,
            diesel: null,
            gasoline: null,
            natural_gas: null,
            liquefied_petroleum_gas: null,
            electricity: null,
            ammonia: null,
            urea: null,
            ammonium_nitrate: null,
            ammonium_sulfate: null,
            urea_ammonium_nitrate_solution: null,
            n_monoammonium_phosphate: null,
            n_diammonium_phosphate: null,
            p_monoammonium_phosphate: null,
            p_diammonium_phosphate: null,
            potash_fertilizer: null,
            lime: null,
            herbicide: null,
            insecticide: null,
            cover_crop_rotation: "Cover crop",
            cover_crop_farming_energy: null,
            cover_crop_herbicide_application_rate: null,
            cover_crop_yield: null,
            manure: "Manure",
            swine: null,
            dairy_cow: null,
            beef_cattle: null,
            chicken: null,
            manure_application_energy: null,
            manure_transportation_distance: null,
            manure_transportation_energy: null,
            tillage: "Conventional tillage",
            location_state: null,
            location_county: null,
            // climate_zone: "No consideration",
            technology_for_nitrogen_management: "Business as usual",
            source_of_ammonia: "Conventional",
          },
    };
  },
  computed: {
    states() {
      let states = [];
      for (let state in this.stateList) {
        states.push(state);
      }
      return states;
    },
  },
  methods: {
    resetScenarioInputs() {
      this.currentInputs = {
        farm_size: null,
        crop_yield: null,
        diesel: null,
        gasoline: null,
        natural_gas: null,
        liquefied_petroleum_gas: null,
        electricity: null,
        ammonia: null,
        urea: null,
        ammonium_nitrate: null,
        ammonium_sulfate: null,
        urea_ammonium_nitrate_solution: null,
        n_monoammonium_phosphate: null,
        n_diammonium_phosphate: null,
        p_monoammonium_phosphate: null,
        p_diammonium_phosphate: null,
        potash_fertilizer: null,
        lime: null,
        herbicide: null,
        insecticide: null,
        cover_crop_rotation: "Cover crop",
        cover_crop_farming_energy: null,
        cover_crop_herbicide_application_rate: null,
        cover_crop_yield: null,
        manure: "Manure",
        swine: null,
        dairy_cow: null,
        beef_cattle: null,
        chicken: null,
        manure_application_energy: null,
        manure_transportation_distance: null,
        manure_transportation_energy: null,
        tillage: "Conventional tillage",
        location_state: null,
        location_county: null,
        // climate_zone: "No consideration",
        technology_for_nitrogen_management: "Business as usual",
        source_of_ammonia: "Conventional",
      };
    },
    counties(state) {
      let counties = [];
      if (state) {
        counties = Object.values(this.stateList[state]);
      }

      return counties;
    },
  },
  watch: {
    currentInputs: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.$emit("updateScenarioInputs", value);
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
