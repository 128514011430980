<template>
  <div class="flex min-h-screen flex-row">
    <!-- Side menu -->
    <div class="flex w-64 overflow-y-auto border-r border-gray-200 bg-white">
      <div class="mt-5 flex flex-grow flex-col">
        <nav class="flex-1 space-y-1 bg-white px-2" aria-label="Sidebar">
          <span class="text-sm font-medium text-green-600"> Help Topics </span>
          <template v-for="item in navMenu" :key="item.name">
            <div v-if="!item.children">
              <button
                @click="setPage(item.docId)"
                :class="[
                  item.current
                    ? 'bg-gray-100 text-gray-900'
                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex w-full items-center rounded-md py-2 pl-7 pr-2 text-sm font-medium',
                ]"
              >
                {{ item.name }}
              </button>
            </div>
            <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
              <DisclosureButton
                :class="[
                  item.current
                    ? 'bg-gray-100 text-gray-900'
                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex w-full items-center rounded-md py-2 pr-2 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500',
                ]"
              >
                <svg
                  :class="[
                    open ? 'rotate-90 text-gray-400' : 'text-gray-300',
                    'mr-2 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
                  ]"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                </svg>
                {{ item.name }}
              </DisclosureButton>
              <DisclosurePanel class="space-y-1">
                <DisclosureButton
                  v-for="subItem in item.children"
                  :key="subItem.name"
                  as="a"
                  @click="setPage(subItem.docId)"
                  class="group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                >
                  {{ subItem.name }}
                </DisclosureButton>
              </DisclosurePanel>
            </Disclosure>
          </template>
          <div>
            <button
              @click="showContactForm = true"
              class="mt-8 text-sm font-medium text-green-600 hover:bg-gray-100 hover:text-green-800"
            >
              Contact Us
            </button>
          </div>
        </nav>
      </div>
    </div>
    <!-- Contact Us Form -->
    <Dialog
      :open="showContactForm"
      @close="showContactForm = false"
      class="absolute z-50 -mt-8 border"
    >
      <!-- The backdrop, rendered as a fixed sibling to the panel container -->
      <div class="fixed inset-0 bg-black/30" aria-hidden="true" />

      <!-- Full-screen container to center the panel -->
      <div class="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel>
          <contact-form
            blurb="Have a problem, or a suggestion for the product? Please let us know!"
            routeName="_SELF"
            :first="firstName"
            :last="lastName"
            :company_name="org.name"
            :email_address="profile.email"
            @close="showContactForm = false"
          />
        </DialogPanel>
      </div>
    </Dialog>
    <!-- Main page content -->

    <div
      class="flex w-full overflow-y-auto border-r border-gray-200 bg-white pl-2 font-medium text-gray-600"
    >
      <span v-html="currentPageContent" class="prose"></span>
    </div>
  </div>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import ContactForm from "@/layouts/static/ContactForm.vue";
export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Dialog,
    DialogPanel,
    ContactForm,
  },
  data() {
    const helpContent = [
      {
        label: "Getting Started",
        docId: "1",
        category: "Overview",
        content: ` <h1 class="text-lg">Welcome, we are glad you are here! </h1>
            <div class="max-w-2xl mt-4">
                We hope using Parcel is an intuitive experience, and we want to ensure you learn how to take advantage of all the great features
                that have been added to help you manage your operations.
            </div>

            <div class="mt-4">
                <h2>How to navigate around in Parcel:</h2>
                <iframe width="980" height="550" src="https://www.youtube.com/embed/vpGqzvi9kxY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            `,
      },
      {
        label: "Fields",
        docId: "2",
        category: "Assets",
        content: `  <div class="max-w-2xl mt-4">Adding your fields allows you to assign tasks, book hauling, and allocate produce sales to those fields. It also allows your business partners to
          see where the fields are, when you set up agreements with them to perform services on those fields.
            </div>
            <div class="max-w-2xl mt-4">
                <h2>How to Add and Edit fields</h2>
                <iframe width="980" height="550" src="https://www.youtube.com/embed/jI1OLMOmA1I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            `,
      },
      {
        label: "Map",
        docId: "3",
        category: "Assets",
        content: `  <div class="max-w-2xl mt-4">The map allows you to locate your fields and equipment (if equiped with telemetry connected to Parcel).
          You can use it to select fields to edit and get turn by turn directions.
            </div>
            <div class="max-w-2xl mt-4">
                <h2>How to use the map:</h2>
                <iframe width="980" height="550" src="https://www.youtube.com/embed/5z7U2P4g78E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            `,
      },
      {
        label: "Activities",
        docId: "4",
        category: "Operations",
        content: `  <div class="max-w-2xl mt-4">
            Activities are used to track work on various assets. These can either be happening in the field, or assigned to work on specific equipment.
            <p>
            Activities are classified into distinct categories to help organize the work by job type.
            <p>
            Work will generally be created as 'Ready', and once it is assigned to a user, set to 'Assigned'. The assigned user should then complete the task and mark it
            either 'Complete' or 'Canceled' if they are unable to complete it. If they are going to take a while to complete the task, they can also mark it 'In Progress'
            so everyone knows they are actively working on the task.
            </div>
            <div class="max-w-2xl mt-4">
                <h2>How to make a hauling reservation:</h2>
                <iframe width="980" height="550" src="https://www.youtube.com/embed/_I5Y9GS2Guc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            `,
      },
      {
        label: "Hauling",
        docId: "5",
        category: "Buy",
        content: `  <div class="max-w-2xl mt-4">
              Hauling allows you to reserve transportation of your harvested produce by carriers you are contracting with that use Parcel.
              This kicks off a process where you share the details of the field to be hauled with your carrier of choice, and the times you plan on harvesting,
              and where that produce should be delivered. The carrier can then arrange to drop trailers at your location, and deliver them when filled.
            </div>
            <div class="max-w-2xl mt-4">
                <h2>How to make a hauling reservation:</h2>
                <iframe width="980" height="550" src="https://www.youtube.com/embed/oY9yltFwnpM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            `,
      },
      {
        label: "Listings",
        docId: "6",
        category: "Sell",
        content: `  <div class="max-w-2xl mt-4">
              Listings allow you to sell your crops.
              You can create listings that are displayed in the Parcel crop markeplace, and buyers can open inquiries with you for any of your listings.
            </div>
            <div class="max-w-2xl mt-4">
                <h2>How to make a listing and receive an inquiry:</h2>
                <iframe width="980" height="550" src="https://www.youtube.com/embed/f5Xm2IhO6TA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            `,
      },
      {
        label: "Orders",
        docId: "7",
        category: "Sell",
        content: `  <div class="max-w-2xl mt-4">
          Orders allow you to track the produce you have sold. You can share details with your buyers if you wish, and you can also use the order to ensure you have allocated all fields to a contract.
            </div>
            <div class="max-w-2xl mt-4">
                <h2>How to manage orders:</h2>
                <iframe width="980" height="550" src="https://www.youtube.com/embed/Wqk3O_kkXa4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            `,
      },
      {
        label: "Carbon",
        docId: "8",
        category: "Sell",
        content: `  <div class="max-w-2xl mt-4">
          Carbon Scenarios allow you to track the carbon footprint of your operations. You can see how much carbon you are emitting. This is helpful if you are trying to sell your crop for a premium through the Parcel crop marketplace.
            </div>
            <div class="max-w-2xl mt-4">
                <h2>How to compute your C02e emissions:</h2>
                <iframe width="980" height="550" src="https://www.youtube.com//embed/lLuOVTidUjU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            `,
      },
    ];
    let profile = this.$store.state.profile.profile;
    let firstName = profile?.name?.split(" ")?.slice(0, -1)?.join(" ");
    let lastName = profile?.name?.split(" ")?.slice(-1)?.join(" ");
    return {
      helpContent: helpContent,
      // navigation: navigation,
      docId: "1",
      showContactForm: false,
      profile: profile,
      org: this.$store.getters.getCurrentOrg,
      firstName: firstName,
      lastName: lastName,
    };
  },
  methods: {
    setPage(page) {
      // console.log("set page");
      this.docId = page;
    },
  },
  computed: {
    currentPageContent() {
      let helpDoc = this.helpContent.find((page) => {
        return page.docId == this.docId;
      });
      let content = helpDoc?.content;
      //console.log("doc found", this.helpContent, helpDoc, content, this.docId);
      return content;
    },
    navMenu() {
      let menu = [];
      for (let helpDoc of this.helpContent) {
        let menuItem = menu.find((item) => helpDoc.category == item.name);
        if (!menuItem) {
          menu.push({
            name: helpDoc.category,
            current: false,
            children: [],
          });
          // console.log("inserting category", helpDoc.category);
        }
        // console.log("inserting menu item", helpDoc, menuItem);
        menuItem = menu.find((item) => helpDoc.category == item.name);
        menuItem.children.push({ name: helpDoc.label, docId: helpDoc.docId });
      }
      return menu;
    },
  },
};
</script>

<!-- Example navigation for menu
/*
    const navigation = [
      {
        name: "Assets",
        current: false,
        children: [
          { name: "Fields", docId: "fields" },
          { name: "Eqiupment", docId: "equipment" },
          { name: "Map", docId: "map" },
        ],
      },
    */
-->
