<template>
  <carbon-scenario-form-single-crop
    onSubmitNavigationRouteName="carbon-single-scenario"
  />
</template>

<script>
import CarbonScenarioFormSingleCrop from "@/layouts/common/carbon/CarbonScenarioFormSingleCrop.vue";

export default {
  components: {
    CarbonScenarioFormSingleCrop,
  },

  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
