<template>
  <form
    @submit.prevent="submitTaskForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <select-dropdown
      :wideItem="true"
      inputTitle="Category"
      v-model="currentTask.category"
      :selectOptions="taskTypes"
      :noSort="true"
      :required="true"
    />
    <div class="sm:col-span-6">
      <text-area inputTitle="Instructions" v-model="currentTask.instructions" />
    </div>
    <div class="sm:col-span-3">
      <date-input
        inputTitle="Complete By"
        v-model="currentTask.deadline"
        :required="true"
        :dateOnly="true"
      />
    </div>
    <div class="mt-8 sm:col-span-3">
      <submit-button buttonText="Save" :formId="formId" />
      <base-button
        buttonText="Cancel"
        :redButton="true"
        @buttonClick="cancelSave"
      />
    </div>
  </form>
</template>

<script>
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import DateInput from "@/components/form/DateInput.vue";
import TextArea from "@/components/form/TextArea.vue";
// import SelectSearch from "@/components/form/SelectSearch.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";

export default {
  components: {
    SelectDropdown,
    DateInput,
    TextArea,
    // SelectSearch,
    BaseButton,
    SubmitButton,
  },
  props: {
    taskId: {
      type: Number,
      default: null,
    },

    fieldId: {
      type: Number,
      required: true,
    },
    prepopulatedStatus: {
      type: String,
      default: "Ready",
    },
  },
  events: ["closeModal"],
  data() {
    const statusOptions = [
      "Recommended",
      "Requested",
      "Ready",
      "Assigned",
      "InProcess",
      "Complete",
      "Canceled",
    ];
    let taskTypes = [
      "Planting",
      "Tilling",
      "Irrigation",
      "Spraying",
      "Manual / Hand labor",
      "Hauling",
      "Scouting",
      "Observation",
      "Other field task",
      "Harvesting",
      //   // equipment tasks
      //   "Maintenance",
      //   "Repair",
      //   "Other equipment task",
    ];
    return {
      statusOptions,
      taskTypes,
      currentTask: null,
      allFields: this.$store.state.fields.fields,
      allUsers: this.$store.state.users.users,
      allEquipment: this.$store.state.equipment.equipment,
      formId: "newFieldForm",
    };
  },
  computed: {
    fieldList() {
      return this.allFields.map((field) => {
        return { label: field.properties.name, value: field.id };
      });
    },
    equipmentList() {
      return this.allEquipment.map((asset) => {
        return { label: asset.alias, value: asset.id };
      });
    },
    allPartners() {
      let partners = [];
      this.$store.state.orgs.orgs.forEach((partner) => {
        if (partner.feature_flags.find((ff) => ff === "vp-work-orders")) {
          partners.push(partner);
        }
      });
      return partners;
    },
    assignedUserId() {
      return this.currentTask.user;
    },
  },
  methods: {
    submitTaskForm() {
      if (this.taskId) {
        this.updateTask();
      } else {
        this.createTask();
      }
      this.$emit("closeModal");
    },
    cancelSave() {
      this.$emit("closeModal");
    },
    // Form Methods
    readTask() {
      this.currentTask = this.$store.getters.getTaskById(this.taskId);
      this.currentTask.deadline = this.currentTask.deadline?.substring(0, 10);
      // check if it is assigned to a partner and then set the user to the partner org id
      if (this.currentTask.assigned_org) {
        this.currentTask.user = "p" + this.currentTask.assigned_org;
      }
    },
    createTask() {
      this.currentTask.source = this.$store.getters.getCurrentUser.id;
      this.currentTask.status = "Ready";
      this.currentTask.field = this.fieldId;
      this.currentTask.deadline = this.currentTask.deadline + "T12:00:0.000Z";
      console.log("deadline", this.currentTask.deadline);
      if (this.currentTask.user === -1) {
        this.currentTask.user = null;
      }
      if (this.currentTask.user && this.currentTask.user[0] === "p") {
        this.currentTask.assigned_org = this.currentTask.user.substring(1);
        this.currentTask.user = null;
        this.$store.dispatch("createSharedTask", this.currentTask);
      } else {
        this.$store.dispatch("createTask", this.currentTask);
      }
    },
    updateTask() {
      this.currentTask.deadline = this.currentTask.deadline + "T12:00:0.000Z";
      console.log("deadline", this.currentTask.deadline);
      if (this.currentTask.user === -1) {
        this.currentTask.user = null;
      }
      if (this.currentTask.user && this.currentTask.user[0] === "p") {
        this.currentTask.assigned_org = parseInt(
          this.currentTask.user.substring(1),
        );
        // console.log("assigned org", this.currentTask.assigned_org);
        this.currentTask.user = null;
        this.$store.dispatch("updateSharedTask", {
          taskId: this.taskId,
          task: this.currentTask,
        });
      } else {
        this.currentTask.assigned_org = null;
        this.$store.dispatch("updateTask", {
          taskId: this.taskId,
          task: this.currentTask,
        });
      }
    },
    clearForm() {
      this.currentTask = {
        category: "", // string
        instructions: "", // string
        status: null, // string - enum
        user: null, // int
        source: null, // int
        field: null, // int
        deadline: null, // string - datetime
      };
      this.currentTask.status = this.prepopulatedStatus;
    },
  },
  watch: {
    taskId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        console.log("the fieldId param changed to ", value); //, " from ", oldValue);
        if (value) {
          this.readTask();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    assignedUserId: {
      handler(value) {
        if (
          (value != null && this.currentTask.status == "Ready") ||
          this.currentTask.status == "Recommended" ||
          this.currentTask.status == "Requested" ||
          this.currentTask.status == "Assigned"
        ) {
          if (value && value[0] == "p") {
            this.currentTask.status = "Requested";
          } else {
            this.currentTask.status = "Assigned";
          }
        }
      },
    },
  },
};
</script>
