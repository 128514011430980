import _ from "lodash";

/*
filter takes in an array of objects and a predicate of the form:

    {
        key: value,
        key: value,
        key: [value, value, value]
        ...
    },
   
Each key is treated as AND. if the value is an array, the values are treated as OR. 
for example, the predicate:
{name:'Bob', age: [10, 20]} will match any object with name Bob and age 10 or 20.
*/
export function filter(collection, predicate) {
  const single_predicate_values = _.omitBy(predicate, _.isArray);
  let result = _.filter(collection, single_predicate_values); // filter everything that isn't an array in one pass

  // only perform array checks if there are still results
  if (result.length > 0) {
    const multi_predicate_values = _.pickBy(predicate, _.isArray);

    const buildArrayFilter = (array, key) => (entry) =>
      _.includes(
        array, // includes will return true on first match in array
        _.get(entry, key), // get will not throw if key doesn't exist in entry
      );

    const combinedArrayFilters = _.map(
      multi_predicate_values,
      buildArrayFilter,
    );

    result = _.filter(result, _.overEvery(combinedArrayFilters)); // overEvery will exit early if any check fails
  }

  return result;
}

// Declare a flatten function that takes
// object as parameter and returns the flattened object
// example:
// {
//  farm: {
//    animal: {
//      name: 'cow',
//      sound: 'moo'
//    }
//   }
// }
// becomes
// {
//  'farm.animal.name': 'cow',
//  'farm.animal.sound': 'moo'
// }
// if includeOriginal is true, the original object is included in the result
// example:
// {
// farm: {
//    animal: {
//      name: 'cow',
//      sound: 'moo'
//    }
//  },
//  'farm.animal.name': 'cow',
//  'farm.animal.sound': 'moo'
// }
// TODO: Can we make this more efficient with _.flattenDeep?
export const flattenObj = (ob, includeOriginal) => {
  // The object which contains the
  // final result
  let result = {};
  if (includeOriginal) {
    result = ob;
  }
  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i recursively call the function again if it is a nested object
    if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        // Store temp in result
        result[i + "." + j] = temp[j];
      }
    }
    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }

  return result;
};

export function debounce(fn, wait) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    const context = this; // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, wait);
  };
}

// imports an image from a url and returns a file object that can be saved as an image
export async function dataUrlToFile(dataUrl, fileName) {
  let res;
  try {
    res = await fetch(dataUrl);
    console.log("result", dataUrl, res, res.headers.get("content-type"));
    const blob = await res.blob();
    return new File([blob], fileName, {
      type: res.headers.get("content-type"),
    });
  } catch (error) {
    console.log("There was an error fetching the file", error);
    return null;
  }
}

export function summarizePremium(p) {
  let premium = "";
  // console.log("p", p);
  switch (p.type) {
    case "fixed":
      if (p.total_or_unit == "unit") {
        premium = " $" + p.base_premium?.toFixed(2) + "/" + p.harvest_units;
      } else {
        premium = " $" + p.base_premium?.toFixed(2) + "  ";
      }
      break;
    case "range":
      premium = " $" + p.premium_per_point?.toFixed(2) + "/" + p.score_units;
      break;
    case "threshold":
      premium = " $" + p.base_premium?.toFixed(2) + "/" + p.score_units;
      break;
    case "other":
      premium = p.terms;
  }
  return premium;
}
export function parseSku(title, quantity) {
  let qty = null;
  let unit = null;
  let caseSize = null;
  if (
    title.toLowerCase().includes(" ga ") ||
    title.toLowerCase().includes(" gallon ")
  ) {
    unit = "gal";
  } else if (
    title.toLowerCase().includes(" lbs.") ||
    title.toLowerCase().includes(" pound ")
  ) {
    unit = "lbs";
  } else {
    unit = title.split(" ")[1]; // '10 oz package (Case 2) returns 'oz'
  }
  if (title.toLowerCase().includes(" case)")) {
    let caseStr = title.toLowerCase().split("(").pop().split("/ case)")[0]; // (2 / Case) returns '2'
    if (caseStr?.length > 0) {
      caseSize = parseInt(caseStr);
    }
  }
  //qty = title.replace(/(^\d+)(.+$)/i, '$1');
  qty = parseFloat(title);
  if (isNaN(qty)) qty = null;
  console.log("parseSku", title, qty, unit, caseSize);
  if (!qty || !unit) {
    return null;
  }
  let currentQuantity = null;
  if (quantity) {
    currentQuantity = qty * quantity;
    if (caseSize) {
      currentQuantity = currentQuantity * caseSize;
    }
    currentQuantity = currentQuantity.toFixed(2) + " " + unit;
  }
  return {
    qty: qty,
    unit: unit,
    caseSize: caseSize,
    displayText: currentQuantity,
  };
}
