<template>
  <page-card headingTitle="New Contract">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="newContractFormId"
      />
    </template>
    <template #cardDetails>
      <contract-form :formId="newContractFormId" />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import ContractForm from "@/layouts/buyer-portal/bp-controller/contracts/ContractForm.vue";

export default {
  components: {
    PageCard,
    SubmitButton,
    ContractForm,
  },

  data() {
    return {
      newContractFormId: "new-contract-form",
    };
  },
  computed: {},
  methods: {},
};
</script>
