<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton :class="buttonClass">
        {{ props.buttonText }}
        <ChevronDownIcon class="-mr-1 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 mt-1 h-96 w-56 origin-top-right overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1" v-for="action in props.actions" :key="action">
          <MenuItem v-slot="{ active }">
            <a
              @click="$emit('action', action.event)"
              href="#"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]"
              >{{ action.label }}</a
            >
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
// actions: [ {label: 'Edit', event: 'edit'}, {label: 'Delete', event: 'delete'} ]
import { computed } from "vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
const props = defineProps({
  buttonText: {
    type: String,
    required: true,
  },
  buttonType: {
    type: String,
    required: false,
    default: "other", // other, page-card, table-toolbar
  },
  actions: {
    type: Array,
    required: true,
  },
  redButton: { type: Boolean, default: false },
  marginLeft: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  dataTestid: { type: String, default: null },
});

const buttonClass = computed(() => {
  if (props.buttonType === "page-card") {
    let style =
      "inline-flex items-center rounded border border-transparent px-2.5 py-1 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2";
    style += " " + conditionalButtonStyling.value;
    return style;
  } else if (props.buttonType === "table-toolbar") {
    let style =
      "relative ml-1 inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100";
    return style;
  } else {
    let style =
      "relative ml-1 inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100";
    return style;
  }
});
const conditionalButtonStyling = computed(() => {
  let conditionalButtonStyling = "";
  if (!props.disabled && !props.redButton) {
    conditionalButtonStyling =
      "bg-twilight-700 hover:bg-twilight-800 focus:ring-twilight-500";
  } else if (!props.disabled && props.redButton) {
    conditionalButtonStyling = "bg-red-600 hover:bg-red-700 focus:ring-red-500";
  } else if (props.disabled) {
    conditionalButtonStyling = "bg-gray-200 hover:bg-gray-200";
  }
  if (props.marginLeft) {
    conditionalButtonStyling += " ml-1";
  }
  if (props.disabled) {
    conditionalButtonStyling += " bg-gray-200 hover:bg-gray-200";
  }
  return conditionalButtonStyling;
});
</script>
