<template>
  <alert-modal
    :open="modalIsActive"
    modalTitle="Field Shape Required"
    modalDescription="You must draw a shape to save a new field."
    @clickDismiss="dismissModal"
  />

  <form
    @submit.prevent="submitFieldForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Field Details
    </div>
    <text-input
      :wideItem="true"
      :required="true"
      inputTitle="Field Name"
      inputHtmlType="text"
      v-model="currentField.properties.name"
    />
    <variable-string-type-input
      :wideItem="true"
      inputTitle="Farm Name"
      inputHtmlType="text"
      v-model="currentField.properties.farm"
    />
    <div class="sm:col-span-3">
      <number-input
        inputTitle="Acres"
        :required="true"
        placeholder="Draw your field to calculate"
        v-model="currentField.properties.acres"
      />
    </div>
    <div class="col-span-3"></div>
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Crop Details
    </div>
    <select-dropdown
      v-if="currentCrop"
      :wideItem="true"
      inputTitle="Crop"
      :selectOptions="cropOptions"
      v-model="currentCrop.crop_type"
    />
    <component
      :is="cropType"
      :crop="this.currentCrop"
      @updateFieldDetails="updateFieldDetails"
      v-if="currentCrop?.crop_type"
    />
    <div class="sm:col-span-6"></div>
    <select-dropdown
      v-if="currentCrop"
      :wideItem="true"
      inputTitle="Planting Status"
      :selectOptions="statusOptions"
      v-model="currentCrop.details.planting_status"
      class="sm:-mt-8"
    />
    <CheckboxInput
      v-if="currentCrop"
      :wideItem="true"
      inputTitle="Irrigated"
      v-model="currentCrop.details.irrigated"
      class="sm:-mt-2"
    />
    <date-input
      :wideItem="true"
      inputTitle="From"
      helpText="Date the crop was planted, or crop year begins for planning and financial purposes."
      v-model="harvest_range_start"
    />
    <date-input
      :wideItem="true"
      inputTitle="To"
      helpText="Date the crop was harvested, or crop year ends for planning and financial purposes."
      v-model="harvest_range_end"
    />
  </form>
  <!-- <div class="border-t border-gray-200 px-4 py-5 sm:col-span-6 sm:px-6">
    <field-leaflet-map
      :fieldId="fieldHasGeometry ? parseInt(fieldId) : null"
      @edited="updateFieldShape"
      :key="fieldId"
      @location-added="markLocation"
      @removeMarker="deleteLocation"
    >
      <modal :show="showLocationModal" title="save a location">
        <select-dropdown
          v-model="markedLocationType"
          :selectOptions="['Entrance', 'Pick up']"
          :placeholder="'Select a location'"
          inputTitle="Location Type"
        />
        <base-button @click="saveLocation" buttonText="Save"></base-button>
        <base-button
          @click="showLocationModal = false"
          buttonText="Cancel"
          :redButton="true"
        ></base-button>
      </modal>
    </field-leaflet-map>
  </div> -->
</template>

<script>
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import DateInput from "@/components/form/DateInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import GrapeDetails from "@/layouts/grower-portal/assets/fields/crop-details/GrapeDetails.vue";
import SoybeanDetails from "@/layouts/grower-portal/assets/fields/crop-details/SoybeanDetails.vue";
import CornDetails from "@/layouts/grower-portal/assets/fields/crop-details/CornDetails.vue";
import WheatDetails from "@/layouts/grower-portal/assets/fields/crop-details/WheatDetails.vue";
import BarleyDetails from "@/layouts/grower-portal/assets/fields/crop-details/BarleyDetails.vue";
import HayDetails from "@/layouts/grower-portal/assets/fields/crop-details/HayDetails.vue";
import FruitTreeDetails from "@/layouts/grower-portal/assets/fields/crop-details/FruitTreeDetails.vue";
import DefaultDetails from "@/layouts/grower-portal/assets/fields/crop-details/DefaultDetails.vue";
// import FieldLeafletMap from "@/components/map/FieldEditMap.vue";
// import modal from "@/components/modals/PopupModal.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

import AlertModal from "@/components/modals/AlertModal.vue";

let cropList = require("@/assets/croplist.js");

Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0;
};

export default {
  props: {
    fieldId: {
      type: Number,
      default: null,
    },
    cropId: {
      type: Number,
      default: null,
    },
    // needed to allow a submit button outside the form tags to submit the form
    formId: {
      type: String,
      required: true,
    },
  },
  components: {
    VariableStringTypeInput,
    DateInput,
    CheckboxInput,
    BaseButton,
    // modal,
    NumberInput,
    SelectDropdown,
    GrapeDetails,
    SoybeanDetails,
    CornDetails,
    HayDetails,
    WheatDetails,
    BarleyDetails,

    FruitTreeDetails,
    DefaultDetails,
    TextInput,
    // FieldLeafletMap,
    AlertModal,
  },
  data() {
    return {
      listOfCropOptionObjects: cropList,
      currentField: null,
      currentCrop: {
        details: {},
      },
      modalIsActive: false,
      // showLocationModal: false,
      markedLocationPoint: null,
      markedLocationType: null,
      harvest_range_start: null,
      harvest_range_end: null,
      cropModalIsActive: false,
      copyCropDetails: false,
    };
  },
  computed: {
    cropOptions() {
      let options = [];
      for (let crop of this.listOfCropOptionObjects) {
        options.push(crop.name);
      }
      return options;
    },
    statusOptions() {
      return [
        { label: "Initital", value: "I" },
        { label: "Prevented", value: "P" },
        { label: "Failed", value: "F" },
        { label: "Subsequent Crop", value: "S" },
        { label: "Double Crop", value: "D" },
        { label: "Repeat", value: "R" },
        { label: "Volunteer", value: "V" },
        { label: "Experimental", value: "E" },
        { label: "Initial Failed", value: "IF" },
        { label: "Initial Prevented", value: "IP" },
        { label: "Subsequent Failed", value: "SF" },
        { label: "Double-cropped Failed", value: "DF" },
        { label: "Double-cropped Prevented", value: "DP" },
      ];
    },
    cropType() {
      switch (this.currentCrop?.crop_type) {
        case "Grape":
          return "GrapeDetails";
        case "Soybeans":
          return "SoybeanDetails";
        case "Corn":
          return "CornDetails";
        case "Apple":
        case "Apricot":
        case "Cherry":
        case "Pear":
        case "Nectarine":
          return "FruitTreeDetails";
        case "Hay":
        case "Alfalfa":
          return "HayDetails";
        case "Wheat":
          return "WheatDetails";
        case "Barley":
          return "BarleyDetails";
        default:
          return "DefaultDetails";
      }
    },
    fieldHasGeometry() {
      if (this.currentField.geometry?.coordinates?.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    submitFieldForm() {
      if (this.fieldId) {
        this.updateField();
      } else {
        this.createField();
      }
    },
    updateFieldDetails(e) {
      this.currentCrop.details = e;
    },
    updateFieldShape(feature, acres) {
      // console.log("update that shape!")
      // console.log("New vals", feature, acres)
      this.currentField.properties.acres = acres;
      this.currentField.geometry = feature.geometry;
      // console.log(this.currentField)
    },
    formatHarvestRange(crop) {
      if (this.harvest_range_start) {
        let date = new Date(this.harvest_range_start);
        // const offset = date.getTimezoneOffset();
        // date = new Date(date.getTime() - offset * 60 * 1000);
        crop.harvest_range_start = date.toISOString().split("T")[0];
      } else {
        delete crop.harvest_range_start;
      }
      if (this.harvest_range_end) {
        let date = new Date(this.harvest_range_end);
        // const offset = date.getTimezoneOffset();
        // date = new Date(date.getTime() - offset * 60 * 1000);
        crop.harvest_range_end = date.toISOString().split("T")[0];
      } else {
        delete crop.harvest_range_end;
      }
      return crop;
    },
    // Form Methods
    async readField() {
      this.currentField = this.$store.getters.getFieldById(this.fieldId);
      if (this.cropId) {
        this.currentCrop = this.$store.getters.getAnyCropById(this.cropId);
        // console.log("current crop from crop", this.currentCrop, this.cropId);
      } else {
        this.currentCrop = this.$store.getters.getAnyCropById(
          this.currentField.properties.current_crop.id,
        );
        // console.log("current crop from field", this.currentField);
      }
      if (
        this.currentField?.properties?.acres &&
        this.currentField.properties.acres.countDecimals() > 2
      ) {
        this.currentField.properties.acres = parseFloat(
          this.currentField.properties.acres.toFixed(2),
        );
      }
      if (this.currentCrop?.harvest_range_start)
        this.harvest_range_start = this.currentCrop.harvest_range_start;
      else this.harvest_range_start = null;

      if (this.currentCrop?.harvest_range_end)
        this.harvest_range_end = this.currentCrop.harvest_range_end;
      else this.harvest_range_end = null;
      console.log(
        "currentField post date",
        this.currentField,
        this.currentCrop,
      );
    },
    async createField() {
      if (this.fieldHasGeometry) {
        this.currentCrop = this.formatHarvestRange(this.currentCrop);
        // this.currentField.properties.current_crop = this.currentCrop;
        this.currentField.properties.crop = this.currentCrop.crop_type;
        this.currentField.properties.details = this.currentCrop.details;
        // NOTE: this assumes if we are creating a field, we are also creating the current crop
        this.$store.dispatch("createField", this.currentField);
        if (!this.$store.getters.getApiError) {
          this.$router.push({ name: "fields" });
        }
      } else {
        this.displayNoGeomModal();
      }
    },
    updateField() {
      if (this.fieldHasGeometry) {
        console.log("getting ready to updateField", this.currentField);
        this.currentCrop = this.formatHarvestRange(this.currentCrop);
        this.currentField.properties.crop = this.currentCrop.crop_type;
        this.currentField.properties.details = this.currentCrop.details;
        this.$store.dispatch("updateField", this.currentField);
        // NOTE: this may update a different crop year than the current field. User could make changes to both field attributes
        // and crop attributes and save them together.
        // console.log("current crop save", this.currentCrop);
        this.$store.dispatch("updateCrop", this.currentCrop);
        if (this.$route.query.order) {
          this.$router.push({
            name: "grower-portal-produce-order",
            params: { orderId: this.$route.query.order },
          });
        } else {
          this.$router.push({ name: "fields" });
        }
      } else {
        this.displayNoGeomModal();
      }
    },
    clearForm() {
      this.currentField = {
        type: "Feature",
        geometry: {}, // object
        properties: {
          farm: null, // string
          name: null, // string
          acres: null, // int
          owner: null, // int
          crop: null, // string
          details: {}, // object
        },
      };
    },

    displayNoGeomModal() {
      this.modalIsActive = true;
    },
    dismissModal() {
      this.modalIsActive = false;
    },
  },
  watch: {
    fieldId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value); //, " from ", oldValue)
        if (value) {
          this.readField();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    cropId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        // console.log("the fieldId param changed to ", value, " from ", oldValue);
        if (this.cropId) {
          this.readField();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
