<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Remove Assignment"
    modalDescription="Are you sure you want to permanently remove this assignment?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="delistAssignment"
  />
  <page-card headingTitle="Edit Assignment">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="editAssignmentFormId"
      />
      <base-button
        buttonText="Remove"
        :marginLeft="false"
        :formId="editAssignmentFormId"
        @click="modalIsActive = true"
        :redButton="true"
        class="ml-2"
      />
    </template>
    <template #cardDetails>
      <assignment-form
        :assignmentId="assignmentId"
        :formId="editAssignmentFormId"
      />
      <div
        class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
      >
        Assignment Summary
      </div>
      <div class="col-span-6 -ml-6">
        <controller-offer-details
          :offerId="assignmentId"
          :hideContractSummary="true"
        />
      </div>
      <controller-offer-form :offerId="assignmentId" formId="viewOfferForm" />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import AssignmentForm from "@/layouts/buyer-portal/bp-controller/assignments/AssignmentForm.vue";
import ControllerOfferDetails from "@/layouts/buyer-portal/bp-controller/offers/ControllerOfferDetails.vue";
import ControllerOfferForm from "@/layouts/buyer-portal/bp-controller/offers/ControllerOfferForm.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";

export default {
  components: {
    PageCard,
    SubmitButton,
    BaseButton,
    AssignmentForm,
    ControllerOfferDetails,
    DeleteModal,
    ControllerOfferForm,
  },
  data() {
    return {
      editAssignmentFormId: "new-offer-form",
      modalIsActive: false,
    };
  },
  computed: {
    assignmentId() {
      return this.$route.params.assignmentId;
    },
  },
  methods: {
    delistAssignment() {
      this.$store.dispatch("deleteControllerMarketOffer", {
        id: this.assignmentId,
      });
      this.$router.push({ name: "buyer-assignments" });
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
  },
};
</script>
