<template>
  <TabGroup as="div">
    <div class="border-b border-gray-200 print:hidden">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Carbon Intensity Report
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Explanation
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Edit Scenario
          </button>
        </Tab>
        <!-- <Tab as="template" v-slot="{ selected }">
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Suggestions
            </button>
          </Tab> -->
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <carbon-scenario-report :scenarioId="scenarioId" />
      </TabPanel>
      <TabPanel as="dl" class="pt-10">
        <carbon-scenario-report-help :scenarioId="scenarioId" />
      </TabPanel>
      <TabPanel class="pt-10">
        <carbon-scenario-form
          onSubmitNavigationRouteName="carbon"
          :scenarioId="parseInt(scenarioId)"
          v-if="!singleCrop"
        />
        <carbon-scenario-form-single-crop
          onSubmitNavigationRouteName="carbon"
          :scenarioId="parseInt(scenarioId)"
          v-else
        />
      </TabPanel>
      <!-- <TabPanel class="pt-10">
          <p>Suggestions</p>
        </TabPanel> -->
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import CarbonScenarioForm from "@/layouts/common/carbon/CarbonScenarioForm.vue";
import CarbonScenarioFormSingleCrop from "@/layouts/common/carbon/CarbonScenarioFormSingleCrop.vue";
import CarbonScenarioReport from "@/layouts/common/carbon/CarbonScenarioReport.vue";
import CarbonScenarioReportHelp from "@/layouts/common/carbon/CarbonScenarioReportHelp.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    CarbonScenarioForm,
    CarbonScenarioFormSingleCrop,
    CarbonScenarioReport,
    CarbonScenarioReportHelp,
  },

  data() {
    return {};
  },
  computed: {
    scenarioId() {
      return this.$route.params.scenarioId;
    },
    singleCrop() {
      let scenario = this.$store.getters.getScenarioById(this.scenarioId);
      let singleCrop = false;
      if (scenario?.metadata?.crops.length == 1) {
        singleCrop = true;
      }
      return singleCrop;
    },
  },
  methods: {},
};
</script>
