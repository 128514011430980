<template>
  <base-button
    :buttonText="'Add Premium'"
    :marginLeft="false"
    @buttonClick="createPremium()"
  />
  <page-card
    v-for="(premium, index) in premiums"
    :key="premium"
    :heading-title="'Premium ' + (index + 1)"
    :inserted="true"
  >
    <template #buttonArea>
      <base-button
        buttonText="Delete"
        :redButton="true"
        :marginLeft="true"
        @buttonClick="deletePremium(index)"
      />
    </template>
    <template #cardDetails>
      <div
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <select-search
          :wideItem="true"
          inputTitle="Premium Type"
          inputHtmlType="text"
          v-model="premium.type"
          :selectOptions="premiumTypes"
        />
        <text-area
          :wideItem="true"
          inputTitle="Terms"
          inputHtmlType="text"
          v-model="premium.terms"
          help-text="The terms to be paid this premium. E.g. 'At Harvest', 'At End of Season', 'As measured on delivery' etc."
        />
        <!-- Inputs for Non-Other Type Premiums -->
        <select-search
          v-if="premium.type != 'other'"
          :wideItem="true"
          inputTitle="Lump Total Sum or Per Unit Premium?"
          inputHtmlType="text"
          v-model="premium.total_or_unit"
          :selectOptions="totalOrUnitTypes"
          help-text="Total means the premium is paid once, Unit means the premium is paid per unit"
          @change="defaultHarvestUnits"
        />
        <select-search
          v-if="premium.type != 'other' && premium.total_or_unit != 'total'"
          :wideItem="true"
          inputTitle="Units"
          inputHtmlType="text"
          v-model="premium.harvest_units"
          :select-options="unitOptions"
        />
        <div class="col-span-2" v-else></div>
        <!-- Inputs for Fixed & Threshold Premium -->
        <CurrencyInput
          v-if="premium.type === 'fixed' || premium.type === 'threshold'"
          :wideItem="true"
          inputTitle="Premium"
          inputHtmlType="text"
          v-model="premium.base_premium"
          help-text="That value that will be paid per unit or as a lump sum if the threshold is met"
        />
        <!-- Inputs for Threshold Premium -->
        <number-input
          v-if="premium.type === 'threshold'"
          :wideItem="true"
          inputTitle="Threshold Score"
          inputHtmlType="text"
          v-model="premium.score_target"
          help-text="The score that must be met to receive the premium"
        />
        <!-- Inputs for Threshold & Range Premium -->
        <select-search
          v-if="premium.type === 'threshold' || premium.type === 'range'"
          :wideItem="true"
          inputTitle="Score Preferred"
          v-model="premium.score_preference"
          :selectOptions="scorePreferenceTypes"
          help-text="lower means you prefer a lower score, higher means you  prefer a higher score"
        />
        <text-input
          v-if="premium.type === 'threshold' || premium.type === 'range'"
          :wideItem="true"
          inputTitle="Score Units (CI Score, Grams Carbon, etc)"
          inputHtmlType="text"
          v-model="premium.score_units"
          help-text="The units of the score, e.g. CI Score, Grams Carbon, Brix, etc"
        />
        <!-- Inputs for Range Premium -->
        <number-input
          v-if="premium.type === 'range'"
          :wideItem="true"
          inputTitle="Lower Bound"
          inputHtmlType="text"
          v-model="premium.score_lower_bound"
          help-text="The lower bound of the range"
        />
        <number-input
          v-if="premium.type === 'range'"
          :wideItem="true"
          inputTitle="Upper Bound"
          inputHtmlType="text"
          v-model="premium.score_upper_bound"
          help-text="The upper bound of the range"
        />
        <currency-input
          v-if="premium.type === 'range'"
          :wideItem="true"
          inputTitle="Premium per Point"
          inputHtmlType="text"
          v-model="premium.premium_per_point"
          help-text="The premium paid per point past the lower bound"
        />
      </div>
    </template>
  </page-card>
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PageCard from "@/components/cards/PageCard.vue";

export default {
  props: {
    premiums: {
      type: Array,
      default: null,
    },
    unitOptions: {
      type: Array,
      default: () => ["acres"],
    },
  },
  emits: ["updatePremiums"],
  components: {
    TextInput,
    CurrencyInput,
    TextArea,
    NumberInput,
    // SelectDropdown,
    BaseButton,
    PageCard,
    SelectSearch,
  },
  data() {
    return {
      // temp until build multiple premium support
      premiumTypes: [
        {
          value: "fixed",
          label: "Fixed",
        },
        {
          value: "threshold",
          label: "Threshold",
        },
        {
          value: "range",
          label: "Range",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
      totalOrUnitTypes: [
        {
          value: "total",
          label: "Total",
        },
        {
          value: "unit",
          label: "Unit",
        },
      ],
      scorePreferenceTypes: [
        {
          value: "higher",
          label: "Higher",
        },
        {
          value: "lower",
          label: "Lower",
        },
      ],
      currentPremiums: this.premiums
        ? this.premiums
        : [
            {
              type: "fixed",
              terms: "",
              total_or_unit: "total",
              harvest_units: "",
              base_premium: null,
            },
          ],
      // premium.type: "fixed", // Enum ("fixed", "threshold", "range", "other"), All Types
      // terms: "", // String, All Types
      // total_or_unit: "total", // Enum ("total" "unit"), Non-Other Premium Types
      // harvest_units: "", // String, Non-Other Premium Types
      // base_premium: null, // Number, Fixed or Threshold Premium Types
      // score_target: null, // Number, Threshold Premium Type
      // score_preference: "lower", // Enum ("higher" or "lower"), Threshold Premium Type
      // score_units: "", // String, Threshold or Range Premium Types
      // score_lower_bound: null, // Number, Range Premium Type
      // score_upper_bound: null, // Number, Range Premium Type
      // premium_per_point: null, // Number, Range Premium Type
    };
  },
  computed: {},
  methods: {
    createPremium() {
      this.currentPremiums.push({
        type: "fixed",
        terms: "",
        total_or_unit: "total",
        harvest_units: "",
        base_premium: null,
      });
    },
    deletePremium(index) {
      this.currentPremiums.splice(index, 1);
    },
    defaultHarvestUnits() {
      if (this.total_or_unit === "total") {
        this.harvest_units = "";
      }
      if (this.total_or_unit === "unit" && this.harvest_units === "") {
        this.harvest_units = this.contract?.quantity_units;
      }
    },
  },
  watch: {
    currentPremiums: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        this.$emit("updatePremiums", value);
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>
