<template>
  <delete-modal
    :show="showDeleteModal"
    modalDescription="Are you absolutely sure you want to delete this crop? This will also remove all records associated with this crop and cannot be undone."
    @close="showDeleteModal = false"
    @clickCancel="showDeleteModal = false"
    @clickConfirmDelete="deleteCrop"
  />
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'crop_type', label: 'Crop' },
          { name: 'details.variety', label: 'Variety' },
          { name: 'harvest_range_start', label: 'From', filtered: false },
          { name: 'harvest_range_end', label: 'To', filtered: false },
          { name: 'edit', label: '', filtered: false },
          { name: 'delete', label: '', filtered: false },
          { name: 'active', label: '', filtered: false },
        ]"
        :collection="crops"
        @update:derived-collection="derivedCrops = $event"
        :defaultFilter="defaultFilter"
        :defaultSort="defaultSort"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="crop in derivedCrops" :key="crop.id">
        <table-data td-label="Crop">
          {{ crop?.crop_type }}
        </table-data>
        <table-data td-label="Variety">
          {{ crop?.details?.variety ? crop?.details?.variety : "" }}
        </table-data>
        <table-data td-label="From">
          {{ crop.harvest_range_start }}
        </table-data>
        <table-data td-label="To">
          {{ crop.harvest_range_end }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'single-field',
              params: { fieldId: crop.fieldId },
              query: { cropId: crop.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 print:hidden"
          >
            Edit
          </router-link>
        </table-data>
        <table-data class="text-center">
          <button
            class="font-medium text-violet-500 hover:text-twilight-900 print:hidden"
            v-if="!crop.active"
            @click="
              selectedCropId = crop.id;
              showDeleteModal = true;
            "
          >
            Delete
          </button>
        </table-data>
        <table-data class="text-center">
          <button
            class="font-medium text-violet-500 hover:text-twilight-900 print:hidden"
            v-if="!crop.active"
            @click="makeActive(crop.id)"
          >
            Set Current
          </button>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    DeleteModal,
  },
  props: {
    fieldId: {
      type: Number,
      required: false,
      default: null,
    },
    cropId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    // let filterObject = this.$store.getters.getCropsFilter;
    return {
      // defaultFilter: filterObject,
      defaultSort: { harvest_range_start: "desc" },
      defaultFilter: {},
      searchValue: "",
      derivedCrops: [],
      crops: [],
      showDeleteModal: false,
      selectedCropId: null,
    };
  },
  computed: {},
  methods: {
    async readCropHistory() {
      let crops = this.$store.getters.getExpandedCrops({
        filter: { fieldId: this.fieldId },
      });
      let cropHistory = this.$store.getters.getExpandedCropHistory({
        filter: { fieldId: this.fieldId },
      });
      console.log("crops", crops, cropHistory, this.fieldId);
      this.crops = crops.concat(cropHistory);
      // now don't show the currently displayed crop
      this.crops = this.crops.filter((crop) => crop.id !== this.cropId);
    },
    async deleteCrop() {
      console.log("deleteCrop", this.selectedCropId);
      if (this.selectedCropId) {
        await this.$store.dispatch("deleteCrop", {
          id: this.selectedCropId,
        });
        await this.$store.dispatch("getCropHistory");
        this.readCropHistory();
      }
      this.showDeleteModal = false;
    },
    async makeActive(cropId) {
      console.log("makeActive", cropId);
      let crop = this.crops.find((crop) => crop.id === cropId);
      if (crop) {
        crop.active = true;
        await this.$store.dispatch("updateCrop", crop);
        await this.$store.dispatch("getCropHistory");
        this.readCropHistory();
      }
    },
  },
  watch: {
    fieldId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readCropHistory();
        }
      },
      immediate: true,
    },
    cropId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readCropHistory();
        }
      },
      immediate: true,
    },
  },
};
</script>
