<template>
  <styled-input
    type="number"
    :inputTitle="inputTitle"
    :required="required"
    :displayOnly="displayOnly"
    :wideItem="wideItem"
    :placeholder="placeholder"
    :classProp="classProp"
    :step="step"
    :helpText="helpText"
    :helpTitle="helpTitle"
    @onBlur="checkValidInput"
    v-model="inputValue"
  />
</template>

<script>
import StyledInput from "./StyledInput.vue";
Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0;
};
export default {
  components: {
    StyledInput,
  },
  props: {
    inputTitle: { type: String },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: [Number, String] },
    required: { type: Boolean, default: false },
    modelValue: {},
    placeholder: { type: String, default: null },
    classProp: { type: String },
    wideItem: { type: Boolean, default: false },
    step: { type: Number, default: 0.01 },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  emits: ["update:modelValue"],
  data() {
    // This section is just to be backward compatible when displayOnlyValue was how the value was passed in for read only.
    let inputValue = this.modelValue;
    if (this.displayOnly) inputValue = this.displayOnlyValue;
    //
    return {
      inputValue,
    };
  },
  methods: {
    checkValidInput(value) {
      if (value) {
        let stepDecimals = this.step.countDecimals();
        let valueDecimals = value.countDecimals();
        // console.log("check number input", value, stepDecimals, valueDecimals);
        if (stepDecimals < valueDecimals) {
          this.inputValue = value.toFixed(stepDecimals);
        }
      } else this.inputValue = 0;
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", parseFloat(value));
    },
    modelValue(value) {
      this.inputValue = value;
    },
    displayOnly() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
    displayOnlyValue() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
  },
};
</script>
