import client from "@/api/parcel/api.js";
import { universalGetter } from "@/components/composables/universalGetter";

export default {
  state() {
    return {
      crops: [],
      cropHistory: [],
    };
  },
  mutations: {
    SET_CROPS(state, cropData) {
      state.crops = cropData;
    },
    SET_CROP_HISTORY(state, cropData) {
      state.cropHistory = cropData;
    },
    ADD_CROP(state, cropObject) {
      state.crops.push(cropObject);
    },
    UPDATE_CROP(state, cropObject) {
      state.crops = state.crops.filter(function (obj) {
        return obj.id !== cropObject.id;
      });
      state.crops.push(cropObject);
    },
    DELETE_CROP(state, cropObject) {
      state.crops = state.crops.filter(function (obj) {
        return obj.id !== cropObject.id;
      });
    },
    RESET_CROPS(state) {
      state.crops = [];
      state.bounds = [];
    },
  },
  actions: {
    async getCrops({ commit }) {
      const { data, errors } = await client.crops.read(true);
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_CROPS", data.crops);
      }
    },
    async getCropHistory({ commit }) {
      const { data, errors } = await client.crops.read(false);
      if (errors) {
        console.log(errors);
      } else {
        commit("SET_CROP_HISTORY", data.crops);
      }
    },
    async createCrop({ commit, dispatch }, cropObject) {
      console.log(
        "create crop",
        cropObject.fieldId,
        cropObject.crop,
        commit,
        dispatch,
      );
      const { data, errors } = await client.crops.create(
        cropObject.fieldId,
        cropObject.crop,
      );
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        console.log(data);
        commit("ADD_CROP", data);
        this.dispatch("getCrops");
        this.dispatch("getCropHistory");
        return data;
      }
    },
    async updateCrop({ commit, dispatch }, crop) {
      const { data, errors } = await client.crops.update(crop.id, crop);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors);
      } else {
        // console.log(data);
        commit("UPDATE_CROP", data);
        this.dispatch("getCrops");
      }
    },
    async deleteCrop({ commit, dispatch }, crop) {
      const { data, errors } = await client.crops.delete(crop.id);
      if (errors) {
        console.log(errors);
        dispatch("catchParcelApiError", errors, data);
      } else {
        // console.log(data);
        commit("DELETE_CROP", crop);
        this.dispatch("getCrops");
      }
    },
  },
  getters: {
    getCropById: (state) => (cropId) => {
      return state.crops.find((crop) => crop.id === parseInt(cropId));
    },
    getCropHistoryById: (state) => (cropId) => {
      return state.cropHistory.find((crop) => crop.id === parseInt(cropId));
    },
    getAnyCropById: (state) => (cropId) => {
      // finds crop in active or crop history
      let allCrops = state.crops.concat(state.cropHistory);
      return allCrops.find((crop) => crop.id === parseInt(cropId));
    },
    getAnyCropByFieldId: (state) => (fieldId) => {
      // finds crop in active or crop history
      let allCrops = state.crops.concat(state.cropHistory);
      return allCrops.find((crop) => crop.field === parseInt(fieldId));
    },
    getCropByFieldId: (state) => (fieldId) => {
      return state.crops.find((crop) => crop.field === parseInt(fieldId));
    },
    getCropByType: (state) => (cropType) => {
      return state.crops.find((crop) => crop.crop_type === cropType);
    },
    getNonAllocatedCropAcres: (state) => (cropId) => {
      // get sum of all allocation acres attached to this cropID
      // sumtract the sum from this crop's acres
      // return the value
      return state.growerAllocations.filter(
        (allocation) => allocation.org === parseInt(cropId),
      );
    },
    getCrops:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let crops = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.crops,
        );
        return crops;
      },
    getExpandedCrops:
      (state, getters, rootState) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let crops = state.crops;
        let growerAllocations = rootState.growerAllocations.growerAllocations;
        let fields = rootState.fields.fields;
        let expandedCrops = crops.map((crop) => {
          let cropField = fields.find((field) => field.id === crop.field);
          let farm = cropField?.properties.farm;
          let field = cropField?.properties.name;
          let fieldId = cropField?.id;
          let acres = cropField?.properties.acres;
          let cropAllocations = growerAllocations.filter(
            (allocation) => allocation.crop === crop.id,
          );
          let sum = cropAllocations.reduce((accumulator, object) => {
            return accumulator + object.acres;
          }, 0);
          let isAllocated = sum > 0 ? true : false;
          let acresAllocated = sum;
          let remainingAcres = acres - sum;
          return {
            ...crop,
            isAllocated,
            acresAllocated,
            remainingAcres,
            farm,
            field,
            fieldId,
            acres,
          };
        });
        let finalCrops = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          expandedCrops,
        );
        // console.log("expanded crops", finalCrops);
        return finalCrops;
      },

    getExpandedCropHistory:
      (state, getters, rootState) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let crops = state.cropHistory;
        console.log("crop History", crops, filter);
        let growerAllocations = rootState.growerAllocations.growerAllocations;
        let fields = rootState.fields.fields;
        let expandedCrops = crops.map((crop) => {
          let cropField = fields.find((field) => field.id === crop.field);
          let farm = cropField?.properties.farm;
          let field = cropField?.properties.name;
          let fieldId = cropField?.id;
          let acres = cropField?.properties.acres;
          let cropAllocations = growerAllocations.filter(
            (allocation) => allocation.crop === crop.id,
          );
          let sum = cropAllocations.reduce((accumulator, object) => {
            return accumulator + object.acres;
          }, 0);
          let isAllocated = sum > 0 ? true : false;
          let acresAllocated = sum;
          let remainingAcres = acres - sum;
          return {
            ...crop,
            isAllocated,
            acresAllocated,
            remainingAcres,
            farm,
            field,
            fieldId,
            acres,
          };
        });
        let finalCrops = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          expandedCrops,
        );
        // console.log("expanded crops", finalCrops);
        return finalCrops;
      },
  },
};
