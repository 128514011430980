<template>
  <form
    @submit.prevent="submitCropInquiryForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <text-input
      v-if="currentOrg && currentOrgType === 'buyer'"
      :wideItem="true"
      inputTitle="Seller"
      :display-only="true"
      :display-only-value="currentOrder?.seller_details?.name"
    />
    <text-input
      v-if="currentOrg && currentOrgType === 'grower'"
      :wideItem="true"
      inputTitle="Buyer"
      :display-only="true"
      :display-only-value="currentOrder?.buyer_details?.name"
    />
    <div class="sm:col-span-3">
      <!-- <router-link
        v-if="currentOrderFullListing.published"
        :to="{
          name:
            currentOrgType === 'grower'
              ? 'grower-portal-produce-listing'
              : 'buyer-produce-listings-single',
          params: { listingId: currentOrder.listing },
        }"
        class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
      >
        View Public Listing
      </router-link>
      <router-link
        v-if="currentOrder.offer"
        :to="{
          name:
            currentOrgType === 'grower'
              ? 'grower-portal-market-offer-single'
              : 'buyer-market-offer-single',
          params: { offerId: currentOrder.offer },
        }"
        class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
      >
        View Public Offer
      </router-link> -->
    </div>
    <number-input
      v-if="currentOrder"
      :wideItem="true"
      inputTitle="Quantity"
      :required="true"
      :display-only="currentOrgType === 'buyer' ? true : false"
      :display-only-value="currentOrder?.quantity"
      v-model="currentOrder.quantity"
    />
    <template v-if="currentOrder">
      <!-- <text-input
        :wideItem="true"
        inputTitle="Units"
        :required="true"
        :display-only="unitsReadOnly"
        :display-only-value="currentOrder?.units"
        :selectOptions="unitsOptions"
        v-model="currentOrder.units"
        v-if="currentOrder.units" /> -->
      <text-input
        :wideItem="true"
        inputTitle="Units"
        :required="true"
        :display-only="unitsReadOnly"
        :display-only-value="currentOrder?.units"
        :selectOptions="unitsOptions"
        v-model="currentOrder.units"
        v-if="currentOrder.units && currentOrgType === 'grower'"
      />
      <!-- <select-dropdown
        v-else
        :wideItem="true"
        inputTitle="Units"
        :required="true"
        :display-only="currentOrgType === 'buyer' ? true : false"
        :display-only-value="currentOrder?.units"
        :selectOptions="unitsOptions"
        v-model="currentOrder.units"
    /> -->
      <select-dropdown
        v-if="currentOrder.units && currentOrgType === 'buyer'"
        :wideItem="true"
        inputTitle="Units"
        :required="true"
        :display-only="currentOrgType === 'buyer' ? true : false"
        :display-only-value="currentOrder?.units"
        :selectOptions="unitsOptions"
        v-model="currentOrder.units"
      />
    </template>

    <div class="sm:col-span-6">
      <text-area
        v-if="currentOrder"
        inputTitle="Terms"
        :display-only="currentOrgType === 'buyer' ? true : false"
        :display-only-value="currentOrder?.terms"
        v-model="currentOrder.terms"
      />
    </div>
    <div class="sm:col-span-3">
      <action-panel-card title="Grower Acceptance">
        <template #buttonArea>
          <base-button
            v-if="
              currentOrgType === 'grower' &&
              currentOrder &&
              !currentOrder.grower_signature
            "
            buttonText="Accept"
            @buttonClick="growerAcceptOrder()"
          />
          <base-button
            v-else-if="currentOrgType === 'grower'"
            buttonText="Accepted"
            :disabled="true"
          />
          <base-button
            v-else
            :buttonText="
              currentOrder
                ? currentOrder?.grower_signature
                  ? 'Grower Accepted'
                  : 'Grower Acceptance Pending'
                : ''
            "
            class="text-gray-900"
            :disabled="true"
          />
        </template>
      </action-panel-card>
    </div>
    <div class="sm:col-span-3">
      <action-panel-card title="Buyer Acceptance">
        <template #buttonArea v-if="currentOrder">
          <base-button
            v-if="
              currentOrgType === 'buyer' &&
              currentOrder &&
              !currentOrder.buyer_signature
            "
            buttonText="Accept"
            @buttonClick="buyerAcceptOrder()"
          />
          <base-button
            v-else-if="currentOrgType === 'buyer'"
            buttonText="Accepted"
            :disabled="true"
          />
          <base-button
            v-else
            :buttonText="
              currentOrder?.buyer_signature
                ? 'Buyer Accepted'
                : 'Buyer Acceptance Pending'
            "
            class="text-gray-900"
            :disabled="true"
          />
        </template>
      </action-panel-card>
    </div>
  </form>
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import ActionPanelCard from "@/components/cards/ActionPanelCard.vue";

export default {
  props: {
    orderId: {
      type: Number,
      default: null,
    },
    // needed to allow a submit button outside the form tags to submit the form
    formId: {
      type: String,
      required: true,
    },
  },
  components: {
    TextInput,
    NumberInput,
    SelectDropdown,
    TextArea,
    BaseButton,
    ActionPanelCard,
  },
  emits: ["formSaved"],
  data() {
    const unitsOptions = ["Tons", "Acres", "Bushels"];
    return {
      currentOrder: {},
      unitsOptions,
      formFixedFilters: { self: false },
      formFixedSorts: ["name"],
    };
  },
  computed: {
    currentOrg() {
      // returns "buyer", "vendor", "grower";
      return this.$store.getters.getCurrentOrg;
    },
    currentOrgType() {
      // returns "buyer", "vendor", "grower";
      return this.$store.getters.getCurrentOrgType;
    },
    cropInquiryForSubmission() {
      let submission = {};
      submission.id = this.currentOrder.id;
      submission.quantity = this.currentOrder.quantity;
      submission.units = this.currentOrder.units;
      submission.terms = this.currentOrder.terms;
      submission.details = this.currentOrder.details;
      submission.estimated_price = this.currentOrder.estimated_price;
      return submission;
    },
    unitsReadOnly() {
      let readonly = this.currentOrgType === "buyer" ? true : false;
      if (this.currentOrder?.offer) {
        readonly = true;
      }
      return readonly;
    },
    readyForReadOrder() {
      if (
        this.currentOrgType === "grower" &&
        this.$store.getters.getGrowerCropOrderById(this.orderId)
      ) {
        return true;
      } else if (
        this.currentOrgType === "buyer" &&
        this.$store.getters.getBuyerOrderById(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    // currentOrderFullListing() {
    //   if (this.currentOrder.listing) {
    //     return this.$store.getters.getGrowerListingById(
    //       this.currentOrder.listing
    //     );
    //   } else {
    //     return null;
    //   }
    // },
  },
  methods: {
    submitCropInquiryForm() {
      if (this.currentOrgType === "grower") {
        this.updateOrder();
        if (!this.$store.getters.getApiError) {
          this.$router.push({ name: "grower-portal-produce-orders" });
        }
      }
    },
    // Form Methods
    readOrder() {
      if (this.currentOrgType === "grower") {
        this.currentOrder = this.$store.getters.getGrowerCropOrderById(
          this.orderId,
        );
      } else if (this.currentOrgType === "buyer") {
        this.currentOrder = this.$store.getters.getBuyerOrderById(this.orderId);
      }
    },
    updateOrder() {
      this.$store.dispatch(
        "updateGrowerCropOrder",
        this.cropInquiryForSubmission,
      );
      this.$emit("formSaved", this.currentOrder.id);
    },
    async buyerAcceptOrder() {
      console.log("buyerAcceptOrder");
      await this.$store.dispatch("updateBuyerCropOrder", {
        id: this.currentOrder.id,
        buyer_signature: true,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "buyer-inquiries" });
      }
    },
    async growerAcceptOrder() {
      await this.$store.dispatch("updateGrowerCropOrder", {
        id: this.currentOrder.id,
        grower_signature: true,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-inquiries" });
      }
    },
  },
  watch: {
    orderId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        this.readOrder();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    readyForReadOrder: {
      handler(value) {
        if (value && this.orderId && this.currentOrg) {
          this.readOrder();
        }
      },
      immediate: true,
    },
  },
};
</script>
