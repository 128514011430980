import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";

// --- Imports ---
//Vue Router
import router from "./router.js";

// Vuex
import store from "./store/index.js";

// UI components for global use
// Layout
import TwoColumn from "./layouts/common/TwoColumn";
import InAppFooter from "./layouts/grower-portal/InAppFooter";
import "vue-toastification/dist/index.css";
import Toast from "vue-toastification";

// Unregister Service Worker
navigator?.serviceWorker?.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister();
  }
});

// --- Initialize App ---
const app = createApp(App);

function appSetup() {
  app.use(store);
  app.use(router);
  app.use(Toast);
  // Add UI components
  // Layout
  app.component("two-column", TwoColumn);
  app.component("in-app-footer", InAppFooter);

  // Mount app
  app.mount("#app");
}

async function loadSentry() {
  const Sentry = await import("@sentry/vue");
  const { BrowserTracing } = await import("@sentry/tracing");

  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "parcel.ag", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_ENV || "production",
  });
}

if (process.env.VUE_APP_SENTRY) {
  loadSentry().then(() => {
    appSetup();
  });
} else {
  appSetup();
}
