var capitalize = require("capitalize");

export function formatStatus(statusString) {
  let formattedString = statusString.replace(/_/g, " ");
  return capitalize.words(formattedString);
}

export function cap(string, allWords = false) {
  if (allWords) {
    return capitalize.words(string);
  } else {
    return capitalize(string);
  }
}

export function prettyQuoteStatusGrower(statusString) {
  switch (statusString) {
    case "buyer_submitted":
      return "Quote Requested";
    case "seller_completed":
      return "Quote Complete";
    case "seller_canceled":
      return "Seller Canceled";
    case "buyer_canceled":
      return "Buyer Canceled";
    case "buyer_closed":
      return "Quote Closed";
    case "buyer_confirmed":
      return "Quote Approved";
    case "seller_responded":
      return "Quote Provided";
    case "buyer_rejected":
      return "Quote Rejected";
    case "buyer_ordered":
      return "Ordered";
    default:
      return statusString;
  }
}

export function prettyOrderStatusGrower(statusString) {
  switch (statusString) {
    case "buyer_submitted":
    case "buyer_confirmed":
    case "buyer_ordered":
      return "Ordered";
    case "seller_completed":
      return "Fulfilled";
    case "seller_canceled":
      return "Seller Canceled";
    case "buyer_canceled":
    case "buyer_closed":
      return "Buyer Canceled";
    case "buyer_rejected":
      return "Quote Rejected";
    default:
      return statusString;
  }
}

export function prettyQuoteStatusVendor(statusString) {
  switch (statusString) {
    case "buyer_submitted":
      return "Quote Requested";
    case "seller_completed":
      return "Quote Complete";
    case "seller_canceled":
      return "Seller Canceled";
    case "buyer_canceled":
      return "Buyer Canceled";
    case "buyer_closed":
      return "Quote Closed";
    case "buyer_confirmed":
      return "Quote Approved";
    case "seller_responded":
      return "Quote Provided";
    case "buyer_rejected":
      return "Buyer Rejected Quote";
    case "buyer_ordered":
      return "Ordered";
    default:
      return statusString;
  }
}

export function prettyOrderStatusVendor(statusString) {
  switch (statusString) {
    case "buyer_ordered":
      return "Ready to Fulfill";
    case "seller_completed":
      return "Fulfilled";
    case "seller_canceled":
      return "Seller Canceled";
    case "buyer_canceled":
    case "buyer_closed":
      return "Buyer Canceled";
    default:
      return statusString;
  }
}
