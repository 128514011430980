<template>
  <div>
    <div class="flex">
      <!-- Map Display here -->
      <div id="mapid" style="height: 60vh; width: 75vw" class="z-0"></div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "@/components/map/leaflet-geoman.css";
import "@geoman-io/leaflet-geoman-free";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import * as turf from "@turf/turf";

export default {
  props: {
    featureLabel: { type: String, default: "Shape" },
    initialFeature: { type: Object, default: null },
    initialView: {
      type: Object,
      default: () => ({
        lat: 39.835194809147914,
        lng: -98.57981623087144,
        zoom: 5,
      }),
    },
  },
  data: function () {
    // console.log("initial feature", this.initialFeature);
    return {
      message: "initial",
      loading: false,
      access_token: process.env.VUE_APP_MAP_ACCESS_TOKEN,
      // map: {},
      draw: {},
      area: "",
      distance: "",
      currentFeature: this.initialFeature,
    };
  },
  mounted() {
    this.createMap();
  },
  methods: {
    async createMap() {
      try {
        let componentHandle = this;
        // Create the map

        const map = new L.map("mapid");
        //this.map = map  disabling due to a bug in vue3 / leaflet

        // Initialise the FeatureGroup to store editable layers
        var editableLayers = new L.FeatureGroup();

        map.addLayer(editableLayers);

        // add the satellite base layer
        L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            attribution:
              'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            id: "airrer1/ckqqvow3854p218l3d6lo43s5",
            tileSize: 512,
            zoomOffset: -1,
            accessToken: this.access_token,
          },
        ).addTo(map);

        // add the shape to the map
        if (this.currentFeature?.geometry?.coordinates?.length > 0) {
          var shapeStyle = {
            opacity: 1,
            weight: 1,
            color: "blue",
          };
          let featureGeoJSON = this.currentFeature;
          if (featureGeoJSON.properties?.name == null) {
            featureGeoJSON.properties.name = this.featureLabel;
          }

          let centroid = L.geoJson(featureGeoJSON).getBounds().getCenter();
          // console.log(centroid);
          map.setView(centroid, 20);
          map.fitBounds(L.geoJson(featureGeoJSON).getBounds());

          let featureLayer = L.geoJSON(featureGeoJSON, {
            onEachFeature: function (_, layer) {
              editableLayers.addLayer(layer);
            },
            style: shapeStyle,
          }).addTo(map);

          // Display feature name on hover (tap on mobile)
          featureLayer.bindTooltip(function (layer) {
            return layer.feature.properties.name;
          });

          // Add editing features to the map

          // Initialise the draw control and pass it the FeatureGroup of editable layers
          map.pm.addControls({
            position: "topleft",
            drawPolygon: true,
            drawMarker: false,
            drawPolyline: false,
            drawRectangle: false,
            drawCircleMarker: false,
            drawCircle: false,
            drawText: false,
            rotateMode: false,
            dragMode: false,
          });

          // Track events that occur when editing.
          featureLayer.on("pm:update", (e) => {
            // console.log("edited a feature layer", e);
            var layers = L.PM.Utils.findLayers(map);
            var group = L.featureGroup();
            layers.forEach((layer) => {
              group.addLayer(layer);
            });
            let shape = group.toGeoJSON();
            e.layer.feature.geometry = shape.features[0].geometry; // override original feature with new geometry
            this.$emit("edited", e.layer.feature);
          });
        } else {
          // console.log("adding a new feature");
          // new feature
          map.pm.addControls({
            position: "topleft",
            drawMarker: false,
            drawPolyline: false,
            drawRectangle: false,
            drawCircleMarker: false,
            drawCircle: false,
            drawText: false,
            editMode: false,
            cutPolygon: false,
            removalMode: false,
            rotateMode: false,
            dragMode: false,
          });
          //   console.log("initial view", this.initialView);
          map.setView(
            [this.initialView.lat, this.initialView.lng],
            this.initialView.zoom,
          ); // center of the US
          // old default view [36.897433, -121.736681]
        }
        // Set Toolbar tooltips - can also set tooltips for drawing if needed
        const customTranslation = {
          tooltips: {},
          buttonTitles: {
            drawPolyButton: "Draw the " + this.featureLabel,
            editButton: "Edit " + this.featureLabel,
            cutButton: "Add Excluded Area in " + this.featureLabel,
            deleteButton: "Remove a " + this.featureLabel,
          },
        };

        map.pm.setLang("custom_en", customTranslation, "en");
        map.pm.setGlobalOptions({ snappable: false });
        // Track adding new shape.
        map.on("pm:create", function (e) {
          // console.log("drew a shape");
          //var type = e.shape;
          var layer = e.layer;
          // console.log(type);
          // console.log(layer);
          editableLayers.addLayer(layer);
          //TODO: Save new shape
          //var layers = L.PM.Utils.findLayers(map);
          //var group = L.featureGroup();
          //.forEach((layer) => {
          //  group.addLayer(layer);
          //});
          //let shape = group.toGeoJSON();
          let shape = editableLayers.toGeoJSON();
          // console.log(shape);
          if (shape.features.length > 1) {
            // console.log("more than one feature, converting to multipolygon");
            let multiPolygon = turf.multiPolygon(
              shape.features.map((feature) => feature.geometry.coordinates),
            );
            multiPolygon.geometry.type = "MultiPolygon";
            componentHandle.$emit("edited", multiPolygon);
          } else {
            componentHandle.$emit("edited", shape.features[0]);
          }
        });
      } catch (err) {
        console.log("map error", err);
      }
    },
  },
  computed: {},
  watch: {
    initialFeature: {
      handler(newVal) {
        // console.log("updated feature", newVal);
        this.currentFeature = newVal;
      },
      deep: true,
    },
    // currentOrder: {
    //   handler(newValue) {
    //     this.$emit("update:buyerNotes", newValue.buyer_notes);
    //   },
    //   deep: true,
    // },
  },
};
</script>
