<template>
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Crop
  </div>
  <text-input
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Buyer"
    inputHtmlType="text"
    v-model="currentMarketOffer.affiliate_name"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.affiliate_name"
  />
  <text-input
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Crop"
    inputHtmlType="text"
    v-model="currentMarketOffer.crop"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.crop"
  />
  <number-input
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Quantity"
    inputHtmlType="text"
    v-model="currentMarketOffer.quantity"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.quantity"
  />
  <text-input
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Quantity Units (Bushels, Bags, Tons, etc)"
    inputHtmlType="text"
    v-model="currentMarketOffer.quantity_units"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.quantity_units"
  />
  <text-input
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Deliver To"
    inputHtmlType="text"
    v-model="currentMarketOffer.elevator"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.elevator"
  />
  <text-area
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Delivery Location"
    inputHtmlType="text"
    v-model="currentMarketOffer.delivery_location"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.delivery_location"
  />
  <date-input
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Deliver By"
    v-model="currentMarketOffer.deliver_by"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.deliver_by"
  />
  <text-input
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Program Name"
    inputHtmlType="text"
    v-model="currentMarketOffer.program_name"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.program_name"
  />
  <text-input
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Program Year"
    inputHtmlType="text"
    v-model="currentMarketOffer.program_year"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.program_year"
  />
  <text-input
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Contract Name"
    inputHtmlType="text"
    v-model="currentMarketOffer.contract_name"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.contract_name"
  />
  <text-area
    v-if="currentMarketOffer"
    :wideItem="true"
    inputTitle="Contract Description"
    inputHtmlType="text"
    v-model="currentMarketOffer.contract_description"
    :displayOnly="true"
    :displayOnlyValue="currentMarketOffer.contract_description"
  />
  <div
    class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Premiums
  </div>
  <div
    v-for="(p, index) in currentMarketOffer?.premiums"
    :key="p"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <p class="text-gray-500 sm:col-span-6">Premium {{ index + 1 }}</p>
    <premium-form :premium="p" />
  </div>
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import NumberInput from "@/components/form/NumberInput.vue";
// import RadioInput from "@/components/form/RadioInput.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
// mport CurrencyInput from "@/components/form/CurrencyInput.vue";
import PremiumForm from "@/layouts/grower-portal/sell/offers/PremiumForm.vue";
import DateInput from "@/components/form/DateInput.vue";

export default {
  props: {
    offerId: {
      type: [Number, String],
      default: null,
    },
  },
  components: {
    TextInput,
    TextArea,
    NumberInput,
    // RadioInput,
    // CheckboxInput,
    // CurrencyInput,
    PremiumForm,
    DateInput,
  },
  data() {
    return {
      currentMarketOffer: {},
    };
  },
  methods: {
    readMarketOffer() {
      let currentMarketOffer = this.$store.getters.getGrowerMarketOfferById(
        this.offerId,
      );
      this.currentMarketOffer = currentMarketOffer;
    },
  },
  watch: {
    offerId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        this.readMarketOffer();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>
