<template>
  <div :class="itemWidth">
    <label class="block text-sm font-medium text-gray-700">
      <span v-if="!noTitle">
        {{ inputTitle }}
        <span v-if="required" class="text-red-600">*</span>
        <span v-if="helpText"
          ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
        /></span>
      </span>
      <div class="margin-auto relative block" v-if="selectOptions">
        <!-- Dropdown Input -->
        <div class="flex flex-row">
          <input
            class="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-2 focus:border-twilight-500 focus:outline-none focus:ring-twilight-500 sm:text-sm"
            :class="conditionalInputStyling"
            :name="name"
            @focus="showOptions()"
            @blur="exit()"
            @keyup="keyMonitor"
            v-model="searchFilter"
            :disabled="displayOnly"
            :required="required"
            :placeholder="placeholder"
            autocomplete="off"
          />
          <button
            class="-mx-8 my-2 text-gray-500"
            @click.prevent="showOptions()"
          >
            <ChevronDownIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <!-- Dropdown Menu -->
        <div
          class="absolute z-50 max-h-56 w-full overflow-auto border border-solid border-gray-900 bg-white drop-shadow-xl"
          v-show="optionsShown && !displayOnly"
        >
          <div
            class="line-height block p-2 hover:bg-twilight-600 sm:text-sm"
            @mousedown="selectOption(option)"
            v-for="(option, index) in filteredOptions"
            :key="index"
          >
            {{ option.label || option.value || "-" }}
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { ChevronDownIcon } from "@heroicons/vue/solid";
import ToolTip from "@/components/modals/ToolTip.vue";
import _ from "lodash";
export default {
  components: {
    ChevronDownIcon,
    ToolTip,
  },
  props: {
    testProp: { type: Object },
    inputTitle: { type: String, required: false, default: "Input Field" },
    noTitle: { type: Boolean, required: false, default: false },
    name: { type: String, required: false, default: "dropdown" },
    wideItem: { type: Boolean, default: true },
    narrowItem: { type: Boolean, default: false },
    selectOptions: { type: Array, required: true, default: new Array() },
    placeholder: {
      type: String,
      required: false,
      default: "Please select an option",
    },
    classProp: { type: String },
    required: { type: Boolean, required: false, default: false },
    maxItem: { type: Number, required: false, default: -1 },
    noSort: { type: Boolean, default: false },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: String },
    modelValue: { type: [Number, String] },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  emits: ["update:modelValue", "onBlur", "filter"],
  data() {
    // turn simple array into a label / value pair if needed. Then sort.
    let options = this.selectOptions;
    options = this.formatOptions(options);

    let searchFilter = "";
    if (this.modelValue) {
      searchFilter = options.find(
        (option) => option?.value == this.modelValue,
      )?.label;
    }

    return {
      selected: {},
      optionsShown: false,
      searchFilter: searchFilter,
      listOptions: options,
    };
  },
  created() {
    // this.$emit("update:modelValue", this.selected.value);
  },
  computed: {
    itemWidth() {
      let itemWidth = "sm:col-span-2";
      if (this.wideItem) itemWidth = "sm:col-span-3";
      if (this.narrowItem) itemWidth = "sm:col-span-1";
      return itemWidth;
    },
    filteredOptions() {
      const filtered = [];
      let options = this.listOptions;
      const regOption = new RegExp(_.escapeRegExp(this.searchFilter), "ig");
      for (const option of options) {
        if (option.label == null) continue;
        if (this.searchFilter?.length < 1 || option.label.match(regOption)) {
          if (this.maxItem < 0 || filtered.length < this.maxItem)
            filtered.push(option);
        }
      }
      return filtered;
    },
    conditionalInputStyling() {
      let style = "";
      if (this.displayOnly) style = "bg-gray-200 ";
      if (this.classProp) style += this.classProp;
      return style;
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.label;
      // console.log("selectOption", option, this.selected);
      this.$emit("update:modelValue", this.selected.value);
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = "";
        this.optionsShown = true;
      }
    },
    formatOptions(options) {
      if (options && typeof options[0] == "string") {
        options = options.map((option) => ({ label: option, value: option }));
      }
      if (this.displayOnly)
        options = [
          { label: this.displayOnlyValue, value: this.displayOnlyValue },
        ];
      if (!this.noSort) {
        if (options.length > 0) {
          options = options.sort((a, b) =>
            a.label == b.label ? 0 : a.label > b.label ? 1 : -1,
          );
        }
      }
      // console.log("select", options, typeof options[0]);
      return options;
    },
    exit() {
      // console.log("on select", this.selected, this.searchFilter);
      if (!this.selected.value) {
        this.selected = {};
        this.searchFilter = "";
      } else {
        this.searchFilter = this.selected.label;
      }
      this.$emit("update:modelValue", this.selected.value);
      this.$emit("onBlur", this.selected.value);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    },
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit("filter", this.searchFilter);
    },
    selectOptions() {
      this.listOptions = this.formatOptions(this.selectOptions);
      if (this.modelValue) {
        this.searchFilter = this.listOptions.find(
          (option) => option?.value == this.modelValue,
        )?.label;
      }
    },
    modelValue(value) {
      // console.log("prop updating value", value, this.listOptions);
      if (value && this.listOptions?.length > 0) {
        this.searchFilter = this.listOptions.find(
          (option) => option.value == value,
        ).label;
      } else this.searchFilter = "";
    },
    displayOnly() {
      if (this.displayOnly) this.searchFilter = this.displayOnlyValue;
    },
    displayOnlyValue() {
      if (this.displayOnly) this.searchFilter = this.displayOnlyValue;
    },
  },
};
</script>
